// general

export const SELECT_DOCUMENT = (root, id) => {
	if (0 === id) {
		return root;
	} else {
		return (root?.children || []).filter(doc => doc.id === id).at(0) || root;
	}
}

export const MERGE_DOCUMENT = (root, id, document) => {
	if (0 !== id) {
		root.children = (root?.children || []).map(child => child.id === id ? document : child);
		return root;
	} else {
		return document;
	}
}

export const MULTI_SIGN_EXTRACT_SIGNATURE_FIELD = (document) => {
	return ((document?.children || []).filter(child => child.childType === 'MULTI_SIGN').at(0)?.signers || []).at(0)?.signatureField;
}

export const MULTI_SIGN_EXTRACT_PARAPH_FIELDS = (document) => {
	return ((document?.children || []).filter(child => child.childType === 'MULTI_SIGN').at(0)?.signers || []).at(0)?.paraphFields || [];
}

export const MULTI_SIGN_EXTRACT_EXTRA_SIGNATURE_FIELDS = (document) => {
	return ((document?.children || []).filter(child => child.childType === 'MULTI_SIGN').at(0)?.signers || []).at(0)?.extraSignatureFields || [];
}

export const MULTI_SIGN_EXTRACT_FORM_FIELDS = (document) => {
	const fields = ((document?.children || []).filter(child => child.childType === 'MULTI_SIGN').at(0)?.signers || []).at(0)?.formFields || [];
	if (document.state !== 'PREPARING') {
		// the value for each field will be different when not preparing, blank it out to avoid confusion
		fields.forEach(field => field.value = '');
	}
	return fields;
}

export const MULTI_SIGN_EXTRACT_CUSTOM_EMAIL_HEADERS = (document) => {
	return (document?.children || [])
		.filter(child => child.childType === 'MULTI_SIGN')
		.at(0)?.signersCustomEmailHeaders || {};
}

export const MULTI_SIGN_DISTRIBUTE_PARAPH_FIELDS = (document, multiSignParaphFields) => {
	return {
		...document,
		children: document.children.map(child => child.childType !== 'MULTI_SIGN' ? child : {
			...child,
			signers: child.signers.map(signer => ({
				...signer,
				paraphFields: [...multiSignParaphFields]
			})),
		})
	}
}

export const MULTI_SIGN_DISTRIBUTE_EXTRA_SIGNATURE_FIELDS = (document, multiSignExtraSignatureFields) => {
	return {
		...document,
		children: document.children.map(child => child.childType !== 'MULTI_SIGN' ? child : {
			...child,
			signers: child.signers.map(signer => ({
				...signer,
				extraSignatureFields: [...multiSignExtraSignatureFields]
			})),
		})
	}
}

export const MULTI_SIGN_DISTRIBUTE_FORM_FIELDS = (document, multiSignFormFields) => {
	return {
		...document,
		children: document.children.map(child => child.childType !== 'MULTI_SIGN' ? child : {
			...child,
			signers: child.signers.map(signer => ({
				...signer,
				formFields: [...multiSignFormFields]
			})),
		})
	}
}

// collection stuff

const _equal = (a, b) => {
	if (typeof a === 'number' && Math.abs(a - b) > Number.EPSILON) return false;
	if (Array.isArray(a)) {
		if (a.length !== b.length) return false;
		for (let i = 0; i < a.length; ++i) {
			if (!_equal(a[i], b[i])) return false;
		}
		return true;
	}
	if (typeof a === 'object') {
		if (null === a) {
			return null === b;
		}
		const keys = Object.keys(a);
		for (let i = 0; i < keys.length; i++) {
			if (!_equal(a[keys[i]], b[keys[i]])) return false;
		}
		return true;
	}
	return a === b;
}

const _merge = (o, n, child, prop) => {
	if (_equal(o[prop], child[prop])) {
		child[prop] = n[prop];
	}
};

export const COLLECTION_DISTRIBUTE_FOLDER = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if (child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION' ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE')) {
			return child;
		}
		if (_equal(oldParent.folderId, child.folderId)) {
			return {
				...child,
				folderId: parent.folderId
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_OVERRIDE_REASON_SETTINGS = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.overrideReasonSettings, child.overrideReasonSettings)) {
			return {
				...child,
				overrideReasonSettings: parent.overrideReasonSettings
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_OVERRIDE_DECLINE_ENABLED = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.overrideDeclineEnabled, child.overrideDeclineEnabled)) {
			return {
				...child,
				overrideDeclineEnabled: parent.overrideDeclineEnabled
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_DECLINE_ENABLED = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.declineEnabled, child.declineEnabled)) {
			return {
				...child,
				declineEnabled: parent.declineEnabled
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_REASON_AVAILABLE = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.reasonAvailable, child.reasonAvailable)) {
			return {
				...child,
				reasonAvailable: parent.reasonAvailable
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_REASON_LEGAL_NOTICE_MANDATORY = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if (child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION' ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE')) {
			return child;
		}
		if (_equal(oldParent.reasonLegalNoticeMandatory, child.reasonLegalNoticeMandatory)) {
			return {
				...child,
				reasonLegalNoticeMandatory: parent.reasonLegalNoticeMandatory
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_REASON_LEGAL_NOTICE_TEXT = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if (child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION' ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE')) {
			return child;
		}
		if (_equal(oldParent.reasonLegalNoticeText, child.reasonLegalNoticeText)) {
			return {
				...child,
				reasonLegalNoticeText: parent.reasonLegalNoticeText
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_APPROVE_ORDER_TYPE = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.approveOrderType, child.approveOrderType)) {
			return {
				...child,
				approveOrderType: parent.approveOrderType
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_APPROVERS = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		const specificApprovers = child.approvers.filter(childApprover => !parent.approvers.find(a => a.id == childApprover.id) &&
			!oldParent.approvers.find(a => a.id == childApprover.id));
		const mergedApprovers = parent.approvers.map(parentApprover => {
			const childApprover = child.approvers.find(a => a.id === parentApprover.id);
			if (!childApprover) {
				return {
					...parentApprover,
				};
			} else {
				const oldParentApprover = oldParent.approvers.find(a => a.id === parentApprover.id);
				if (!oldParentApprover) {
					return {...childApprover};    // didn't exist previously, keep the childApprover
				} else {
					const copy = {...childApprover};
					_merge(oldParentApprover, parentApprover, copy, 'required');
					return copy;
				}
			}
		});
		child.approvers = specificApprovers.concat(mergedApprovers);
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (oldParent.minimalNumberOfApprovalDecisions === child.minimalNumberOfApprovalDecisions &&
			_equal(parent.approvers, child.approvers)) {
			return {
				...child,
				minimalNumberOfApprovalDecisions: parent.minimalNumberOfApprovalDecisions
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_MINIMAL_NUMBER_OF_APPROVALS = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (oldParent.minimalNumberOfApprovals === child.minimalNumberOfApprovals &&
			parent.minimalNumberOfApprovalDecisions === child.minimalNumberOfApprovalDecisions &&
			_equal(parent.approvers, child.approvers)) {
			return {
				...child,
				minimalNumberOfApprovals: parent.minimalNumberOfApprovals
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_APPROVERS_CUSTOM_EMAIL_HEADERS = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		return {
			...child,
			approversCustomEmailHeaders: parent.approversCustomEmailHeaders
		}
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_APPROVERS_CUSTOM_EMAIL_HEADERS_FOR_USER = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}

		return {
			...child,
			approverCustomEmailHeadersForUser: parent.approverCustomEmailHeadersForUser
		}
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.signingOrderType, child.signingOrderType)) {
			return {
				...child,
				signingOrderType: parent.signingOrderType
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_SIGNING_ORDER_TYPE_KIOSK = (parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') || child.attachment) {
			return child;
		}

		return {
			...child,
			signingOrderType: 'KIOSK',
			signers: child.signers
				.filter(signer => signer.person.guest || signer.person.id === child.creator.id),
			signingOrderGroupSettings: [],
			approvers: child.approvers.filter(approver => approver.approvalRequestState !== 'NEW'),
			children: child.children.filter(subChild => subChild.childType !== 'MULTI_SIGN')
		}
	});
	return parent;
}


export const COLLECTION_DISTRIBUTE_SIGNERS = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		const specificSigners = child.signers.filter(childSigner => !parent.signers.find(a => a.id == childSigner.id) &&
			!oldParent.signers.find(a => a.id == childSigner.id));
		const mergedSigners = parent.signers.map(parentSigner => {
			const childSigner = child.signers.find(a => a.id === parentSigner.id);
			if (!childSigner) {
				return {
					...parentSigner,
					...(parentSigner.signatureField && {signatureField: {...parentSigner.signatureField}}),
					...(parentSigner.signatureTypeConfigs && {signatureTypeConfigs: [...parentSigner.signatureTypeConfigs]}),
					...(parentSigner.paraphFields && {paraphFields: structuredClone(parentSigner.paraphFields)}),
					...(parentSigner.extraSignatureFields && {extraSignatureFields: structuredClone(parentSigner.extraSignatureFields)}),
				};
			} else {
				const oldParentSigner = oldParent.signers.find(a => a.id === parentSigner.id);
				if (!oldParentSigner) {
					return {...childSigner};    // didn't exist previously, keep the childSigner
				} else {
					const copy = {...childSigner};

					['otpNumber', 'useKnownOtpNumber', 'order'].forEach(prop => {
						_merge(oldParentSigner, parentSigner, copy, prop);
					});

					// distribute the person if previously it wasn't set (placeholder)
					if (!copy.person && !oldParentSigner.person && !!parentSigner.person) {
						copy.person = structuredClone(parentSigner.person);
					}

					if (!copy.signatureTypeConfigs || _equal(oldParentSigner.signatureTypeConfigs, copy.signatureTypeConfigs)) {
						copy.signatureTypeConfigs = [...parentSigner.signatureTypeConfigs];
					}

					// if there is NO change in the signatureField we keep the child value, if not use the parent one
					// we don't want to merge i.e. the page index when the position has changed
					if (!!parentSigner.signatureField) {
						if (['relativeLocationX', 'relativeLocationY', 'pageIndex']
							.every(val => !childSigner.signatureField || (!!oldParentSigner.signatureField && _equal(oldParentSigner.signatureField[val], childSigner.signatureField[val])))) {
							copy.signatureField = {...parentSigner.signatureField};
						}
					}

					if (!!parentSigner.paraphFields) {
						if (!childSigner.paraphFields || _equal(oldParentSigner.paraphFields, childSigner.paraphFields)) {
							copy.paraphFields = structuredClone(parentSigner.paraphFields);
						}
					}

					// extraSignatureFields are a bit harder, just copy if everything is equal
					if (!!parentSigner.extraSignatureFields) {
						if (!childSigner.extraSignatureFields || _equal(oldParentSigner.extraSignatureFields, childSigner.extraSignatureFields)) {
							copy.extraSignatureFields = structuredClone(parentSigner.extraSignatureFields);
						}
					}

					// similar for formfields
					if (!!parentSigner.formFields) {
						if (!childSigner.formFields || _equal(oldParentSigner.formFields, childSigner.formFields)) {
							copy.formFields = structuredClone(parentSigner.formFields);
						}
					}

					return copy;
				}
			}
		});
		child.signers = specificSigners.concat(mergedSigners);

		if ((!oldParent.signingOrderGroupSettings && !child.signingOrderGroupSettings) ||
			_equal(oldParent.signingOrderGroupSettings, child.signingOrderGroupSettings)) {
			child.signingOrderGroupSettings = parent.signingOrderGroupSettings;
		}

		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_SIGNERS_CUSTOM_EMAIL_HEADERS = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}

		return {
			...child,
			signersCustomEmailHeaders: parent.signersCustomEmailHeaders
		}
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_SIGNERS_CUSTOM_EMAIL_HEADERS_FOR_USER = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}

		return {
			...child,
			signerCustomEmailHeadersForUser: parent.signerCustomEmailHeadersForUser
		}
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_EMAIL_DISTRIBUTION_LIST = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.postSignDocumentEmailDistributionList, child.postSignDocumentEmailDistributionList)) {
			return {
				...child,
				postSignDocumentEmailDistributionList: parent.postSignDocumentEmailDistributionList
			}
		}
		return child;
	});
	return parent;
}

export const COLLECTION_DISTRIBUTE_POST_SIGN_DOCUMENT_AND_EVIDENCE_REPORT_EMAIL_DISTRIBUTION_LIST = (oldParent, parent) => {
	parent.children = parent.children.map(child => {
		if ((child.childType !== 'COLLECTION' && child.childType !== 'VIRTUAL_COLLECTION') ||
			!(child.state === 'PREPARING' || child.state === 'TEMPLATE') ||
			child.attachment) {
			return child;
		}
		if (_equal(oldParent.postSignDocumentAndEvidenceReportEmailDistributionList, child.postSignDocumentAndEvidenceReportEmailDistributionList)) {
			return {
				...child,
				postSignDocumentAndEvidenceReportEmailDistributionList: parent.postSignDocumentAndEvidenceReportEmailDistributionList
			}
		}
		return child;
	});
	return parent;
}

// validation

export const GENERAL_ERROR_COUNT = (document) => {
	let count = 0;
	if (document.documentDescriptionMandatory && (document.documentDescription || '').length === 0) {
		count++;
	}
	return count;
}

export const APPROVAL_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS_ERROR = (document) => {
	if (!document.hasOwnProperty('minimalNumberOfApprovalDecisions')) {
		return false;
	}
	if (typeof document.minimalNumberOfApprovalDecisions !== 'number' || isNaN(document.minimalNumberOfApprovalDecisions)) {
		return true;
	}
	const lowerBound =
		Math.max(1, document.approvers.reduce((count, approver) => count + (approver.required ? 1 : 0), 0));
	return document.minimalNumberOfApprovalDecisions < lowerBound ||
		document.minimalNumberOfApprovalDecisions > document.approvers.length;
}

export const APPROVAL_MINIMAL_NUMBER_OF_APPROVALS_ERROR = (document) => {
	if (!document.hasOwnProperty('minimalNumberOfApprovals')) {
		return false;
	}
	if (typeof document.minimalNumberOfApprovals !== 'number' || isNaN(document.minimalNumberOfApprovals)) {
		return true;
	}
	const lowerBound =
		Math.max(1, document.approvers.reduce((count, approver) => count + (approver.required ? 1 : 0), 0))
	const upperBound = document.hasOwnProperty('minimalNumberOfApprovalDecisions') ?
		document.minimalNumberOfApprovalDecisions : document.approvers.length;
	return document.minimalNumberOfApprovals < lowerBound ||
		document.minimalNumberOfApprovals > upperBound;
}

export const APPROVAL_ERROR_COUNT = (document) => {
	// Do not check for collections that already have their approvers distributed to their children: their document.approvers.length is no longer representative (has been set to 0)
	if (document?.documentCollection && document.approvers.length < 1) {
		return 0;
	}
	return (APPROVAL_MINIMAL_NUMBER_OF_APPROVAL_DECISIONS_ERROR(document) ? 1 : 0) +
		(APPROVAL_MINIMAL_NUMBER_OF_APPROVALS_ERROR(document) ? 1 : 0)
		;
}

export const SIGNER_SMS_OTP_NUMBER_ERROR = (otpNumber) => {
	return !otpNumber || !(otpNumber + '').match(/^\+?[0-9]{8,20}$/);
}

export const SIGNER_SMS_OTP_ERROR = (document, signer) => {
	const smsOtpConfig = document.signatureTypeConfigs.find(config => config.signatureType === 'SMS_OTP');

	return document.state !== 'TEMPLATE' &&
		signer.signatureTypeConfigs.includes(smsOtpConfig.id) &&
		!signer.useKnownOtpNumber &&
		(!signer.signRequestState || signer.signRequestState === 'NEW') &&
		SIGNER_SMS_OTP_NUMBER_ERROR(signer.otpNumber);
}

export const SIGNER_NO_SIGNATURE_FIELD = (signer) => {
	return !signer.signatureField;
}

export const SIGNER_NO_SIGNATURE_TYPE = (signer) => {
	return !signer.signatureTypeConfigs || signer.signatureTypeConfigs.length === 0;
}

export const SIGNER_NO_PERSON_ERROR = (document, signer) => {
	return document.state !== 'TEMPLATE' &&
		!signer.person;
}

export const SIGNER_FORM_FIELD_NEEDS_VALUE = (signer) => {
	return signer?.formFields?.some(field => !field.editable && !field.value && field.type !== 'ATTRIBUTE') || false;
}

export const SIGNER_KIOSK_MULTIPLE_REGISTERED_USERS_ERROR = (document) => {
	return document.signingOrderType === 'KIOSK' &&
		document.signers.filter(signer => !signer.person.guest).length > 1;
}

export const SIGNER_ERROR = (document, signer, onlySignatureField) => {
	if (onlySignatureField) {
		return SIGNER_NO_SIGNATURE_FIELD(signer);
	} else {
		return SIGNER_SMS_OTP_ERROR(document, signer) ||
			SIGNER_NO_SIGNATURE_FIELD(signer) ||
			SIGNER_NO_SIGNATURE_TYPE(signer) ||
			SIGNER_NO_PERSON_ERROR(document, signer) ||
			SIGNER_FORM_FIELD_NEEDS_VALUE(signer) ||
			SIGNER_KIOSK_MULTIPLE_REGISTERED_USERS_ERROR(document)
	}
}

export const SIGNERS_ERROR_COUNT = (document, onlySignatureField) => {
	// For parent of collection: check if the signers of the parent have at least one error on the child documents. Ignore any other signers of the children.
	if (document?.documentCollection) {
		return (document?.signers || []).filter(signer => !signer.signed).reduce((count, parentSigner) => {
			for (const child of document.children) {
				if (child.signers?.length > 0) {
					const childSigner = (child.signers || []).find(signer => signer.id === parentSigner.id);
					if (!!childSigner && SIGNER_ERROR(child, childSigner, onlySignatureField)) {
						return count + 1;
					}
				}
			}
			return count;
		}, 0);
	} else {
		return (document?.signers || []).filter(signer => !signer.signed).reduce((count, signer) => count + (SIGNER_ERROR(document, signer, onlySignatureField) ? 1 : 0), 0);
	}
}

export const MULTI_SIGN_ERROR_COUNT = (document) => {
	return (document?.children || []).filter(signer => !signer.signed).reduce((count, child) => {
		if (child.childType === 'MULTI_SIGN' && (child.signers.length !== 1 || SIGNER_ERROR(child, child.signers[0], false))) {
			return count + 1;
		}
		return count;
	}, 0);
}

export const PDF_ERROR_COUNT = (document) => {
	let count = 0;
	if (!document.hasPdf && document.state === 'PREPARING' && !document.documentCollection) {
		count++;
	}
	return count;
}

export const DOCUMENT_ERROR_COUNT = (document) => {
	return GENERAL_ERROR_COUNT(document) +
		APPROVAL_ERROR_COUNT(document) +
		SIGNERS_ERROR_COUNT(document, false) +
		MULTI_SIGN_ERROR_COUNT(document) +
		PDF_ERROR_COUNT(document)
		;
}

const HAS_ACTOR = (document) => {
	if (document.attachment) {
		return true;
	}

	return document.approvers.length > 0 ||
		document.signers.length > 0 ||
		document.children.reduce((count, child) => count + (child.childType === 'MULTI_SIGN' ? 1 : 0), 0) > 0;
}

export const CAN_SAVE = (rootDocument) => {
	const hasApprovalErrorsSomewhere = []
		.concat(rootDocument)
		.concat(rootDocument.children.filter(child => child.childType === 'COLLECTION'))
		.some(doc => APPROVAL_ERROR_COUNT(doc) > 0);

	if (hasApprovalErrorsSomewhere) {
		return false;
	}

	return true;
}

export const CAN_SEND = (rootDocument, senderUserId) => {
	const hasApproversOrSignersEverywhere =
		!rootDocument.documentCollection && HAS_ACTOR(rootDocument) ||
		rootDocument.documentCollection && rootDocument.children.reduce((acc, child) => {
			if (-1 === acc) {
				return acc;
			}
			if ((child.childType === 'COLLECTION' || child.childType === 'VIRTUAL_COLLECTION') && child.state === 'PREPARING' && !child.attachment) {
				return HAS_ACTOR(child) ? acc + 1 : -1;
			} else {
				return acc;
			}
		}, 0) > 0;

	if (!hasApproversOrSignersEverywhere) {
		return false;
	}

	const hasErrorsSomewhere = []
		.concat(rootDocument)
		.concat(rootDocument.children.filter(child => child.childType === 'COLLECTION' || child.childType === 'VIRTUAL_COLLECTION'))
		.some(doc => DOCUMENT_ERROR_COUNT(doc) > 0);

	if (hasErrorsSomewhere) {
		return false;
	}

	if (rootDocument.signingOrderType === 'KIOSK') {
		if (!rootDocument.documentCollection && !!rootDocument.signers.find(signer => !signer.person.guest && signer.person.id !== senderUserId) ||
			rootDocument.documentCollection && !!rootDocument.children.find(child => !!child.signers.find(signer => !signer.person.guest && signer.person.id !== senderUserId))) {
			return false
		}
	}

	return true;
}

export const COLLECTION_CHILD_ERROR = (collectionChild) => {
	if (GENERAL_ERROR_COUNT(collectionChild) > 0) {
		return true;
	}

	if (collectionChild.state !== 'PREPARING' || collectionChild.attachment){
		return false;
	}

	if (!HAS_ACTOR(collectionChild)) {
		return true;
	} else if (APPROVAL_ERROR_COUNT(collectionChild) > 0) {
		return true;
	} else if (SIGNERS_ERROR_COUNT(collectionChild, false) > 0) {
		return true;
	} else {
		return MULTI_SIGN_ERROR_COUNT(collectionChild) > 0;
	}
}

export const VALID_EMAIL_ADDRESS = (emailAddress) => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
}

export const HAS_BEEN_SIGNED = (document) => {
	return document?.signers?.some((signer) => signer?.signed === true);
}

export const BACKGROUND_COLOR_TO_RGBA = (backgroundColorHex, opacity) => {
	const isDefined = value => typeof value === 'number' && !isNaN(value);
	const r = parseInt(backgroundColorHex.slice(0, 2), 16);
	const g = parseInt(backgroundColorHex.slice(2, 4), 16);
	const b = parseInt(backgroundColorHex.slice(4, 6), 16);
	const a = opacity;
	if (isDefined(r) && isDefined(g) && isDefined(b) && isDefined(a)) {
		return `rgba(${r}, ${g}, ${b}, ${a})`;
	}
	return 'rgba(255, 255, 255, 0.25)';
}

export const VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION = (verticalAlignment) => {
	switch (verticalAlignment) {
		case 'TOP':
			return 'start';
		case 'BOTTOM':
			return 'end';
		case 'CENTER':
		default:
			return 'center';
	}
}

export const HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION = (horizontalAlignment) => {
	switch (horizontalAlignment) {
		case 'LEFT':
			return 'start';
		case 'RIGHT':
			return 'end';
		case 'CENTER':
		default:
			return 'center';
	}
}