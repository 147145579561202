import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Alert, Box, Button, FormControl, IconButton, TextField, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloudIcon from '@mui/icons-material/Cloud';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UserAuthenticatorSetupDialog from "./UserAuthenticatorSetupDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";

class UserProfileSecuritySettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentPassword: '',
			newPassword: '',
			newPasswordConfirmation: '',
			passwordChangeError: false,

			authenticatorSetupDialogOpen: false,
			removeMfaDialogOpen: false,
			removeMfaMethod: null,
		}
	}

	componentDidMount() {
		this.props.onSessionFetchApiAccess();
	}

	render() {
		const sessionBusy = !this.state.authenticatorSetupDialogOpen && this.props.sessionBusy;

		return <>
			{!this.state.authenticatorSetupDialogOpen && <ServerErrorComponent serverError={this.props.sessionServerError} />}

			{this.props.sessionInfo && !this.props.sessionInfo.userNoPassword && <Box sx={{mt: 2}}>
				<Typography variant="h6">{this.props.t('user.profileUpdatePasswordHeader')}</Typography>
				<Box sx={{display: 'flex', mt: 1}}>
					<FormControl sx={{flex: '1 0 300px', maxWidth: '400px'}}>
						<TextField
							label={this.props.t('user.currentPassword')}
							variant="outlined"
							type="password"
							value={this.state.currentPassword}
							required
							fullWidth
							onChange={this.onChangeCurrentPassword}
						/>
					</FormControl>
				</Box>

				<Box sx={{mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2}}>
					<FormControl sx={{flex: '1 0 300px', maxWidth: '400px'}}>
						<TextField
							label={this.props.t('user.newPassword')}
							variant="outlined"
							type="password"
							value={this.state.newPassword}
							helperText={this.state.passwordChangeErrorText}
							error={this.state.passwordChangeError}
							required
							onChange={this.onChangeNewPassword}
						/>
					</FormControl>
					<FormControl sx={{flex: '1 0 300px', maxWidth: '400px'}}>
						<TextField
							label={this.props.t('user.newPasswordConfirmation')}
							variant="outlined"
							type="password"
							value={this.state.newPasswordConfirmation}
							helperText={this.state.passwordChangeErrorText}
							error={this.state.passwordChangeError}
							required
							onChange={this.onChangePasswordConfirmation}
						/>
					</FormControl>
				</Box>

				<Alert icon={false} severity="info" sx={{mt: 1, width: '100%'}}>
					{this.props.t('user.passwordRequirements')}
				</Alert>

				<Box sx={{mt: 2}}>
					<Button
						variant="contained"
						onClick={this.onConfirmPasswordChange}
						startIcon={<SaveIcon/>}
						disabled={this.state.currentPassword === '' || this.state.newPassword === '' || this.state.newPasswordConfirmation === '' || this.state.passwordChangeError}
						id="btn-profile-password-reset"
					>
						{this.props.t('save')}
					</Button>
				</Box>
			</Box>}

			<Box sx={{mt: 2}}>
				<Typography variant="h6">{this.props.t('user.authenticatorTitle')}</Typography>
				{this.props.sessionInfo.companyMfaNeedAction && !this.props.sessionInfo.userMfaCompliant &&
					<Alert severity="error" sx={{mt: 1}}>{this.props.t('user.authenticatorRequired')}</Alert>
				}
				<Button variant="contained" disabled={sessionBusy} sx={{mt: 1, ml: 1}}
						onClick={this.onAuthenticatorOpenDialog}>{this.props.t('user.authenticatorSetAction')}</Button>
				{this.props.sessionInfo.userMfaCompliant &&
					<Button variant="contained" disabled={sessionBusy} sx={{mt: 1, ml: 1}}
							onClick={() => this.onRemoveMfaDialogOpen('AUTHENTICATOR')}>{this.props.t('user.authenticatorResetAction')}</Button>
				}
			</Box>

			<Box sx={{mt: 2}}>
				<Typography variant="h6">{this.props.t('user.profileApiAccess')}</Typography>
				{!this.props.sessionApiAccess?.enabled && <>
					<Alert severity="info" sx={{mt: 1}}>{this.props.t('user.profileApiAccessNotEnabled')}</Alert>
					<Button
						variant="contained"
						onClick={this.onEnableApiAccess}
						disabled={sessionBusy}
						startIcon={<CloudIcon/>}
						id="btn-profile-api-access-enable"
						sx={{mt: 2}}
					>
						{this.props.t('user.profileApiAccessEnable')}
					</Button>
				</>}
				{this.props.sessionApiAccess?.enabled && <>
					<Alert severity="info" sx={{mt: 1}}>{this.props.t('user.profileApiAccessEnabled')}</Alert>
					{!!this.props.sessionApiAccess.secret && <Box sx={{mt: 2, display: 'flex'}}>
						<FormControl sx={{flex: '1 0 300px', maxWidth: '400px'}}>
							<TextField
								label={this.props.t('user.profileApiAccessSecret')}
								variant="outlined"
								value={this.props.sessionApiAccess.secret}
								InputProps={{
									endAdornment: <IconButton color="primary"
															  title={this.props.t('user.profileApiAccessSecretCopy')}
															  onClick={this.onCopyApiSecret}
															  size="small"
									>
										<ContentCopyIcon/>
									</IconButton>
								}}
							/>
						</FormControl>

					</Box>}
					<Button
						variant="contained"
						onClick={this.onDisableApiAccess}
						disabled={sessionBusy}
						startIcon={<CloudIcon/>}
						id="btn-profile-api-access-disable"
						sx={{mt: 2}}
					>
						{this.props.t('user.profileApiAccessDisable')}
					</Button>
				</>}
			</Box>

			<UserAuthenticatorSetupDialog
				update={this.props.sessionInfo.userMfaCompliant}
				open={this.state.authenticatorSetupDialogOpen}
				onCancel={this.onAuthenticatorCloseDialog}
				onFinished={this.onAuthenticatorCloseDialog}
			/>

			<ConfirmationDialog
				title={this.props.t('user.authenticatorRemoveTitle')}
				confirm={this.props.t((!!this.props.sessionInfo?.companyMfaRequired && !!this.props.sessionInfo?.companyMfaInScope) ? 'user.authenticatorRemoveAdminText' : 'user.authenticatorRemoveText')}
				open={this.state.removeMfaDialogOpen}
				onClose={this.onRemoveMfaDialogClose}
				onConfirm={this.onRemoveMfa}
			/>
		</>
	}


	onChangeCurrentPassword = (e) => {
		this.setState({
			currentPassword: e.target.value,
		});
	}

	onChangeNewPassword = (e) => {
		this.setState({
			newPassword: e.target.value,
			passwordChangeError: this.state.newPasswordConfirmation !== e.target.value,
			passwordChangeErrorText: this.state.newPasswordConfirmation !== e.target.value ? this.props.t('serverError.PASSWORD_MISMATCH') : ''
		});
	}

	onChangePasswordConfirmation = (e) => {
		this.setState({
			newPasswordConfirmation: e.target.value,
			passwordChangeError: this.state.newPassword !== e.target.value,
			passwordChangeErrorText: this.state.newPassword !== e.target.value ? this.props.t('serverError.PASSWORD_MISMATCH') : ''
		});
	}

	onConfirmPasswordChange = () => {
		this.props.onSessionUpdateUserPassword(this.state.currentPassword, this.state.newPassword, this.state.newPasswordConfirmation);
		this.setState({
			currentPassword: '',
			newPassword: '',
			newPasswordConfirmation: ''
		});
	}

	onAuthenticatorOpenDialog = () => {
		this.setState({authenticatorSetupDialogOpen: true});
	}

	onAuthenticatorCloseDialog = () => {
		this.setState({authenticatorSetupDialogOpen: false});
	}

	onRemoveMfaDialogOpen = (method) => {
		this.setState({removeMfaDialogOpen: true, removeMfaMethod: method});
	}

	onRemoveMfaDialogClose = () => {
		this.setState({removeMfaDialogOpen: false});
	}

	onRemoveMfa = () => {
		this.setState({
			removeMfaDialogOpen: false
		}, () => this.props.onSessionRemoveMfa(this.state.removeMfaMethod));
	}

	onEnableApiAccess = () => {
		this.props.onSessionUpdateApiAccess(true);
	}

	onDisableApiAccess = () => {
		this.props.onSessionUpdateApiAccess(false);
	}

	onCopyApiSecret = () => {
		navigator?.clipboard?.writeText(this.props.sessionApiAccess.secret);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionApiAccess: state.session.apiAccess,
			sessionBusy: state.session.busy,
			sessionServerError: state.session.serverError
		}
	},
	dispatch => {
		return {
			onSessionUpdateUserPassword: (currentPassword, newPassword, newPasswordConfirmation) => {
				dispatch({
					type: 'SESSION_UPDATE_USER_PASSWORD',
					currentPassword,
					newPassword,
					newPasswordConfirmation
				})
			},
			onSessionFetchApiAccess: () => {
				dispatch({
					type: 'SESSION_FETCH_API_ACCESS'
				})
			},
			onSessionUpdateApiAccess: (enable) => {
				dispatch({
					type: 'SESSION_UPDATE_API_ACCESS',
					enable
				})
			},
			onSessionRemoveMfa: (method) => {
				dispatch({
					type: 'SESSION_REMOVE_MFA',
					method
				})
			}
		}
	}
)(UserProfileSecuritySettingsComponent));