import React, {Component, createRef} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Badge,
	Box,
	Button,
	Checkbox,
	Chip,
	Container,
	FormControlLabel,
	FormGroup,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskIcon from "@mui/icons-material/Task";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import {ReactComponent as Logo} from "../../img/quill_logo.svg";
import AppContainer from "../common/AppContainer";
import ServerErrorComponent from "../common/ServerErrorComponent";
import PdfViewerComponent from "../common/PdfViewerComponent";
import VisualSignatureOrParaphComponent from "../common/VisualSignatureOrParaphComponent";
import SigningDialog from "./SigningDialog";
import SigningItsMeBusyDialog from "./SigningItsMeBusyDialog";
import SigningRedirectDialog from "./SigningRedirectDialog";
import SigningForwardDialog from "./SigningForwardDialog";
import SigningFormFieldDialog from "./SigningFormFieldDialog";
import {BACKGROUND_COLOR_TO_RGBA, HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION, VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION} from "../editor/EditorUtils";
import FixedWidthHeaderComponent from "../common/FixedWidthHeaderComponent";
import SigningEditFormComponent from "./SigningEditFormComponent";
import {format, formatDuration, intervalToDuration, parse} from "date-fns";
import {FORM_FIELD_DATE_VALUE_FORMAT, FORM_FIELD_DATE_VISUAL_FORMAT, FORM_FIELD_UNSET_VALUE, MAP_DATE_FNS_LOCALE} from "../common/Constants";
import PersonIcon from "@mui/icons-material/Person";
import {styled} from "@mui/material/styles";
import {withRouter} from "../common/RouterHelper";
import ForumIcon from "@mui/icons-material/Forum";
import DocumentMessageTimelineDialog from "../document/DocumentMessageTimelineDialog";

const SHOW_FULL_INFO = (window.innerWidth > 1600);

const DERIVE_SIGN_REQUEST_FORM_FIELDS = (signingData) => {
	return (signingData?.documents || [])
		.filter(doc => doc.signRequestState === 'WAITING_FOR_SIGNATURE')
		.map(doc => ({
			signRequestId: doc.signRequestId,
			formFields: doc.formFields.map(field => ({...field}))
		}));
};

const VISUAL_SIGNATURE_PARAPH_DEFAULT = {
	data: null,
	overwrite: false,
	mimeType: null,
	fileSize: 0,
	saveAsDefault: false
};

const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
	"&.Mui-selected": {
		borderBottom: `2px solid ${theme.palette.primary.main}`,
	},
}));

class SigningComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			viewIndex: 0,
			signingDialogOpen: false,
			declining: false,
			forwardDialogOpen: false,
			itsmeCurrentDocumentIds: null,
			itsmeCurrentSignRequestIds: null,
			itsmeCurrentStatus: null,
			itsmeBusyDialogOpen: false,
			redirectDialogOpen: false,
			redirectImmediate: false,
			formFieldToFillIn: null,
			signRequestFormFields: DERIVE_SIGN_REQUEST_FORM_FIELDS(props.signingData),
			visualSignature: VISUAL_SIGNATURE_PARAPH_DEFAULT,
			visualParaph: VISUAL_SIGNATURE_PARAPH_DEFAULT,
			widthFactor: 1,
			useMailRedirectConfig: this.props?.router?.params?.data?.includes("maillink"),
			markedDocumentsAsRead: false,
			messageTimelineDialogDocumentId: null
		}

		this.pdfViewerApiRef = createRef();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.itsmeCurrentStatus) {
			// check if there is a document with an active itsme session
			const docWithActiveItsmeSession = (this.props.signingData?.documents || [])
				.filter(doc => !!doc.itsmeStatus && doc.itsmeStatus?.state !== 'FINISHED');

			if (docWithActiveItsmeSession.length) {
				this.setState({
					itsmeCurrentSignRequestIds: docWithActiveItsmeSession.map(doc => doc.signRequestId),
					itsmeCurrentStatus: docWithActiveItsmeSession.at(0).itsmeStatus,
					itsmeBusyDialogOpen: true
				})
			}
		}
		if (!!this.props.signingData?.redirectUrl && !this.state.redirectDialogOpen) {
			// check if all documents are signed
			const allFinished =
				(this.props.signingData?.documents || []).every(doc =>
					doc.signRequestState === 'SIGNED' ||
					doc.signRequestState === 'DECLINED' ||
					this.props.signingRequestsSigned.indexOf(doc.signRequestId) >= 0
				);

			if (allFinished && (!this.state.useMailRedirectConfig || (this.state.useMailRedirectConfig && this.props.signingData.mailRedirectEnabled))) {
				// redirect immediate when opening a document that is already finished (!prevProps.signingData)
				this.setState({redirectDialogOpen: true, redirectImmediate: !prevProps.signingData});
			}
		}
		if (!!this.props.signingData && this.props.signingData !== prevProps.signingData) {
			this.setState({
				signRequestFormFields: DERIVE_SIGN_REQUEST_FORM_FIELDS(this.props.signingData)
			});
		}

		if (this.props.sessionInfo?.kioskUserId !== prevProps.sessionInfo?.kioskUserId) {
			this.fetchSigningData();
		}
	}


	componentWillUnmount() {
		this.props.onSigningClearData();
	}

	render() {
		return <AppContainer
			needsSession
			onSessionCustomCreator={this.onSessionCustomCreator}
			onSessionCreated={this.fetchSigningData}
			hasPendingChanges={this.props.signingKioskMode && !!this.props.sessionInfo?.kioskUserId}
			hasPendingChangesAutoSave={!this.props.signingKioskUsers?.some(kioskUser => kioskUser.signRequestState === 'WAITING_FOR_SIGNATURE')}
			pendingChangesLabel={this.props.t('signing.signingKioskPendingChangesLabel')}
			pendingChangesSaveAndLeaveLabel={this.props.t('signing.signingKioskPendingChangesSaveAndLeaveLabel')}
			onSave={this.onSessionClearKioskSigner}
			confirmDisabled={true}
		>
			{!this.state.signingDialogOpen && !this.state.itsmeBusyDialogOpen && !!this.props.signingServerError && <Container maxWidth="md" fixed sx={{mb: 2}}>
				<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
					<ServerErrorComponent serverError={this.props.signingServerError}/>
				</Paper>
			</Container>}

			{!!this.props.sessionInfo && <>
				{!!this.props.signingData?.guestAccessExpired && <Container maxWidth="md" fixed>
					<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
						{!!this.props.signingGuestAccessRenewed && <>
							<Alert severity="success">{this.props.t('signing.guestAccessRenewedSuccessfully')}</Alert>
						</>}
						{!this.props.signingGuestAccessRenewed && <Box>
							<Alert severity="warning">
								{this.props.t('signing.guestAccessExpired', {
									duration: formatDuration(intervalToDuration({ start: 0, end: this.props.signingData.guestAccessExpiryDuration * 60 * 1000 }), {format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], locale: MAP_DATE_FNS_LOCALE(this.props.sessionInfo)})
								})}
							</Alert>
							<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
								<Button
									variant="outlined"
									size="small"
									disabled={this.props.signingBusy}
									onClick={this.props.onSigningRenewGuestAccess}
									id="btn-signing-renew-guest-access"
								>
									{this.props.t('signing.guestAccessRenew')}
								</Button>
							</Box>
						</Box>}
					</Paper>
				</Container>}

				{!this.props.signingData?.guestAccessExpired && <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 1}}>
					<Box sx={{flexGrow: 1, width: 600, maxWidth: 900}}>
						{this.renderKioskSigners()}
						{!!this.props.signingData && this.props.signingData.documents.length > 0 && !this.props.sessionSwitchKioskUserBusy && <>
							{this.renderOverview()}
							{this.props.signingData?.documents?.filter(doc => doc.signRequestState === 'WAITING_FOR_SIGNATURE')?.length > 0 &&
								!!this.props.signingData?.visualSignatureAndParaphSelectionEnabled &&
								this.renderVisualSignature()}
							{this.renderFormFields()}
							{this.renderApprovers()}
						</>}
					</Box>
					{(!!this.props.signingData || !!this.props.signingKioskUsers) &&
						<Box sx={{width: 900, maxWidth: 900}}>
							{this.renderPdfViewer()}
						</Box>}
				</Box>}
			</>}

			<DocumentMessageTimelineDialog
				documentId={this.state.messageTimelineDialogDocumentId}
				open={!!this.state.messageTimelineDialogDocumentId}
				onClose={this.onCloseMessageTimelineDialog}
				forcePhase
			/>

			<SigningDialog
				open={this.state.signingDialogOpen}
				onClose={this.onCloseSigningDialog}
				onCompleted={this.onSigningCompleted}
				declining={this.state.declining}
				signRequestFormFields={this.state.signRequestFormFields}
				visualSignature={{
					...this.state.visualSignature,
					data: this.getVisualSignature()
				}}
				visualParaph={{
					...this.state.visualParaph,
					data: this.getVisualParaph()
				}}
				onChangeVisualSignature={this.onChangeCurrentVisualSignature}
				onChangeSaveSignatureAsDefault={this.onChangeSaveSignatureAsDefault}
				kioskMode={this.props.signingKioskMode}
			/>

			<SigningItsMeBusyDialog
				open={this.state.itsmeBusyDialogOpen}
				signRequestIds={this.state.itsmeCurrentSignRequestIds}
				itsmeStatus={this.state.itsmeCurrentStatus}
				onClose={this.onCloseItsmeBusyDialog}
			/>

			<SigningRedirectDialog
				open={this.state.redirectDialogOpen}
				redirectUrl={this.props.signingData?.redirectUrl}
				redirectImmediate={this.state.redirectImmediate}
			/>

			<SigningForwardDialog
				open={this.state.forwardDialogOpen}
				onClose={this.onCloseForwardDialog}
				isGuest={this.props.sessionInfo?.guestAccess}
			/>

			<SigningFormFieldDialog
				open={!!this.state.formFieldToFillIn}
				onClose={this.onCloseFormFieldDialog}
				formField={this.state.formFieldToFillIn}
				onChangeValue={this.onChangeFormFieldValue}
			/>
		</AppContainer>
	}

	renderKioskSigners = () => {
		if (!this.props.signingKioskUsers) {
			return;
		}

		const kioskModeListView = this.props.signingKioskUsers.length > 3
		return <Paper variant="outlined" sx={{p: 2, mb: 1}}>
			<Typography variant="h6">{this.props.t('signing.signingKioskHeader')}</Typography>
			<Typography variant="body">{this.props.t('signing.signingKioskInstructions')}</Typography>
			<Box>
				<List sx={{
					display: 'flex',
					gap: 1,
					flexDirection: kioskModeListView ? 'column' : 'row'
				}}>
					{this.props.signingKioskUsers.map(signer => {
						return <StyledListItemButton
							key={signer.id + '-' + signer.guestSignKey}
							selected={signer.id === this.props.sessionInfo.kioskUserId}
							onClick={() => this.onChangeKioskUser(signer)}
							dense={kioskModeListView}
							disabled={this.props.sessionSwitchKioskUserBusy || this.props.signingBusy}
							sx={{
								flex: 1,
								minWidth: '200px'
							}}
						>
							<ListItemIcon sx={{minWidth: '40px'}}><PersonIcon sx={{fontSize: '2.5rem'}}/></ListItemIcon>
							<ListItemText>
								<Box><Typography>{signer.fullName}</Typography></Box>
								<Box><Typography
									sx={{fontSize: '0.875rem', color: 'grey'}}>{signer.capacityTitle}</Typography></Box>
								<Box><Typography sx={{
									fontSize: '0.875rem',
									color: 'grey'
								}}>{this.props.t('signing.signRequestState_' + signer.signRequestState)}</Typography></Box>
							</ListItemText>

						</StyledListItemButton>
					})}
				</List>
			</Box>
		</Paper>;
	}


	renderOverview = () => {
		const documents = this.props.signingData.documents;
		const documentsWaitingForSignature = documents.filter(doc => doc.signRequestState === 'WAITING_FOR_SIGNATURE');
		const hasSignedOrDeclined = documents.some(doc => doc.signRequestState === 'SIGNED' || doc.signRequestState === 'DECLINED');
		const documentsSigned = documents.every(doc => doc.signRequestState === 'SIGNED');
		const documentsDeclined = documents.every(doc => doc.signRequestState === 'DECLINED');
		const forwardingEnabled = documents.some(doc => doc.forwardingEnabled) && !this.props.signingKioskMode;
		const selectedDocumentDeleted = documents[this.state.viewIndex].documentState === 'DELETED';
		const allowPartialCollectionDocumentHandling = documents.every(doc => doc.allowPartialCollectionDocumentHandling);
		const declineEnabled = documents.filter(doc => doc.signRequestState === 'WAITING_FOR_SIGNATURE')
			.some(doc => (!doc.collectionParentId && doc.declineAllowed) ||
				(!!doc.collectionParentId && (doc.declineAllowed && doc.allowPartialCollectionDocumentHandling) ||
					(doc.declineAllowed && !allowPartialCollectionDocumentHandling && !documents.some(otherDoc => doc.collectionParentId === otherDoc.collectionParentId && !otherDoc.declineAllowed))));

		const hasFormFieldErrors = (this.state.signRequestFormFields || [])
			.some(entry => entry.formFields.some(field => !!field.validatorFormat && field.validatorFormat.length !== field.value?.length));

		const documentsExtended = documents.map(doc => ({
			...doc,
			hasFormFieldError: (this.state.signRequestFormFields
				.filter(entry => entry.signRequestId === doc.signRequestId)
				.at(0)?.formFields || [])
				.some(field => field.required && FORM_FIELD_UNSET_VALUE(field.value) && field.type !== 'ATTRIBUTE'),
			downloadDisabled: doc.downloadNeedsSignature &&
				'SIGNED' !== doc.signRequestState &&
				'ATTACHMENT' !== doc.documentState &&
				'ATTACHMENT_GENERIC' !== doc.documentState
		}));

		const hasNonFilledInRequiredFormFields = documentsExtended.some(doc => doc.hasFormFieldError);
		const allDownloadsDisabled = documentsExtended.every(doc => doc.downloadDisabled);
		const requireMarkAsRead = this.props.signingData?.markAllDocumentsInCollectionAsReadMandatory;

		return <Paper variant="outlined" sx={{p: 2}}>
			{documents.length > 1 && <>
				<Typography variant="h6">{this.props.t('signing.signHeaderMultiple')}</Typography>
				<Typography variant="body">{this.props.t('signing.instructionsMultiple')}</Typography>
			</>}
			{documents.length === 1 && <>
				<Typography variant="h6">{this.props.t('signing.signHeaderSingle')}</Typography>
				<Typography variant="body">{this.props.t('signing.instructionsSingle')}</Typography>
			</>}
			<Typography variant="body2"
						display="block"
						color="error"
						sx={{mt: 0.5}}
			>
				{hasNonFilledInRequiredFormFields && this.props.t('signing.formFieldsRequired')}
			</Typography>
			<Typography variant="body2"
						color="error"
						display="block"
						sx={{mt: 0.5}}
			>
				{hasFormFieldErrors && this.props.t('signing.formFieldsValidValueRequired')}
			</Typography>
			<Box sx={{mt: 1, display: 'flex', flexDirection: 'column', gap: 1}}>
				{documentsExtended.map((document, index) => {
					return <Box key={document.signRequestId}
								sx={{display: 'flex', alignItems: 'center', maxWidth: 'inherit'}}>
						{/* name + visibility */}
						<Box sx={{
							display: 'flex',
							flexGrow: 1,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							width: 0
						}}>
							<Typography variant="body2"
										title={document.name}
										color={document.hasFormFieldError ? 'red' : undefined}
										sx={{
											wordWrap: 'break-word',
											overflowWrap: 'break-word',
											wordBreak: 'break-all',
											whiteSpace: 'nowrap',
											overflow: 'hidden'
										}}
							>
								{document.name}
							</Typography>
							{index === this.state.viewIndex &&
								<VisibilityIcon fontSize="small" sx={{ml: 1, mr: 1}}/>}
						</Box>

						{/* status, hide when very small */}
						<Box sx={{display: {xs: 'none', sm: 'block'}, mr: 1, alignSelf: 'center'}}>
							<Chip
								label={<Typography variant="body2">
									{document.documentState === 'ATTACHMENT' || document.documentState === 'ATTACHMENT_GENERIC' ?
										this.props.t('signing.attachment') :
										this.props.t('signing.signRequestState_' + document.signRequestState)}
								</Typography>}
								size="small"/>
						</Box>

						{/* view + download */}
						<Box sx={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							gap: 2,
							justifyContent: 'flex-end',
							flexShrink: 0
						}}>
							{documents.length > 1 && <Button
								variant="outlined" size="small"
								onClick={() => this.onChangeViewIndex(index)}
								id={"btn-signing-view-" + index}
								disabled={index === this.state.viewIndex || document.documentState === 'ATTACHMENT_GENERIC'}
							>
								{this.props.t('signing.view')}
							</Button>}
							<Badge badgeContent={document.messageCount || 0} color="success" max={99}>
								<IconButton
									variant="outlined" size="small"
									disabled={'WAITING_FOR_SIGNATURE' !== document.signRequestState || !document.messageEnabled}
									onClick={() => this.onChangeMessageTimelineDialogDocumentId(document.id)}
									id={"btn-signing-message-timeline-" + index}
								>
									<ForumIcon fontSize="small" />
								</IconButton>
							</Badge>
							<Button
								variant="outlined" size="small"
								disabled={'DELETED' === document.documentState}
								onClick={() => this.props.onDocumentSingleDownloadArtifacts(document.id, ['DOCUMENT', 'ATTACHMENT', 'DECLINE_ATTACHMENT', 'PREVIOUS_VERSIONS'])}
								id={"btn-signing-download-" + index}
							>
								{this.props.t('signing.download')}
							</Button>
						</Box>
					</Box>
				})}
				{documents.length > 1 && <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<Button variant="outlined" size="small"
							disabled={allDownloadsDisabled}
							onClick={this.onDownloadAll}
					>
						{this.props.t('signing.downloadAll')}
					</Button>
				</Box>}
				{requireMarkAsRead && <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<FormGroup>
						<FormControlLabel control={
							<Checkbox
								checked={documentsWaitingForSignature.length === 0 || this.state.markedDocumentsAsRead}
								onChange={this.onChangeMarkAddDocumentsAsRead}/>}
										  label={this.props.t('signing.readAllDocuments')}
										  disabled={documentsWaitingForSignature.length === 0}
						/>
					</FormGroup>
				</Box>}
			</Box>

			<Box sx={{mt: 2, display: 'flex', alignItems: 'center', maxWidth: 'inherit', gap: 1, flexDirection: 'column'}}>
				<Box sx={{display: {xs: 'block', md: 'none'}, mr: 2}}>
					<Logo style={{height: '54px'}}/>
				</Box>
				{documentsWaitingForSignature.length > 0 && <Box sx={{display: 'flex', width: '100%'}}>
					<Box sx={{flexGrow: 1}}/>
					<Box sx={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
						gap: 1,
						justifyContent: 'flex-end'
					}}>
						<Button
							variant="contained"
							startIcon={<EditIcon/>}
							disabled={this.props.signingBusy || (requireMarkAsRead && !this.state.markedDocumentsAsRead) || hasNonFilledInRequiredFormFields || hasFormFieldErrors}
							onClick={this.onOpenSigningDialogForSigning}
							id="btn-signing-sign"
						>
							{this.props.t('signing.sign')}
						</Button>
						{declineEnabled && <Button
							variant="contained"
							startIcon={<NotInterestedIcon/>}
							disabled={this.props.signingBusy}
							onClick={this.onOpenSigningDialogForDeclining}
							id="btn-signing-decline"
						>
							{this.props.t('signing.decline')}
						</Button>}
						{forwardingEnabled && <Button
							variant="contained"
							startIcon={<ShortcutIcon/>}
							disabled={this.props.signingBusy}
							onClick={this.onOpenForwardDialog}
							id="btn-signing-forward"
						>
							{this.props.t('signing.forward')}
						</Button>}
					</Box>
				</Box>}
				{documentsWaitingForSignature.length === 0 && hasSignedOrDeclined && !documentsSigned && !documentsDeclined &&
					<Alert severity="info" sx={{flexGrow: 1, width: '100%'}}>{this.props.t('signing.everythingSignedDeclined')}</Alert>}
				{documentsSigned && documents.length > 1 &&
					<Alert severity="info" sx={{flexGrow: 1, width: '100%'}}>{this.props.t('signing.everythingSignedMultiple')}</Alert>}
				{documentsSigned && documents.length === 1 &&
					<Alert severity="info" sx={{flexGrow: 1, width: '100%'}}>{this.props.t('signing.everythingSignedSingle')}</Alert>}
				{documentsDeclined &&
					<Alert severity="info" sx={{flexGrow: 1, width: '100%'}}>{this.props.t('signing.everythingDeclined')}</Alert>}
				{selectedDocumentDeleted &&
					<Alert severity="warning" sx={{flexGrow: 1, width: '100%'}}>{this.props.t('signing.documentDeleted')}</Alert>}
			</Box>
		</Paper>;
	}

	renderVisualSignature = () => {
		return <Paper variant="outlined" sx={{p: 2, mt: 1}}>
			<Box sx={{display: 'flex', flexWrap: 'wrap'}}>
				{this.props.signingData.visualSignatureSelectionEnabled && <Box sx={{flexBasis: '50%', flexGrow: 1}}>
					<VisualSignatureOrParaphComponent type="signature"
													  visualSignature={this.getVisualSignature()}
													  onChangeVisualSignature={this.onChangeCurrentVisualSignature}/>
					{!this.props.signingKioskMode && !!this.state.visualSignature.overwrite && !!this.state.visualSignature.data &&
						<FormControlLabel
							control={
								<Checkbox checked={this.state.visualSignature.saveAsDefault}
										  onChange={this.onChangeSaveSignatureAsDefault}
										  sx={{p: 0}}
								/>
							}
							label={<Typography
								variant="body2">{this.props.t('signing.visualSignatureSaveAsDefault')}</Typography>}
							disabled={this.props.signingBusy}
							sx={{m: 0}}
						/>}
				</Box>
				}
				<Box sx={{flexBasis: '50%', flexGrow: 1}}>
					<VisualSignatureOrParaphComponent type="paraph"
													  visualSignature={this.getVisualParaph()}
													  onChangeVisualSignature={this.onChangeCurrentVisualParaph}/>
					{!this.props.signingKioskMode && !!this.state.visualParaph.overwrite && !!this.state.visualParaph.data &&
						<FormControlLabel
							control={
								<Checkbox checked={this.state.visualParaph.saveAsDefault}
										  onChange={this.onChangeSaveParaphAsDefault}
										  sx={{p: 0}}
								/>
							}
							label={<Typography
								variant="body2">{this.props.t('signing.visualParaphSaveAsDefault')}</Typography>}
							disabled={this.props.signingBusy}
							sx={{m: 0}}
						/>}
				</Box>
			</Box>
		</Paper>;
	}

	renderFormFields = () => {
		const selectedDocument = this.props.signingData.documents[this.state.viewIndex];
		const formFields = ((this.state.signRequestFormFields || [])
			.filter(entry => entry.signRequestId === selectedDocument?.signRequestId)
			.at(0)?.formFields || [])
			.filter(field => field.editable)
		;

		formFields.sort((a, b) => (a?.name || '').localeCompare(b?.name || ''));

		if (formFields.length === 0) return;

		return <Accordion variant="outlined"
						  disableGutters
						  defaultExpanded
						  sx={{
							  mt: 1,
							  '&:before': {
								  display: 'none',
							  }
						  }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
				<Typography variant="h6">{this.props.t('signing.formFields')}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 0}}>
				<SigningEditFormComponent
					formFields={formFields}
					onUpdateFormFieldValue={this.onUpdateFormFieldValue}
					pdfViewerApiRef={this.pdfViewerApiRef}
				/>
			</AccordionDetails>
		</Accordion>
	}

	renderApprovers = () => {
		const documents = this.props.signingData.documents;
		const approvers = documents[this.state.viewIndex]?.approvers || [];
		if (approvers.length === 0) return null;

		return <Accordion variant="outlined"
						  disableGutters={true}
						  defaultExpanded={SHOW_FULL_INFO}
						  sx={{
							  mt: 1,
							  '&:before': {
								  display: 'none',
							  }
						  }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
				<Typography variant="h6">{this.props.t('signing.approvers')}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{pt: 0}}>
				<Typography
					variant="body"
					sx={{
						wordWrap: 'break-word',
						overflowWrap: 'break-word',
						wordBreak: 'break-all'
					}}>{this.props.t('signing.approversDescription') + ' ' + documents[this.state.viewIndex].name}</Typography>
				<List dense={true}>
					{approvers.map(this.renderApprover)}
				</List>
			</AccordionDetails>
		</Accordion>
	}

	renderApprover = (approver) => {
		let icon = null;
		if ('APPROVED' === approver.approvalRequestState) {
			icon = <TaskIcon/>;
		} else if ('DECLINED' === approver.approvalRequestState) {
			icon = <ThumbDownAltIcon/>;
		}

		let text = this.props.t('approval.approvalRequestState_' + approver.approvalRequestState);
		if (approver.declineReason) {
			text += ": " + approver.declineReason;
		}

		return <ListItem disablePadding={true} key={approver.id}>
			<ListItemIcon sx={{minWidth: '35px'}}>{icon}</ListItemIcon>
			<ListItemText primary={approver.person.fullName} secondary={text}/>
		</ListItem>;
	}

	renderPdfViewer = () => {
		if (this.props.sessionSwitchKioskUserBusy || !this.props.signingData || this.props.signingData.documents.length === 0) {
			// show a dummy PdfViewer to prevent screen flinching when switching between kiosk users
			return <PdfViewerComponent
				pages={[{placeholder: true}]}
			/>
		}

		const selectedDocument = this.props.signingData.documents[this.state.viewIndex];
		if ('DELETED' === selectedDocument.documentState) {
			return <PdfViewerComponent
				pages={[{placeholder: true}]}
			/>
		}

		const signatureFields = (selectedDocument.extraSignatureFields || [])
			.concat(!!selectedDocument.signatureField ? [selectedDocument.signatureField] : []);

		// caching is handled backend
		const pdfUrl = `/api/internal/document/${selectedDocument.id}/pdf`;

		// builds a list of all sign requests that are signed
		// this is used to diversify the URL (via signed query parameter), so we don't have an invalid cached result
		const documentSignedSignRequests = this.props.signingData.documents
			.filter(doc =>
				(doc.id === selectedDocument.id) &&
				(doc.signRequestState === 'SIGNED' || this.props.signingRequestsSigned.indexOf(doc.signRequestId) >= 0))
			.map(doc => doc.signRequestId)
			.sort();
		const documentSignedSignRequestsJoined = documentSignedSignRequests.join('-');
		const suffix = (documentSignedSignRequests.length > 0) ? '?signed=' + documentSignedSignRequestsJoined : '';
		const statusUrl = `/api/internal/document/${selectedDocument.id}/preview/status${suffix}`;

		const selectedDocumentPages = Array(selectedDocument.pageCount)
			.fill(null)
			.map((ign, index) => {
				const pageHasSignatureField =
					signatureFields.some(field => field.pageIndex === index) ||
					(selectedDocument.paraphFields || []).some(field => field.pageIndex === index) ||
					(selectedDocument.formFields || []).some(field => field.pageIndex === index);
				return {
					imageUrl: `/api/internal/document/${selectedDocument.id}/preview/${index}/image${suffix}`,
				};
			});

		const signatureFieldTextColor = '000000';
		const selectedDocumentOverlays = [];

		if (selectedDocument.signRequestState === 'WAITING_FOR_SIGNATURE' &&
			this.props.signingRequestsSigned.indexOf(selectedDocument.signRequestId) === -1) {

			// signature fields
			signatureFields.forEach((field, index) =>
				selectedDocumentOverlays.push({
					pageIndex: field.pageIndex,
					relativeLocationX: field.relativeLocationX,
					relativeLocationY: field.relativeLocationY,
					relativeWidth: field.relativeWidth,
					relativeHeight: field.relativeHeight,
					component:
						<Paper
							key={'signature-' + index}
							elevation={4}
							sx={{
								backgroundColor: BACKGROUND_COLOR_TO_RGBA(field?.style?.backgroundColor, field?.style?.backgroundOpacity),
								color: signatureFieldTextColor,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.verticalAlignment),
								alignItems: HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.horizontalAlignment),
								width: '100%',
								height: '100%',
								userSelect: 'none',
								fontSize: field?.style?.fontSize ? Math.round(this.state.widthFactor * field.style.fontSize) + 'pt' : 'min(1.2vw, 16px)',
								p: 0.5,
							}}>
							<Box sx={{textAlign: 'center', width: '100%'}}>
								{this.props.t('signing.location')}
							</Box>
							<Box sx={{textAlign: 'center', width: '100%', fontSize: 'smaller'}}>
								{this.getCurrentSignerFullName()}
							</Box>
							<Box sx={{textAlign: 'center', width: '100%', fontSize: 'smaller'}}>
								{selectedDocument.capacityTitle}
							</Box>
						</Paper>
				})
			)

			// paraphs
			if (!!selectedDocument.paraphFields) {
				selectedDocument.paraphFields.forEach((field, index) => {
					selectedDocumentOverlays.push({
						pageIndex: field.pageIndex,
						relativeLocationX: field.relativeLocationX,
						relativeLocationY: field.relativeLocationY,
						relativeWidth: field.relativeWidth,
						relativeHeight: field.relativeHeight,
						component:
							<Paper
								key={'paraph' + index}
								elevation={4}
								sx={{
									backgroundColor: BACKGROUND_COLOR_TO_RGBA(field?.style?.backgroundColor, field?.style?.backgroundOpacity),
									color: signatureFieldTextColor,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.verticalAlignment),
									alignItems: HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.horizontalAlignment),
									textAlign: 'center',
									width: '100%',
									height: '100%',
									userSelect: 'none',
									fontSize: field?.style?.fontSize ? Math.round(this.state.widthFactor * field.style.fontSize) + 'pt' : 'min(1.0vw, 10px)',
									p: 0.5,
								}}>
								{this.props.t('signing.locationParaph')}
							</Paper>
					});
				})
			}

			// Form fields
			const formFields = (this.state.signRequestFormFields || [])
				.filter(entry => entry.signRequestId === selectedDocument?.signRequestId)
				.at(0)
				?.formFields || [];
			formFields.forEach((field, index) =>
				selectedDocumentOverlays.push({
					pageIndex: field.pageIndex,
					relativeLocationX: field.relativeLocationX,
					relativeLocationY: field.relativeLocationY,
					relativeWidth: field.relativeWidth,
					relativeHeight: field.relativeHeight,
					component:
						<>
							{(field.editable || 'ATTRIBUTE' === field.type) &&
								<Paper
									key={'form-field' + field.id}
									elevation={2}
									sx={{
										backgroundColor: BACKGROUND_COLOR_TO_RGBA(field?.style?.backgroundColor, field?.style?.backgroundOpacity),
										color: signatureFieldTextColor,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										width: '100%',
										height: '100%',
										userSelect: 'none',
										cursor: field.editable ? 'pointer' : '',
									}}
									onClick={() => this.onChangeFormFieldToFillIn(field)}
								>
									{this.renderFormFieldContent(field)}
								</Paper>
							}
							{(!field.editable && 'ATTRIBUTE' !== field.type) && this.renderFormFieldContent(field)}
						</>
				})
			);
		}

		return <PdfViewerComponent
			key={selectedDocument.id + documentSignedSignRequestsJoined}
			apiRef={this.pdfViewerApiRef}
			onResize={this.onPdfViewerResized}
			useClientPdfRendering={!!this.props.sessionInfo.useClientPdfRendering}
			pdfUrl={pdfUrl}
			statusUrl={statusUrl}
			pages={selectedDocumentPages}
			overlays={selectedDocumentOverlays}>
			<FixedWidthHeaderComponent maxWidth={300} value={selectedDocument.name}/>
		</PdfViewerComponent>
	}

	renderFormFieldContent = (field) => {
		const normalizedFontSize = !!field?.style?.fontSize ? Math.round(this.state.widthFactor * field.style.fontSize) : null;
		return <>
			<Box
				title={!!field.value ? (field.name + (field.required ? '*' : '')) : undefined}
				sx={{
				fontSize: !!normalizedFontSize ? normalizedFontSize + 'pt' : 'min(1.2vw, 16px)',
				flexGrow: 1,
				display: 'flex',
				justifyContent: HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.horizontalAlignment),
				alignItems: VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.verticalAlignment),
				p: 0.5
			}}>
				{!!field.value && <Box>
					{('TEXT' === field.type || 'NUMERIC' === field.type || 'ATTRIBUTE' === field.type && 'META_FIELD' === field.attributeType) &&
						<Typography sx={{fontSize: 'inherit'}}>{field.value}</Typography>}
					{'CHECKBOX' === field.type && <>
						{'true' === field.value && <CheckBoxOutlinedIcon sx={{fontSize: 'inherit'}}/>}
						{'true' !== field.value && <CheckBoxOutlineBlankIcon sx={{fontSize: 'inherit'}}/>}
					</>}
					{'DATE' === field.type && <Typography
						sx={{fontSize: 'inherit'}}>{format(parse(field.value, FORM_FIELD_DATE_VALUE_FORMAT, Date.now()), FORM_FIELD_DATE_VISUAL_FORMAT)}</Typography>}
				</Box>}
				{!field.value && 'ATTRIBUTE' === field.type && <>
					<ContactPageIcon fontSize="small"/>
					<Typography
						sx={{fontSize: 'inherit'}}>{this.props.t('signing.formFieldsAttribute_' + field.attributeType)}</Typography>
				</>}
			</Box>
			{field.editable && <Box sx={{
				fontSize: !!normalizedFontSize ? normalizedFontSize + 'pt' : 'min(1.0vw, 10px)',
				position: 'absolute',
				bottom: '0px'
			}}>
				{!field.value ? (field.name + (field.required ? '*' : '')) : ''}
			</Box>}
		</>
	}

	onSessionCustomCreator = () => {
		// this is called everytime, even though we have a session
		// the reason is that we can have a 'normal' session, but guest key
		// the guest key will need to have priority
		const data = this.props?.router?.params?.data;
		if (data.startsWith('ids=')) {
			// we could destroy current session and create new, but that's a bit stupid
			if (!this.props.sessionCreated) {
				this.props.onSessionCheck();
			} else {
				this.fetchSigningData();
			}
		} else if (data.startsWith('guestKey=')) {
			const guestSignKey = this.getParamValueFromUrlData(data, 'guestKey');
			if (this.props?.sessionGuestPath == null || !this.props?.sessionGuestPath.includes(guestSignKey)) {
				this.props.onMenuClose();
				this.props.onSessionDestroy();
				this.props.onSessionCreateGuest({signKey: guestSignKey});
			} else if (!this.props.signingData) {
				this.fetchSigningData();
			}
		} else if (data.startsWith('guestBulkKey=')) {
			const guestBulkSignKey = this.getParamValueFromUrlData(data, 'guestBulkKey');
			if (this.props?.sessionGuestPath == null || !this.props?.sessionGuestPath.includes(guestBulkSignKey)) {
				this.props.onMenuClose();
				this.props.onSessionDestroy();
				this.props.onSessionCreateGuest({bulkSignKey: guestBulkSignKey});
			} else if (!this.props.signingData) {
				this.fetchSigningData();
			}
		}
	}

	fetchSigningData = () => {
		const data = this.props?.router?.params?.data;
		if (data.startsWith('ids=')) {
			const signIds = this.getParamValueFromUrlData(data, 'ids');
			this.props.onSigningFetchData(signIds);
		} else {
			this.props.onSigningFetchData();
		}
	}

	onChangeViewIndex = (viewIndex) => {
		this.setState({viewIndex});
	}

	onChangeMessageTimelineDialogDocumentId = (messageTimelineDialogDocumentId) => {
		this.setState({messageTimelineDialogDocumentId});
	}

	onCloseMessageTimelineDialog = () => {
		const documentId = this.state.messageTimelineDialogDocumentId;
		this.setState({messageTimelineDialogDocumentId: null}, () => this.props.onSigningMessageFetchCount(documentId));
	}

	onOpenSigningDialogForSigning = () => {
		this.setState({signingDialogOpen: true, declining: false});
	}

	onOpenSigningDialogForDeclining = () => {
		this.setState({signingDialogOpen: true, declining: true});
	}

	onCloseSigningDialog = (refreshData) => {
		this.setState({signingDialogOpen: false}, () => {
			if (refreshData) {
				this.fetchSigningData();
			} else {
				this.props.onSigningSignErrorClear();
			}
		});
	}

	onSigningCompleted = () => {
		this.setState({signingDialogOpen: false, useMailRedirectConfig: false});
	}

	onCloseItsmeBusyDialog = () => {
		this.setState({itsmeBusyDialogOpen: false}, this.props.onSigningSignErrorClear)
	}

	onOpenForwardDialog = () => {
		this.setState({forwardDialogOpen: true});
	}

	onCloseForwardDialog = () => {
		this.setState({forwardDialogOpen: false});
	}

	onChangeFormFieldToFillIn = (formFieldToFillIn) => {
		if (formFieldToFillIn.editable) {
			this.setState({formFieldToFillIn});
		}
	}

	onCloseFormFieldDialog = () => {
		this.setState({formFieldToFillIn: null})
	}

	onChangeFormFieldValue = (value) => {
		this.onUpdateFormFieldValue(this.state.formFieldToFillIn.id, value);
	}

	onUpdateFormFieldValue = (formFieldId, value) => {
		const selectedDocument = this.props.signingData.documents[this.state.viewIndex];
		const signRequestFormFields = (this.state.signRequestFormFields || []).map(entry => {
			if (entry.signRequestId === selectedDocument?.signRequestId) {
				return {
					...entry,
					formFields: entry.formFields.map(field => (formFieldId !== field.id) ? field : {...field, value})
				};
			}
			return entry;
		});
		this.setState({signRequestFormFields, formFieldToFillIn: null});
	}

	onChangeCurrentVisualSignature = (visualSignature, mimeType, fileSize) => {
		this.setState({
			visualSignature: {
				data: visualSignature,
				overwrite: true,
				mimeType: mimeType,
				fileSize: fileSize,
				saveAsDefault: !!visualSignature ? this.state.visualSignature.saveAsDefault : false
			}
		});
	}

	onChangeCurrentVisualParaph = (visualParaph, mimeType, fileSize) => {
		this.setState({
			visualParaph: {
				data: visualParaph,
				overwrite: true,
				mimeType: mimeType,
				fileSize: fileSize,
				saveAsDefault: !!visualParaph ? this.state.visualParaph.saveAsDefault : false
			}
		});
	}

	onChangeSaveSignatureAsDefault = (e, value) => {
		this.setState({
			visualSignature: {
				...this.state.visualSignature,
				saveAsDefault: value
			}
		});
	}

	onChangeSaveParaphAsDefault = (e, value) => {
		this.setState({
			visualParaph: {
				...this.state.visualParaph,
				saveAsDefault: value
			}
		});
	}

	onDownloadAll = () => {
		this.props.onDocumentDownloadArtifacts(this.props.signingData.documents.map(doc => doc.id), ['DOCUMENT'])
	}

	onChangeMarkAddDocumentsAsRead = (e, value) => {
		this.setState({markedDocumentsAsRead: value})
	}

	onPdfViewerResized = (widthFactor) => {
		this.setState({widthFactor});
	}

	getParamValueFromUrlData = (data, key) => {
		const params = data.split('&');
		const keyValue = params.find(param => param.startsWith(key));
		if (!!keyValue) {
			return keyValue.substring(key.length + 1);
		}

		return null;
	}

	onChangeKioskUser = (user) => {
		const data = this.props?.router?.params?.data;
		let documentId;
		if (data.startsWith('ids=')) {
			// we only expect a single document reference in kiosk mode
			documentId = this.getParamValueFromUrlData(data, 'ids');
		}

		this.setState({
			viewIndex: 0,
			visualSignature: VISUAL_SIGNATURE_PARAPH_DEFAULT,
			visualParaph: VISUAL_SIGNATURE_PARAPH_DEFAULT
		}, () => this.props.onSessionSwitchKioskUser(documentId, user.id));
	}

	getVisualSignature = () => {
		if (this.state.visualSignature.overwrite) {
			return this.state.visualSignature.data;
		}

		if (this.props.signingKioskMode) {
			return this.props.sessionInfo?.kioskUserVisualSignature;
		} else {
			return this.props.sessionInfo?.visualSignature;
		}
	}

	getVisualParaph = () => {
		if (this.state.visualParaph.overwrite) {
			return this.state.visualParaph.data;
		}

		if (this.props.signingKioskMode) {
			return this.props.sessionInfo?.kioskUserVisualParaph;
		} else {
			return this.props.sessionInfo?.visualParaph;
		}
	}

	getCurrentSignerFullName = () => {
		if (!this.props.signingKioskMode) {
			return this.props.sessionInfo.userFullName;
		}

		return this.props.sessionInfo.kioskUserFullName;
	}

	onSessionClearKioskSigner = () => {
		this.props.onSessionClearKioskSigner();
	}
}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			sessionSwitchKioskUserBusy: state.session.switchKioskUserBusy,
			sessionGuestPath: state.session.guestPath,
			sessionCreated: state.session.created,

			signingBusy: state.signing.busy,
			signingServerError: state.signing.serverError,
			signingData: state.signing.data,
			signingGuestAccessRenewed: state.signing.guestAccessRenewed,
			signingKioskUsers: state.signing.kioskSigners,
			signingKioskMode: !!state.signing.kioskSigners,
			signingRequestsSigned: state.signing.requestsSigned,
			signingRequestsLockedState: state.signing.requestsLockedState,
			signingRequestsCompletedState: state.signing.requestsCompletedState,
		}
	},
	dispatch => {
		return {
			onSigningFetchData: (ids) => {
				dispatch({
					type: 'SIGNING_FETCH_DATA',
					ids
				});
			},
			onSigningClearData: () => {
				dispatch({
					type: 'SIGNING_CLEAR_DATA'
				});
			},
			onSigningRenewGuestAccess: () => {
				dispatch({
					type: 'SIGNING_RENEW_GUEST_ACCESS'
				});
			},
			onSigningMessageFetchCount: (documentId) => {
				dispatch({
					type: 'SIGNING_MESSAGE_FETCH_COUNT',
					documentId
				})
			},
			onSigningSignErrorClear: () => {
				dispatch({
					type: 'SIGNING_SIGN_ERROR_CLEAR'
				})
			},
			onDocumentDownloadArtifacts: (ids, types) => {
				dispatch({
					type: 'DOCUMENT_DOWNLOAD_ARTIFACTS',
					ids,
					types
				});
			},
			onDocumentSingleDownloadArtifacts: (id, types) => {
				dispatch({
					type: 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS',
					id,
					types
				});
			},
			onSessionCheck: () => {
				dispatch({
					type: 'SESSION_CHECK'
				})
			},
			onSessionDestroy: () => {
				dispatch({
					type: 'SESSION_DESTROY'
				})
			},
			onSessionSwitchKioskUser: (documentId, signerId) => {
				dispatch({
					type: 'SESSION_SWITCH_KIOSK_USER',
					documentId, signerId
				})
			},
			onSessionClearKioskSigner: () => {
				dispatch({
					type: 'SESSION_SWITCH_KIOSK_USER',
					documentId: null,
					signerId: null
				})
			},
			onSessionCreateGuest: (request) => {
				dispatch({
					type: 'SESSION_CREATE_GUEST',
					request
				})
			},
			onMenuClose: () => {
				dispatch({
					type: 'MENU_CLOSE'
				})
			},
		}
	}
)(SigningComponent)));
