import React, {Component, useCallback, useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Radio,
	RadioGroup,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import {SIGNATURE_TYPES} from "../common/Constants";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";

const CompanyItsmeConfigDialog = ({open, itsmeConfig, onSubmit, onClose}) => {

	const {t} = useTranslation();

	const [name, setName] = useState(itsmeConfig?.name || '');
	const [defaultConfig, setDefaultConfig] = useState(itsmeConfig?.defaultConfig || false);
	const [partnerCode, setPartnerCode] = useState(itsmeConfig?.partnerCode || '');
	const [serviceCode, setServiceCode] = useState(itsmeConfig?.serviceCode || '');
	const [heavyUserEnabled, setHeavyUserEnabled] = useState(itsmeConfig?.heavyUserEnabled || false);
	const [nrnEnabled, setNrnEnabled] = useState(itsmeConfig?.nrnEnabled || false);

	const onChangeName = useCallback((e) => {
		setName(e.target.value)
	}, []);

	const onChangeDefaultConfig = useCallback((e, value) => {
		setDefaultConfig(value)
	}, []);

	const onChangePartnerCode = useCallback((e) => {
		setPartnerCode(e.target.value)
	}, []);

	const onChangeServiceCode = useCallback((e) => {
		setServiceCode(e.target.value)
	}, []);

	const onChangeHeavyUserEnabled = useCallback((e, value) => {
		setHeavyUserEnabled(value)
	}, []);

	const onChangeNrnEnabled = useCallback((e, value) => {
		setNrnEnabled(value)
	}, []);

	const onLocalClose = useCallback((e, reason) => {
		if (reason !== 'backdropClick') {
			onClose();
		}
	}, [onClose]);

	const onLocalSubmit = useCallback(() => {
		onSubmit({
			...itsmeConfig,
			name,
			defaultConfig,
			partnerCode,
			serviceCode,
			heavyUserEnabled,
			nrnEnabled
		});
	}, [onSubmit, name, partnerCode, serviceCode, defaultConfig, heavyUserEnabled, nrnEnabled]);

	const onKeyUp = useCallback((e) => {
		if (e.key === 'Enter') {
			onLocalSubmit();
		}
	}, [onLocalSubmit]);

	useEffect(() => {
		setName(itsmeConfig?.name || '')
		setDefaultConfig(itsmeConfig?.defaultConfig || false)
		setPartnerCode(itsmeConfig?.partnerCode || '')
		setServiceCode(itsmeConfig?.serviceCode || '')
		setHeavyUserEnabled(itsmeConfig?.heavyUserEnabled || false)
		setNrnEnabled(itsmeConfig?.nrnEnabled || false)
	}, [itsmeConfig])

	return <Dialog
		open={open}
		onClose={onLocalClose}
		onKeyUp={onKeyUp}
		fullWidth
		maxWidth="md"
	>
		<DialogTitle>{t('company.adminSettingsItsmeConfig')}</DialogTitle>
		<DialogContent>
			<Box sx={{display: 'flex', flexDirection: 'column', pt: 1}}>
				<TextField
					variant="outlined"
					label={t('company.adminSettingsItsmeConfigName')}
					value={name}
					onChange={onChangeName}
					autoComplete="off"
					fullWidth
					required
					size="small"
					sx={{mb: 2}}
				/>
				<TextField
					variant="outlined"
					label={t('company.adminSettingsItsmePartnerCode')}
					value={partnerCode}
					onChange={onChangePartnerCode}
					autoComplete="off"
					fullWidth
					required
					size="small"
					sx={{mb: 2}}
				/>
				<TextField
					variant="outlined"
					label={t('company.adminSettingsItsmeServiceCode')}
					value={serviceCode}
					onChange={onChangeServiceCode}
					autoComplete="off"
					fullWidth
					required
					size="small"
					sx={{mb: 2}}
				/>
				<FormControlLabel
					control={<Checkbox checked={defaultConfig}
									   onChange={onChangeDefaultConfig}/>}
					label={t('company.adminSettingsItsmeConfigDefault')}
					sx={{ m: 0 }}/>
				<FormControlLabel
					control={<Checkbox checked={heavyUserEnabled}
									   onChange={onChangeHeavyUserEnabled}/>}
					label={t('company.adminSettingsItsmeConfigHeavyUserEnabled')}
					sx={{ m: 0 }}/>
				<FormControlLabel
					control={<Checkbox checked={nrnEnabled}
									   onChange={onChangeNrnEnabled}/>}
					label={t('company.adminSettingsItsmeConfigNrnEnabled')}
					sx={{ m: 0 }}/>
			</Box>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={onClose}
				id="btn-itsme-config-edit-cancel"
			>
				{t('cancel')}
			</Button>
			<Button
				variant="contained"
				onClick={onLocalSubmit}
				disabled={!name || !partnerCode}
				id="btn-itsme-config-edit-save"
			>
				{t('save')}
			</Button>
		</DialogActions>
	</Dialog>;
}


class CompanyAdminSettingsDialog extends Component {

	constructor(props) {
		super(props);

		this.state = {
			itsmeConfig: null
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.props.onCompanyFetchAdminSettings(this.props.companyId);
		}
	}

	render() {
		const signatureTypes = Object.keys(SIGNATURE_TYPES);
		const companyAdminSettings = this.props.companyAdminSettings;

		const itsmeConfigs = [...(companyAdminSettings?.itsmeConfigs || [])].sort((a, b) => {
			if (a.id < 0 && b.id > 0) return 1;
			if (a.id > 0 && b.id < 0) return -1;
			return a.id - b.id;
		})

		const itsmeConfigColumns = [
			{
				field: 'name',
				headerName: this.props.t('company.adminSettingsItsmeConfigName'),
				editable: false,
				sortable: false,
				flex: 1
			},
			{
				field: 'defaultConfig',
				headerName: this.props.t('company.adminSettingsItsmeConfigDefault'),
				editable: false,
				sortable: false,
				width: 120,
				align: 'center',
				renderCell: (cellValues) => cellValues.row.defaultConfig ? <CheckIcon fontSize="small"/> : null
			},
			{
				field: 'heavyUserEnabled',
				headerName: this.props.t('company.adminSettingsItsmeConfigHeavyUserEnabled'),
				editable: false,
				sortable: false,
				width: 100,
				align: 'center',
				renderCell: (cellValues) => cellValues.row.heavyUserEnabled ? <CheckIcon fontSize="small"/> : null
			},
			{
				field: 'nrnEnabled',
				headerName: this.props.t('company.adminSettingsItsmeConfigNrnEnabled'),
				editable: false,
				sortable: false,
				width: 230,
				align: 'center',
				renderCell: (cellValues) => cellValues.row.nrnEnabled ? <CheckIcon fontSize="small"/> : null
			},
			{
				field: 'actions',
				headerName: '',
				editable: false,
				sortable: false,
				width: 100,
				align: 'center',
				renderCell: (cellValues) => <>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.adminSettingsItsmeConfigEdit')}
						disabled={companyAdminSettings.enabledSignatureTypes.indexOf('ITSME') < 0}
						onClick={() => this.onItsmeConfigEdit({...cellValues.row})}
					>
						<SettingsIcon fontSize="small"/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.adminSettingsItsmeConfigDelete')}
						disabled={companyAdminSettings.enabledSignatureTypes.indexOf('ITSME') < 0}
						onClick={() => this.onItsmeConfigDelete(cellValues.row.id)}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</>
			},
		];


		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   onKeyUp={this.onKeyUp}
					   fullWidth
					   maxWidth="md"
		>
			<DialogTitle>{this.props.t('company.adminSettingsHeader')}</DialogTitle>
			{!companyAdminSettings && <DialogContent><LoadingComponent /></DialogContent>}
			{companyAdminSettings && <DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.companyName')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.name')}
								required
								value={companyAdminSettings.companyName}
								onChange={this.onChangeName}
								autoComplete="off"
								fullWidth
								autoFocus
								sx={{ m: 0 }}
							/>

							<Box sx={{mt: 1}}>
								<FormControlLabel
									control={<Checkbox checked={companyAdminSettings.inactive} sx={{ p: 0 }}
													   onChange={this.onChangeInactive}/>}
									label={this.props.t('company.adminSettingsInactive')}
									sx={{ m: 0 }}/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsSignatureTypes')}</StepLabel>
						<StepContent>
							{signatureTypes.map(signatureType => {
								const signatureTypeEnabled = companyAdminSettings.enabledSignatureTypes.indexOf(signatureType);
								return <Box key={signatureType}>
									<FormControlLabel
										control={<Checkbox checked={signatureTypeEnabled >= 0} sx={{ p: 0}}
														   onChange={(e, value) => this.onChangeSignatureType(signatureType, value)}/>}
										label={this.props.t('company.adminSettingsSignature_'+ signatureType)}
										sx={{ m: 0 }}
									/>
								</Box>
							})}
						</StepContent>
					</Step>

					{this.props.applicationAdmin && <Step active>
						<StepLabel>{this.props.t('company.adminSettingsItsmeHeader')}</StepLabel>
						<StepContent>
							<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
								<DataGrid
									hideFooter
									disableColumnSelector
									columns={itsmeConfigColumns}

									disableColumnFilter
									disableRowSelectionOnClick
									disableColumnMenu

									rows={itsmeConfigs}

									pagination
									paginationModel={{page: 0, pageSize: 5}}
									pageSizeOptions={[5]}

									density="compact"
									autoHeight
								/>
								<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
									<Button
										variant="contained"
										onClick={this.onItsmeConfigCreate}
										startIcon={<AddIcon/>}
										sx={{mr: 1}}
										id="btn-itsme-config-create"
										disabled={companyAdminSettings.enabledSignatureTypes.indexOf('ITSME') < 0}
										size="small"
									>
										{this.props.t('company.adminSettingsItsmeConfigCreate')}
									</Button>
								</Box>
							</Box>
						</StepContent>
					</Step>
					}

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsApiHeader')}</StepLabel>
						<StepContent>
							<>
								<Box>
									<FormControlLabel
										control={<Checkbox checked={companyAdminSettings.apiEnabled} sx={{p: 0}}
														   onChange={this.onChangeApiEnabled}/>}
										label={this.props.t('company.adminSettingsApiAccess')}
										sx={{m: 0}}/>
								</Box>
							</>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsDomain')}</StepLabel>
						<StepContent>
							<Box>
								<FormControl fullWidth>
									<RadioGroup
										value={this.getUrlDomainTypeRadioGroupValue(companyAdminSettings)}
										onChange={this.onChangeUrlDomainType}
									>
										<FormControlLabel value="NONE" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainNone')}/>
										<FormControlLabel value="SUBDOMAIN" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainSubdomain')}/>
										{companyAdminSettings.urlSubdomainEnabled && <Box sx={{ml: 2, width: '100%'}}>
											<Alert severity="info" sx={{mb: 1}}>{this.props.t('company.adminSettingsDomainSubdomainExample') + ` https://${companyAdminSettings.urlSubdomain}.quill.dioss.com`}</Alert>

											<TextField
												variant="outlined"
												fullWidth
												helperText={this.props.t('company.adminSettingsDomainSubdomainValidation')}
												label={this.props.t('company.adminSettingsDomainSubdomain')}
												value={companyAdminSettings.urlSubdomain || ''}
												onChange={this.onChangeUrlSubdomain}
											/>

										</Box>}

										<FormControlLabel value="CUSTOMDOMAIN" control={<Radio sx={{p: 0.5}}/>}
														  label={this.props.t('company.adminSettingsDomainCustomDomain')}/>
										{companyAdminSettings.urlCustomDomainEnabled && <Box sx={{ml: 2}}>
											<TextField
												InputProps={{
													startAdornment: <InputAdornment position="start" sx={{mr: 0}}>https://</InputAdornment>,
												}}
												variant="outlined"
												fullWidth
												helperText={this.props.t('company.adminSettingsDomainCustomDomainValidation')}
												label={this.props.t('company.adminSettingsDomainCustomDomain')}
												value={companyAdminSettings.urlCustomDomain || ''}
												onChange={this.onChangeUrlCustomDomain}
											/>
										</Box>}
									</RadioGroup>
								</FormControl>

								{(companyAdminSettings.urlSubdomainEnabled || companyAdminSettings.urlCustomDomainEnabled) &&  <Box>
									<Typography sx={{mt: 2}}>{this.props.t('company.adminSettingsWhitelistedFrameDomainsDescription')}</Typography>

									<TextField
										fullWidth
										label={this.props.t('company.adminSettingsWhitelistedFrameDomains')}
										helperText={this.props.t('company.adminSettingsWhitelistedFrameDomainsHelperText')}
										value={companyAdminSettings.whitelistedFrameDomainsDelimited}
										onChange={this.onChangeWhitelistedFrameDomainsDelimited}
										autoComplete="off"
										sx={{mt: 0.5}}
									/>
								</Box>}
							</Box>

						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsEmail')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox checked={companyAdminSettings.mailboxEnabled} sx={{ p: 0 }}
													   onChange={this.onChangeMailboxEnabled}/>}
									label={this.props.t('company.adminSettingsMailboxEnabled')}
									sx={{ m: 0 }}/>
							</Box>
							<Box>
								<TextField
									fullWidth
									label={this.props.t('company.adminSettingsWhitelistedEmailDomains')}
									helperText={this.props.t('company.adminSettingsWhitelistedEmailDomainsHelperText')}
									value={companyAdminSettings.whitelistedEmailDomainsDelimited}
									onChange={this.onChangeWhitelistedEmailDomainsDelimited}
									autoComplete="off"
									sx={{mt:2}}
								/>
							</Box>
						</StepContent>
					</Step>

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsDocuments')}</StepLabel>
						<StepContent>
							<Box>
								<FormControlLabel
									control={<Checkbox checked={companyAdminSettings.pdfRecreatedShown} sx={{ p: 0 }}
													   onChange={this.onChangePdfRecreatedShown}/>}
									label={this.props.t('company.adminSettingsPdfRecreatedShown')}
									sx={{ m: 0 }}/>
							</Box>

							{this.props.applicationAdmin && <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
								<TextField
									variant="outlined"
									size="small"
									type="number"
									value={companyAdminSettings.documentEntryDeletionThreshold || ''}
									onChange={this.onChangeDocumentEntryDeletionThreshold}
									inputProps={{min: 1, max: 730}}
									sx={{mr: 1, width: 80}}
								/>
								<span>{this.props.t('company.adminSettingsDocumentEntryDeletionThreshold')}</span>
							</Box>
							}
						</StepContent>
					</Step>

					{this.props.applicationAdmin &&
						<Step active>
							<StepLabel>{this.props.t('company.adminSettingsMisc')}</StepLabel>
							<StepContent>
								<>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.canManageChildCompanies} sx={{p: 0}}
															   onChange={this.onChangeCanManageChildCompanies}/>}
											label={this.props.t('company.adminSettingsCanManageChildCompanies')}
											disabled={companyAdminSettings.childCompany}
											sx={{m: 0}}/>
									</Box>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.canRequestSensitiveSignerData} sx={{p: 0}}
															   onChange={this.onChangeCanRequestSensitiveSignerData}/>}
											label={this.props.t('company.adminSettingsCanRequestSensitiveSignerData')}
											sx={{m: 0}}/>
									</Box>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.useEidMiddlewarePinInput} sx={{p: 0}}
															   onChange={this.onChangeUseEidMiddlewarePinInput}/>}
											label={this.props.t('company.adminSettingsUseEidMiddlewarePinInput')}
											sx={{m: 0}}/>
									</Box>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.useClientPdfRendering} sx={{p: 0}}
															   onChange={this.onChangeUseClientPdfRendering}/>}
											label={this.props.t('company.adminSettingsUseClientPdfRendering')}
											sx={{m: 0}}/>
									</Box>
									<Box>
										<FormControlLabel
											control={<Checkbox checked={companyAdminSettings.allowFixedCapacityFromUserAttributes} sx={{p: 0}}
															   onChange={this.onChangeAllowFixedCapacityFromUserAttributes}/>}
											label={this.props.t('company.adminSettingsAllowFixedCapacityFromUserAttributes')}
											sx={{m: 0}}/>
									</Box>
								</>
							</StepContent>
						</Step>
					}

					<Step active>
						<StepLabel>{this.props.t('company.adminSettingsFinish')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('company.adminSettingsFinishDescription')}</Typography>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>}

			<DialogActions>
				<Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!companyAdminSettings || !this.canContinue()} onClick={this.onUpdate}
						id="btn-settings-save">
					{this.props.t('save')}
				</Button>
			</DialogActions>

			<CompanyItsmeConfigDialog
				open={!!this.state.itsmeConfig}
				itsmeConfig={this.state.itsmeConfig}
				onClose={this.onItsmeConfigClose}
				onSubmit={this.onItsmeConfigUpdate}
			/>
		</Dialog>
	}

	getUrlDomainTypeRadioGroupValue(companyAdminSettings) {
		if (!companyAdminSettings.urlSubdomainEnabled && !companyAdminSettings.urlCustomDomainEnabled) {
			return 'NONE';
		} else if (companyAdminSettings.urlSubdomainEnabled) {
			return 'SUBDOMAIN';
		} else if (companyAdminSettings.urlCustomDomainEnabled) {
			return 'CUSTOMDOMAIN';
		}
	}

	onChangeName = (e) => {
		this.props.onCompanyChangeAdminSetting('companyName', e.target.value);
	}

	onChangeInactive = (e, value) => {
		this.props.onCompanyChangeAdminSetting('inactive', value);
	}

	onChangeSignatureType = (type, enabled) => {
		const types = this.props.companyAdminSettings.enabledSignatureTypes
			.filter(t => t !== type);
		if (enabled) types.push(type);
		this.props.onCompanyChangeAdminSetting('enabledSignatureTypes', types);
	}

	onChangePdfRecreatedShown = (e, value) => {
		this.props.onCompanyChangeAdminSetting('pdfRecreatedShown', value);
	}

	onChangeDocumentEntryDeletionThreshold = (e) => {
		const value = e.target.value
		if (!!value && (value < 1 || value > 730)) {
			return;
		}

		this.props.onCompanyChangeAdminSetting('documentEntryDeletionThreshold', value);
	}

	onChangeApiEnabled = (e, value) => {
		this.props.onCompanyChangeAdminSetting('apiEnabled', value);
	}

	onChangeMailboxEnabled = (e, value) => {
		this.props.onCompanyChangeAdminSetting('mailboxEnabled', value);
	}

	onChangeWhitelistedEmailDomainsDelimited = (e) => {
		this.props.onCompanyChangeAdminSetting('whitelistedEmailDomainsDelimited', e.target.value);
	}

	onChangeUrlDomainType = (e) => {
		if (e.target.value === 'NONE') {
			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', null);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', null);
		} else if (e.target.value === 'SUBDOMAIN') {
			let	proposedName = this.props.companyAdminSettings.companyName.toLowerCase();
			proposedName = proposedName.replaceAll(/[^a-z0-9-_]/g, "");

			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', null);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', true);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', proposedName);

		} else if (e.target.value === 'CUSTOMDOMAIN') {
			this.props.onCompanyChangeAdminSetting('urlCustomDomainEnabled', true);
			this.props.onCompanyChangeAdminSetting('urlSubdomainEnabled', false);
			this.props.onCompanyChangeAdminSetting('urlSubdomain', null);
		}
	}

	onChangeUrlSubdomain = (e) => {
		if (/^[a-z0-9_-]*$/.test(e.target.value)) {
			this.props.onCompanyChangeAdminSetting('urlSubdomain', e.target.value);
		}
	}

	onChangeUrlCustomDomain = (e) => {
		if (/^[a-z0-9._-]*$/.test(e.target.value)) {
			this.props.onCompanyChangeAdminSetting('urlCustomDomain', e.target.value);
		}
	}

	onChangeWhitelistedFrameDomainsDelimited = (e) => {
		this.props.onCompanyChangeAdminSetting('whitelistedFrameDomainsDelimited', e.target.value);
	}

	onChangeCanManageChildCompanies = (e, value) => {
		this.props.onCompanyChangeAdminSetting('canManageChildCompanies', value);
	}

	onChangeCanRequestSensitiveSignerData = (e, value) => {
		this.props.onCompanyChangeAdminSetting('canRequestSensitiveSignerData', value);
	}

	onChangeUseEidMiddlewarePinInput = (e, value) => {
		this.props.onCompanyChangeAdminSetting('useEidMiddlewarePinInput', value);
	}

	onChangeUseClientPdfRendering  = (e, value) => {
		this.props.onCompanyChangeAdminSetting('useClientPdfRendering', value);
	}

	onChangeAllowFixedCapacityFromUserAttributes = (e, value) => {
		this.props.onCompanyChangeAdminSetting('allowFixedCapacityFromUserAttributes', value);
	}

	onItsmeConfigCreate = () => {
		// gives an id starting from -1, -2, -3, ...
		const id = this.props.companyAdminSettings.itsmeConfigs
			.map(config => config.id)
			.reduce((a, b) => a <= b ? a : b, 0) - 1;

		this.setState({itsmeConfig: {
				id,
				name: '',
				partnerCode: '',
				heavyUserEnabled: false,
				nrnEnabled: false,
			}});
	}

	onItsmeConfigEdit = (itsmeConfig) => {
		this.setState({itsmeConfig})
	}

	onItsmeConfigDelete = (id) => {
		const itsmeConfigs = this.props.companyAdminSettings.itsmeConfigs
			.filter(config => config.id !== id);

		this.props.onCompanyChangeAdminSetting('itsmeConfigs', itsmeConfigs);
	}

	onItsmeConfigClose = () => {
		this.setState({itsmeConfig: null});
	}

	onItsmeConfigUpdate = (itsmeConfig) => {
		let itsmeConfigs = this.props.companyAdminSettings.itsmeConfigs
			.filter(config => config.id !== itsmeConfig.id);

		if (itsmeConfig.defaultConfig) {
			// only 1 default config possible
			itsmeConfigs = itsmeConfigs.map(config => ({
				...config,
				defaultConfig: false
			}));
		}

		itsmeConfigs.push(itsmeConfig);

		this.setState({itsmeConfig: null},
			() => this.props.onCompanyChangeAdminSetting('itsmeConfigs', itsmeConfigs));
	}


	canContinue = () => {
		return !!this.props.companyAdminSettings?.companyName &&
			((!this.props.companyAdminSettings?.urlSubdomainEnabled || this.props.companyAdminSettings?.urlSubdomain?.length > 0)
			&& (!this.props.companyAdminSettings?.urlCustomDomainEnabled || this.props.companyAdminSettings?.urlCustomDomain?.length > 0))
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onUpdate();
		}
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	onUpdate = () => {
		this.props.onUpdateAdminSettings(this.props.companyAdminSettings);
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companyBusy: state.company.busy,
			companyAdminSettings: state.company.adminSettings,
			applicationAdmin: state.session.info.applicationAdmin
		}
	},
	dispatch => {
		return {
			onCompanyFetchAdminSettings: (companyId) => {
				dispatch({
					type: 'COMPANY_FETCH_ADMIN_SETTINGS',
					companyId
				});
			},
			onCompanyChangeAdminSetting: (key, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_ADMIN_SETTING',
					key,
					value
				});
			}
		}
	}
)(CompanyAdminSettingsDialog));
