import React, {Component, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	MenuItem,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import HelpIcon from '@mui/icons-material/Help';
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {COMPANY_SETTING_VALUE, FILE_FORMATS, SIGNATURE_TYPES} from "../common/Constants";
import EditorFieldStyleComponent from "../editor/EditorFieldStyleComponent";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import CompanyFormFieldValidatorDialog from "./CompanyFormFieldValidatorDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {v4 as uuidv4} from "uuid";
import {GridActionsCellItem, GridCellModes} from "@mui/x-data-grid-pro";

const VISUAL_SIGNATURE_FONTS = ['INDIEFLOWER', 'GREATVIBES', 'QWIGLEY', 'ARTYSIGNATURE'];

const DEFAULT_STATE = {
	formFieldValidatorDialogOpen: false,
	formFieldCurrentValidatorId: null,
	signatureTypeSettingDialogOpen: false,
}

const DEFAULT_QUILL_ITSME_CONFIG_ID = -1;


const CompanySignatureTypeConfigsDialog = ({signatureType, configurations, itsmeConfigs, allowFixedCapacityFromUserAttributes, open, onClose, onChange}) => {

	const {t} = useTranslation();

	const [localConfigurations,setLocalConfigurations] = useState(configurations || []);
	const [activeConfiguration,setActiveConfiguration] = useState(null);
	const [addConfigurationDialogOpen,setAddConfigurationDialogOpen] = useState(false);

	useEffect(() => {
		setLocalConfigurations(configurations);
	}, [open]);

	const onConfigurationChange = useCallback((changedConfiguration) => {
		const updatedConfigurations = localConfigurations
			.map(config => config.id === changedConfiguration.id ? {
				...config,
				...changedConfiguration,
			} : config);
		setLocalConfigurations(updatedConfigurations);
		setActiveConfiguration(null);
	}, [localConfigurations]);

	const onConfigurationAdd = useCallback((newConfiguration) => {
		const updatedConfigurations = [...localConfigurations, newConfiguration];
		setLocalConfigurations(updatedConfigurations);
		setAddConfigurationDialogOpen(false);
	}, [localConfigurations]);

	const onConfigurationRemove = useCallback((configurationToRemove) => {
		const updatedConfigurations = localConfigurations.filter(config => config !== configurationToRemove);
		setLocalConfigurations(updatedConfigurations);
	}, [localConfigurations]);

	const dataGridColumns = [
		{
			field: 'name',
			headerName: t('company.generalSettingsSignatureConfigName'),
			editable: false,
			flex: 1
		},
		{
			field: 'settingValue',
			headerName: t('company.generalSettingsSignatureConfigSetting'),
			editable: false,
			flex: 2,
			valueFormatter: (value) => t('company.generalSettingsValue_' + value)
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: t('company.actions'),
			editable: false,
			width: 100,
			renderCell: (cellValues) => (<Fragment>
				<IconButton
					variant="contained"
					color="primary"
					title={t('company.generalSettingsSignatureConfigEdit')}
					onClick={() => setActiveConfiguration(cellValues.row)}
					>
					<EditIcon/>
				</IconButton>
				<IconButton
					variant="contained"
					color="primary"
					title={t('company.generalSettingsSignatureConfigDelete')}
					disabled={cellValues.row.systemType}
					onClick={() => onConfigurationRemove(cellValues.row)}
					>
					<DeleteIcon/>
				</IconButton>
			</Fragment>)
		},
	];

	return <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
		<DialogTitle>{t('company.generalSettingsSignature_' + signatureType)}</DialogTitle>
		<DialogContent>
			{localConfigurations &&
				<Box sx={{mt: 2, ml: 2, mb: 2}}>
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 1}}>
						<Button variant="contained"
								onClick={() => setAddConfigurationDialogOpen(true)}
								startIcon={<AddIcon/>}
								sx={{mr: 1}}
								id="btn-signature-type-config-add"
						>
							{t('company.generalSettingsSignatureConfigAdd')}
						</Button>
					</Box>

					<DataGrid autoHeight
							  disableColumnSelector
							  hideFooter
							  columns={dataGridColumns}

							  disableColumnFilter
							  disableRowSelectionOnClick
							  disableColumnMenu
							  disableColumnSorting

							  rows={localConfigurations}
							  getRowId={config => config.id || config.internalId}
							  density="compact"/>
				</Box>
			}

			{!!activeConfiguration && <CompanySignatureTypeConfigDialog
				signatureType={signatureType}
				configuration={activeConfiguration}
				open={!!activeConfiguration}
				onClose={() => setActiveConfiguration(null)}
				onChange={onConfigurationChange}
				itsmeConfigs={itsmeConfigs}
				allowFixedCapacityFromUserAttributes={allowFixedCapacityFromUserAttributes}
				/>}

			{addConfigurationDialogOpen && <CompanySignatureTypeConfigDialog
				signatureType={signatureType}
				title={t('company.generalSettingsSignatureConfigAdd')}
				open={addConfigurationDialogOpen}
				onClose={() => setAddConfigurationDialogOpen(false)}
				onChange={onConfigurationAdd}
				itsmeConfigs={itsmeConfigs}
				allowFixedCapacityFromUserAttributes={allowFixedCapacityFromUserAttributes}
			/>}

			<DialogActions>
				<Button onClick={onClose} id="btn-settings-cancel">{t('cancel')}</Button>
				<Button variant="contained" onClick={() => onChange(localConfigurations)}
						id="btn-settings-confirm">
					{t('ok')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}

const CompanySignatureTypeConfigDialog = ({configuration, signatureType, itsmeConfigs, allowFixedCapacityFromUserAttributes, open, onClose, onChange, title}) => {

	const DEFAULT_TRANSLATION_MAP = {
		'DUTCH': '',
		'ENGLISH': '',
		'FRENCH': ''
	}

	const MAX_FILE_SIZE = 524288; // 0.5MB


	const {t} = useTranslation();
	const [name,setName] = useState(configuration?.name || '');
	const [settingValue,setSettingValue] = useState(configuration?.settingValue || COMPANY_SETTING_VALUE[1]);
	const [applyToFolder,setApplyToFolder] = useState(configuration?.applyToFolder || false);
	const [descriptions,setDescriptions] = useState(Object.entries(!! configuration ? configuration.descriptions : DEFAULT_TRANSLATION_MAP).sort((a,b) => (a[0]).localeCompare(b[0])).map(([key, value]) => ({language: key, description: value})) || []);
	const [itsmeConfigId,setItsmeConfigId] = useState(!!configuration ? (configuration.itsmeConfigId || DEFAULT_QUILL_ITSME_CONFIG_ID) : (itsmeConfigs?.find(config => config.defaultConfig)?.id || DEFAULT_QUILL_ITSME_CONFIG_ID));
	const [fixedCapacityFromUserAttributes, setFixedCapacityFromUserAttributes] = useState(configuration?.fixedCapacityFromUserAttributes || false);
	const [logoBase64,setLogoBase64] = useState(configuration?.logoBase64 || null);
	const [logoMimeType,setLogoMimeType] = useState(configuration?.logoMimeType || null);
	const [fileSizeWarning,setFileSizeWarning] = useState(false);
	const [cellModesModel, setCellModesModel] = useState({});

	const setLocalName = (value) => {
		if (/^[a-zA-Z0-9_]*$/.test(value)) {
			setName(value);
		}
	}

	const setSetting = (value) => {
		if (!value) {
			return;
		}
		if ('NEVER_AVAILABLE' === value || 'ALWAYS_AVAILABLE' === value) {
			setApplyToFolder(false);
		}
		setSettingValue(value);
	}

	const canContinue = useMemo(() => {
		return !!name && name.length > 0 &&
			!!descriptions.find(entry => entry.language === 'DUTCH').description &&
			!!descriptions.find(entry => entry.language === 'FRENCH').description &&
			!!descriptions.find(entry => entry.language === 'ENGLISH').description;
	}, [name, descriptions])

	const onConfirm = () => {
		const initialConfiguration = !!configuration ? configuration : {
			signatureType,
			internalId: uuidv4(),
			systemType: false
		};

		const confirmedConfiguration = {
			...initialConfiguration,
			signatureType,
			name,
			settingValue,
			applyToFolder,
			descriptions: Object.fromEntries(descriptions.map(obj => [obj.language, obj.description])),
			itsmeConfigId: itsmeConfigId === DEFAULT_QUILL_ITSME_CONFIG_ID ? null : itsmeConfigId,
			fixedCapacityFromUserAttributes,
			logoMimeType,
			logoBase64
		};
		onChange(confirmedConfiguration);
	}

	const onRowChanged = (updatedRow) => {
		const updatedDescriptions = descriptions.map(entry => (entry.language === updatedRow.language) ? {...entry, description: updatedRow.description} : entry);
		setDescriptions(updatedDescriptions);

		return updatedRow;
	}

	const handleEdit = (id) => {
		setCellModesModel({
			...cellModesModel,
			[id]: {...cellModesModel[id], ['description']: {mode: GridCellModes.Edit}},
		});
	};

	const handleSave = (id) => {
		setCellModesModel({
			...cellModesModel,
			[id]: {...cellModesModel[id], ['description']: {mode: GridCellModes.View}},
		});
	};

	const cellMode = useCallback((id) => {
		return cellModesModel[id]?.['description']?.mode || 'view';
	}, [cellModesModel]);

	const clearLogo = useCallback(() => {
		setLogoBase64(null);
		setLogoMimeType(null);
	}, [logoBase64, logoMimeType])

	const onSelectFile = () => {
		const fileEl = document.getElementById("file");
		fileEl.onchange = () => {
			const file = fileEl.files[0];
			if (!!file) {
				const reader = new FileReader();
				reader.onload = () => {
					const result = reader.result;
					const index1 = result.indexOf(';');
					const index2 = result.indexOf(',');

					if (file.size > MAX_FILE_SIZE) {
						setFileSizeWarning(true);
					} else if (index1 > 0 && index2 > index1) {
						const base64Data = result.substring(index2 + 1);
						const mimeType = result.substring(5, index1);
						setLogoBase64(base64Data);
						setLogoMimeType(mimeType);
					}
				}
				reader.readAsDataURL(file);
			}
		};
		fileEl.click();
	}


	const columns = [
		{
			field: 'language',
			headerName: t('company.generalSettingsSignatureConfigLanguage'),
			editable: false,
		},
		{
			field: 'description',
			headerName: t('company.generalSettingsSignatureConfigTranslation'),
			editable: !configuration?.systemType,
			flex: 1
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: t('company.actions'),
			width: 70,
			resizable: false,
			cellClassName: 'actions',
			getActions: (row) => {
				const editMode = cellMode(row.id) === 'edit'
				return [
					<GridActionsCellItem
						variant="contained"
						color="primary"
						icon={editMode ? <SaveIcon/> : <EditIcon/>}
						label={t('edit')}
						onClick={() => {editMode ? handleSave(row.id) : handleEdit(row.id)}}
					/>,
				];
			},
		}
	];

	return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
		<DialogTitle>{title ? t(title) : t('company.generalSettingsSignatureConfigEdit')}</DialogTitle>
		<DialogContent>
			<Stepper activeStep={-1} orientation="vertical">
				<Step active>
					<StepLabel>{t('company.generalSettingsSignatureConfigGeneral')}</StepLabel>
					<StepContent>
						<TextField
							variant="outlined"
							label={t('company.generalSettingsSignatureConfigName')}
							required
							disabled={!!configuration}
							value={name}
							onChange={(e) => setLocalName(e.target.value)}
							autoComplete="off"
							fullWidth
							autoFocus
						/>
					</StepContent>
				</Step>

				<Step active>
					<StepLabel>{t('company.generalSettingsSignatureConfigAvailability')}</StepLabel>
					<StepContent>
						<Box sx={{display: 'flex'}}>
						<ToggleButtonGroup
							color="primary"
							value={settingValue}
							exclusive
							onChange={(e, value) => setSetting(value)}
						>
							{COMPANY_SETTING_VALUE.map(value =>
								<ToggleButton key={value}
											  value={value}>{t('company.generalSettingsValue_' + value)}</ToggleButton>)}
						</ToggleButtonGroup>

							<Tooltip title={t('company.generalSettingsSigningMethodsHelp')}>
								<HelpIcon fontSize="small" sx={{ml: 1}}/>
							</Tooltip>
						</Box>

						<FormControlLabel
							control={<Checkbox checked={applyToFolder}
											   onChange={(e, checked) => setApplyToFolder(checked)}
											   disabled={('NEVER_AVAILABLE' === settingValue || 'ALWAYS_AVAILABLE' === settingValue)}/>}
							label={<Typography
								variant="body2">{t('company.generalSettingsApplyToFolders')}</Typography>}
						/>
					</StepContent>
				</Step>

				<Step active>
					<StepLabel>{t('company.generalSettingsSignatureConfigTranslations')}</StepLabel>
					<StepContent>
						<DataGrid autoHeight
								  disableColumnSelector
								  hideFooter
								  columns={columns}

								  disableColumnFilter
								  disableRowSelectionOnClick
								  disableColumnMenu
								  disableColumnSorting

								  cellModesModel={cellModesModel}
								  onCellEditStop={(params) => {
									  handleSave(params.id);
								  }}

								  columnVisibilityModel={{
									  actions: !configuration?.systemType,
								  }}

								  rows={descriptions}
								  getRowId={row => row.language}
								  processRowUpdate={onRowChanged}
								  density="compact"/>
					</StepContent>
				</Step>

				{!configuration?.systemType && <Step active>
					<StepLabel>{t('company.generalSettingsSignatureConfigLogo')}</StepLabel>
					<StepContent>
						<Typography>{t('company.generalSettingsSignatureConfigLogoDescription')}</Typography>

						{!logoBase64 &&
							<Button color="secondary"
									variant="contained"
									startIcon={<AddIcon/>}
									onClick={onSelectFile}
									id="btn-logo-upload">
								{t('company.generalSettingsSignatureConfigLogoUpload')}
							</Button>
						}

						{!!logoBase64 &&
							<Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
								<img
									style={{
										width: '80px',
										maxHeight: '80px',
										border: '1px solid black',
										borderRadius: 5
									}}
									src={`data:${logoMimeType};base64,${logoBase64}`}
									alt={t('company.generalSettingsSignatureConfigLogo')}
								/>

								<IconButton
									variant="contained"
									color="primary"
									size="large"
									title={t('company.generalSettingsSignatureConfigLogoDelete')}
									onClick={() => clearLogo(null)}>
									<DeleteIcon/>
								</IconButton>
							</Box>
						}
						<input type="file" id="file" accept=".png, .jpeg, .jpg" style={{display: 'none'}}></input>

						{fileSizeWarning && <Alert severity="error" sx={{mt: 1}}>{t('company.generalSettingsSignatureConfigLogoFileLimitExceeded')}</Alert>}
					</StepContent>
				</Step>}

				{signatureType === 'ITSME' &&
					<Step active>
						<StepLabel>{t('company.generalSettingsSignatureConfigItsme')}</StepLabel>
						<StepContent>
							<Box>
								<Select
									value={itsmeConfigId}
									onChange={(e) => setItsmeConfigId(e.target.value)}
									size="small"
									autoWidth
									disabled={!itsmeConfigId}
								>
									{[{
										id: DEFAULT_QUILL_ITSME_CONFIG_ID,
										name: t('company.generalSettingsSignatureConfigItsmeDefaultConfig')
									}]
										.concat(itsmeConfigs)
										.map(config =>
											<MenuItem key={config.id}
													  value={config.id}>{config.name}
											</MenuItem>)}
								</Select>
							</Box>
						</StepContent>
					</Step>}

				{allowFixedCapacityFromUserAttributes && <Step active>
					<StepLabel>{t('company.generalSettingsSignatureConfigMisc')}</StepLabel>
					<StepContent>
						<Box>
							<FormControlLabel
								control={<Checkbox checked={fixedCapacityFromUserAttributes}
												   onChange={(e, checked) => setFixedCapacityFromUserAttributes(checked)}
								/>}
								label={<Typography
									variant="body2">{t('company.generalSettingsFixedCapacityFromUserAttributes')}</Typography>}
							/>
						</Box>
					</StepContent>
				</Step>}

			</Stepper>

			<DialogActions>
				<Button onClick={onClose} id="btn-settings-cancel">{t('cancel')}</Button>
				<Button variant="contained" onClick={onConfirm}
						disabled={!canContinue}
						id="btn-settings-save">
					{t('save')}
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
}


class CompanyGeneralSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = DEFAULT_STATE;
	}

	componentDidMount() {
		this.props.onCompanyFetchGeneralSettings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		if (!this.props.companyGeneralSettings) {
			return <LoadingComponent/>;
		}

		const signingSettings = this.props.companyGeneralSettings?.signingSettings;
		const approvalSettings = this.props.companyGeneralSettings?.approvalSettings;
		const userSettings = this.props.companyGeneralSettings?.userSettings;
		const documentSettings = this.props.companyGeneralSettings?.documentSettings;
		const expirationSettings = this.props.companyGeneralSettings?.expirationSettings;

		// create map based on signatureType for easy access
		const signatureTypeMap = signingSettings?.signatureTypeConfigs.reduce((prev, current) => ({
			...prev,
			[current.signatureType]: [...prev[current.signatureType] || [], {...current}]
		}), {});

		const formFieldDataGridColumns = [
			{
				field: 'id',
			},
			{
				field: 'systemValidator',
			},
			{
				field: 'name',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorName'),
				editable: false,
				width: 200,
			},
			{
				field: 'formFieldType',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorType'),
				editable: false,
				width: 250,
				valueGetter: (value) => this.props.t('company.generalSettingsFormFieldValidatorType_' + value)
			},
			{
				field: 'format',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorFormat'),
				editable: false,
				flex: 1,
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorActions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.generalSettingsFormFieldValidatorEdit')}
						onClick={() => this.onOpenDialogForValidatorEditing(!!cellValues.row.id ? cellValues.row.id : cellValues.row.referenceId)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.generalSettingsFormFieldValidatorDelete')}
						disabled={cellValues.row.systemValidator}
						onClick={() => this.onRemoveFormFieldValidator(cellValues.row)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];


		return <Box sx={{mt: 2}}>
			<Typography
				variant="h6">{this.props.t('company.generalSettingsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsUsers')}</Typography>
				<Box sx={{display: 'flex', flexDirection: 'column', ml: 2, mt: 1}}>
					<Box><FormControlLabel
						control={<Checkbox checked={userSettings.oidcLoginOnly}
										   onChange={this.onChangeOidcLoginOnly}/>}
						label={this.props.t('company.generalSettingsApplicationOidcOnlyLogin')}
						disabled={this.props.companyBusy}
					/>
					</Box>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={userSettings.guestUserAllowedToBecomeRegistered}
											   onChange={this.onChangeGuestUserAllowedToBecomeRegistered}/>}
							label={this.props.t('company.generalSettingsUsersAllowGuestToBecomeRegistered')}
							disabled={this.props.companyBusy}
						/>
					</Box>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={userSettings.mfaRequiredForAdmins}
											   onChange={this.onChangeMfaRequiredForAdmins}/>}
							label={this.props.t('company.generalSettingsUsersMfaRequiredForAdmins')}
							disabled={this.props.companyBusy || !this.props.sessionInfo.userMfaCompliant}
						/>
						{!this.props.sessionInfo.userMfaCompliant &&
							<Alert severity="warning">{this.props.t('company.generalSettingsUsersMfaRequiredForAdminsWarning')}</Alert>}
					</Box>
				</Box>
			</Box>

			<Typography
				variant="h6" sx={{mt: 2}}>{this.props.t('company.generalSettingsSigningSettings')}</Typography>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsSigningMethods')}
				</Typography>
				<Box sx={{ml: 2, mt: 1, display: 'flex', gap: 2, flexDirection: 'column'}}>
					{Object.keys(SIGNATURE_TYPES)
						.filter(signatureType => !!signatureTypeMap[signatureType])
						.map(signatureType => <Box key={signatureType} sx={{display: 'flex', gap: 0.5}}>
							<Box sx={{display: 'flex', alignItems: 'center', minWidth: '250px'}}>{this.props.t('company.generalSettingsSignature_' + signatureType)}</Box>
							<Button
								variant="contained"
								onClick={() => this.onOpenSignatureTypeSettingsDialog(signatureType, signatureTypeMap[signatureType])}
								startIcon={<EditIcon/>}
								id="btn-company-signaturetype-edit"
								disabled={this.props.companyBusy}
							>
								{this.props.t('company.generalSettingsSignatureEdit')}
							</Button>
						</Box>)}
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsSigningPreferences')}
					<Tooltip title={this.props.t('company.generalSettingsSigningPreferencesHelp')}>
						<HelpIcon fontSize="small" sx={{ml: 1}}/>
					</Tooltip>
				</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Box>{this.props.t('company.generalSettingsSigningReason')}</Box>
					<ToggleButtonGroup
						color="primary"
						value={signingSettings.signingReasonPreference}
						exclusive
						onChange={this.onChangeSigningReasonValue}
						disabled={this.props.companyBusy}
					>
						{COMPANY_SETTING_VALUE.map(value =>
							<ToggleButton key={value}
										  value={value}>{this.props.t('company.generalSettingsValue_' + value)}</ToggleButton>)}
					</ToggleButtonGroup>

					{(signingSettings.signingReasonPreference !== 'NEVER_AVAILABLE') &&
						<Box>
							<FormControlLabel
								control={<Checkbox checked={signingSettings.signingReasonLegallyRequired}
												   onChange={this.onChangeSigningReasonLegallyRequired}/>}
								label={this.props.t('company.generalSettingsSigningReasonMandatoryLegalNotice')}
								disabled={this.props.companyBusy}
								sx={{mt: 1}}
							/>
						</Box>
					}

					{(signingSettings.signingReasonPreference !== 'NEVER_AVAILABLE') && signingSettings.signingReasonLegallyRequired &&
						<TextField
							variant="outlined"
							fullWidth
							label={this.props.t('company.generalSettingsSigningReasonLegalNotice')}
							value={signingSettings.signingReasonLegalText || ''}
							onChange={this.onChangeSigningReasonLegalText}
							disabled={this.props.companyBusy}/>
					}
				</Box>
				<Box sx={{ml: 2, mt: 2}}>
					<Box>{this.props.t('company.generalSettingsSigningRemark')}</Box>
					<ToggleButtonGroup
						color="primary"
						value={signingSettings.signingCommentPreference}
						exclusive
						onChange={this.onChangeSigningCommentValue}
						disabled={this.props.companyBusy}
					>
						{COMPANY_SETTING_VALUE.map(value =>
							<ToggleButton key={value}
										  value={value}>{this.props.t('company.generalSettingsValue_' + value)}</ToggleButton>)}
					</ToggleButtonGroup>
				</Box>
				<Box sx={{ml: 2, mt: 2}}>
					<Box>{this.props.t('company.generalSettingsSigningVisualSignatureFont')}</Box>
					<ToggleButtonGroup
						color="primary"
						value={signingSettings.visualSignatureFont}
						exclusive
						onChange={this.onChangeVisualSignatureFont}
						disabled={this.props.companyBusy}
					>
						{VISUAL_SIGNATURE_FONTS.map(value =>
							<ToggleButton key={value}
										  value={value}>{this.props.t('company.generalSettingsSigningVisualSignatureFont_' + value)}</ToggleButton>)}
					</ToggleButtonGroup>


					{!!signingSettings.fontPreviewBase64Data[signingSettings.visualSignatureFont] && <Box sx={{mt: 1}}>
						<Typography>{this.props.t('company.generalSettingsSigningVisualSignatureFontPreview')}</Typography>
						<Box>
							<img
								style={{
									border: '1px solid black',
									borderRadius: 5
								}}
								src={'data:image/png;base64,' + signingSettings.fontPreviewBase64Data[signingSettings.visualSignatureFont]}
								alt={this.props.t('company.generalSettingsSigningVisualSignatureFont_' + signingSettings.visualSignatureFont)}
							/>
						</Box>
					</Box>}
				</Box>
				<Box sx={{ml: 2, mt: 1, display: 'flex', flexDirection: 'column'}}>
					<FormControlLabel
						control={<Checkbox
							checked={signingSettings.addSigningMethodToSignature || signingSettings.addFullSigningInfo}
							onChange={this.onChangeAddSigningMethodToSignature}/>}
						label={this.props.t('company.generalSettingsSigningAddSigningMethod')}
						disabled={this.props.companyBusy || signingSettings.addFullSigningInfo}
					/>

					<FormControlLabel
						control={<Checkbox checked={signingSettings.addFullSigningInfo}
										   onChange={this.onChangeAddFullSigningInfo}/>}
						label={this.props.t('company.generalSettingsSigningAddFullSigningInfo')}
						disabled={this.props.companyBusy}
					/>
					<FormControlLabel
						control={<Checkbox checked={signingSettings.addImageToSignature}
										   onChange={this.onChangeAddImageToSignature}/>}
						label={this.props.t('company.generalSettingsSigningAddImage')}
						disabled={this.props.companyBusy}
					/>
				</Box>
				{signingSettings?.signingFieldStyle &&
					<Box sx={{ml: 2, mt: 1}}>
						<Typography sx={{fontWeight: 500, display: 'flex', alignItems: 'center', mb: 1}}>
							{this.props.t('company.generalSettingsDefaultSignatureFieldStyle')}
						</Typography>
						<EditorFieldStyleComponent style={signingSettings.signingFieldStyle}
												   onChangeStyle={this.onChangeStyle}/>
					</Box>
				}
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsDeclinePreferences')}
				</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={signingSettings.declineDocumentEnabled}
										   onChange={this.onChangeSigningDeclineDocumentEnabled}/>}
						label={this.props.t('company.generalSettingsSigningAllowDecline')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Typography
				variant="h6" sx={{mt: 2}}>{this.props.t('company.generalSettingsDocumentSettings')}</Typography>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsDescription')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={documentSettings.documentDescriptionMandatory}
											   onChange={this.onChangeDocumentDescriptionMandatory}/>}
							label={this.props.t('company.generalSettingsDescriptionMandatory')}
							disabled={this.props.companyBusy}
						/>
					</Box>
					<TextField
						variant="outlined"
						label={this.props.t('company.generalSettingsDescriptionText')}
						value={documentSettings.defaultDocumentDescription}
						onChange={this.onChangeDefaultDocumentDescription}
						disabled={this.props.companyBusy}
						autoComplete="off"
						fullWidth
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsDocumentCollectionMarkAsReadMandatory')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.markAllDocumentsInCollectionAsReadMandatory}
										   onChange={this.onChangeDocumentCollectionMarkAsReadMandatory}/>}
						label={this.props.t('company.generalSettingsDocumentCollectionMarkAsReadMandatoryInfo')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsFormFieldValidatorSettings')}</Typography>
				<Box sx={{ml: 2}}>
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 1}}>
						<Button variant="contained"
								onClick={this.onOpenDialogForValidatorAdding}
								startIcon={<AddIcon/>}
								sx={{mr: 1}}
								disabled={this.props.companyBusy}
								id="btn-formfieldvalidators-add"
						>
							{this.props.t('company.generalSettingsFormFieldValidatorAdd')}
						</Button>
					</Box>
					<DataGrid
						disableColumnFilter
						disableRowSelectionOnClick
						disableColumnSelector
						pagination

						initialState={{
							sorting: {
								sortModel: [{field: 'systemValidator', sort: 'desc'}, {field: 'id', sort: 'asc'}],
							},
							columns: {
								columnVisibilityModel: {
									id: false,
									systemValidator: false
								}
							},
							pagination: {
								paginationModel: {pageSize: 10, page: 0},
							},
						}}

						loading={this.props.companyBusy}

						getRowId={(row) => !!row.id ? row.id : row.referenceId}
						columns={formFieldDataGridColumns}
						rows={documentSettings.formFieldValidators || []}
						pageSizeOptions={[10]}
						density="compact"
						autoHeight
					/>

					<CompanyFormFieldValidatorDialog
						formFieldValidator={documentSettings.formFieldValidators?.find(validator => (validator.id === this.state.formFieldCurrentValidatorId || validator.referenceId === this.state.formFieldCurrentValidatorId))}
						open={this.state.formFieldValidatorDialogOpen}
						onClose={this.onCloseValidatorDialog}
						onConfirm={this.onEditFormFieldValidator}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsApprovalPreferences')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={approvalSettings.showSignerInfo}
										   onChange={this.onChangeShowSignerInfo}/>}
						label={this.props.t('company.generalSettingsApprovalShowSignerInfo')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsExpiration')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox
							checked={expirationSettings.pendingApprovalAndSignaturesExpirationEnabled || false}
							onChange={this.onChangePendingApprovalAndSignaturesExpirationEnabled}/>}
						label={this.props.t('company.generalSettingsExpirationEnable')}
						disabled={this.props.companyBusy}
					/>
					{expirationSettings.pendingApprovalAndSignaturesExpirationEnabled &&
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<TextField
								variant="outlined"
								size="small"
								value={expirationSettings.pendingApprovalAndSignaturesExpirationInDays}
								onChange={this.onChangePendingApprovalAndSignaturesExpirationInDays}
								sx={{mr: 1, width: 100}}
								disabled={this.props.companyBusy}
							/>
							<span>{this.props.t('company.generalSettingsExpirationDays')}</span>
						</Box>}
					{expirationSettings.pendingApprovalAndSignaturesExpirationEnabled &&
						<>
							<FormControlLabel
								control={<Checkbox
									checked={expirationSettings.allowFoldersToDefineExpirations || false}
									onChange={this.onChangeAllowFoldersToDefineExpirations}/>}
								label={this.props.t('company.generalSettingsAllowFoldersToDefineExpirations')}
								disabled={this.props.companyBusy}
							/>
							<br/>
							<FormControlLabel
								control={<Checkbox
									checked={expirationSettings.allowDocumentsToDefineExpirations || false}
									onChange={this.onChangeAllowDocumentsToDefineExpirations}/>}
								label={this.props.t('company.generalSettingsAllowDocumentsToDefineExpirations')}
								disabled={this.props.companyBusy}
							/>
						</>}
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsDocumentCleanup')}
					<Tooltip title={this.props.t('company.generalSettingsDocumentCleanupHelp')}>
						<HelpIcon fontSize="small" sx={{ml: 1}}/>
					</Tooltip>
				</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<TextField
							variant="outlined"
							size="small"
							value={documentSettings.cleanupOfProcessedDocumentsInDays}
							onChange={this.onChangeCleanupOfProcessedDocumentsInDays}
							sx={{mr: 1, width: 100}}
							disabled={this.props.companyBusy}
						/>
						<span>{this.props.t('company.generalSettingsDocumentCleanupDaysProcessed')}</span>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<TextField
							variant="outlined"
							size="small"
							value={documentSettings.cleanupOfPendingDocumentsInDays}
							onChange={this.onChangeCleanupOfPendingDocumentsInDays}
							sx={{mr: 1, width: 100}}
							disabled={this.props.companyBusy}
						/>
						<span>{this.props.t('company.generalSettingsDocumentCleanupDaysPending')}</span>
					</Box>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.differentCleanupSettingsForFoldersAllowed}
										   onChange={this.onChangeDifferentCleanupSettingsForFoldersAllowed}/>}
						label={this.props.t('company.generalSettingsDocumentCleanupAllowFolderSettings')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsForwarding')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.documentForwardingAllowed}
										   onChange={this.onChangeDocumentForwardingAllowed}/>}
						label={this.props.t('company.generalSettingsDocumentForwardingAllowed')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>
			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsDownloading')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.signerNeedsSignatureToDownload}
										   onChange={this.onChangeSignerNeedsSignatureToDownload}/>}
						label={this.props.t('company.generalSettingsSignerNeedsSignatureToDownload')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsFileTypes')}
				</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					{FILE_FORMATS
						.map(fileType => (documentSettings?.fileTypeSettings || []).find(setting => setting.fileType === fileType))
						.filter(setting => !!setting)
						.map(setting => <Box key={setting.fileType}>
							<Box>{this.props.t('company.generalSettingsAllowFileType')
								.replace('$FILETYPE', this.props.t('company.generalSettingsFileType_' + setting.fileType))}
								{setting.fileType === 'SPREADSHEET' && this.props.t('company.generalSettingsFileType_SPREADSHEET_suffix')}
							</Box>
							<ToggleButtonGroup
								color="primary"
								value={setting.value}
								exclusive
								onChange={(e, value) => this.onChangeFileTypeValue(setting.fileType, value)}
								disabled={this.props.companyBusy}
							>
								{COMPANY_SETTING_VALUE.map(value =>
									<ToggleButton key={setting.fileType + value}
												  value={value}>{this.props.t('company.generalSettingsValue_' + value)}</ToggleButton>)}
							</ToggleButtonGroup>
							{('DEFAULT_AVAILABLE' === setting.value || 'DEFAULT_UNAVAILABLE' === setting.value) &&
								<FormControlLabel
									control={<Checkbox checked={setting.applyToFolder || false}
													   onChange={(e, value) => this.onChangeFileTypeApplyToFolder(setting.fileType, value)}/>}
									label={<Typography
										variant="body2">{this.props.t('company.generalSettingsApplyToFolders')}</Typography>}
									disabled={this.props.companyBusy}
									sx={{ml: 1}}
								/>}
						</Box>)}
				</Box>
			</Box>

			<CompanySignatureTypeConfigsDialog
				open={this.state.signatureTypeSettingDialogOpen}
				signatureType={this.state.activeSignatureType}
				configurations={this.state.activeSignatureTypeConfig}
				itsmeConfigs={signingSettings.itsmeConfigs}
				onClose={this.onCloseSignatureTypeSettingsDialog}
				onChange={(signatureTypeSettings) => this.onChangeSignatureTypeSettings(this.state.activeSignatureType, signatureTypeSettings)}
				allowFixedCapacityFromUserAttributes={signingSettings.allowFixedCapacityFromUserAttributes}
			/>

			<Box sx={{mt: 2}}>
				<Button variant="contained" onClick={this.onSave} id="btn-settings-save"
						startIcon={<SaveIcon/>}>{this.props.t('save')}</Button>
			</Box>
		</Box>
	}

	onOpenSignatureTypeSettingsDialog = (type, config) => {
		this.setState({
			signatureTypeSettingDialogOpen: true,
			activeSignatureType: type,
			activeSignatureTypeConfig: config
		});
	}

	onCloseSignatureTypeSettingsDialog = () => {
		this.setState({
			signatureTypeSettingDialogOpen: false,
			activeSignatureType: null,
			activeSignatureTypeConfig: null
		});
	}

	onChangeSignatureTypeSettings = (signatureType, changedSignatureTypeConfigs) => {
		const currentSettings = this.props.companyGeneralSettings.signingSettings.signatureTypeConfigs;
		const signatureTypeConfigs = [...currentSettings.filter(settings => settings.signatureType !== signatureType), ...changedSignatureTypeConfigs];

		this.setState({signatureTypeSettingDialogOpen: false}, () => this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signatureTypeConfigs', signatureTypeConfigs));
	}

	onChangeSigningReasonValue = (e, value) => {
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingReasonPreference', value);
		}
	}

	onChangeSigningReasonLegallyRequired = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingReasonLegallyRequired', value);
	}

	onChangeSigningReasonLegalText = (e) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingReasonLegalText', e.target.value);
	}

	onChangeSigningDeclineDocumentEnabled = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'declineDocumentEnabled', value);
	}

	onChangeSigningCommentValue = (e, value) => {
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingCommentPreference', value);
		}
	}

	onChangeVisualSignatureFont = (e, value) => {
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'visualSignatureFont', value);
		}
	}

	onChangeOidcLoginOnly = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('userSettings', 'oidcLoginOnly', value);
	}

	onChangeGuestUserAllowedToBecomeRegistered = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('userSettings', 'guestUserAllowedToBecomeRegistered', value);
	}

	onChangeMfaRequiredForAdmins = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('userSettings', 'mfaRequiredForAdmins', value);
	}

	onChangePendingApprovalAndSignaturesExpirationEnabled = (e, value) => {
		if (!value) {
			this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'pendingApprovalAndSignaturesExpirationInDays', 0);
			this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'allowFoldersToDefineExpirations', false);
		}
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'pendingApprovalAndSignaturesExpirationEnabled', value);
	}

	onChangePendingApprovalAndSignaturesExpirationInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'pendingApprovalAndSignaturesExpirationInDays', !value ? 0 : value);
	}

	onChangeAllowFoldersToDefineExpirations = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'allowFoldersToDefineExpirations', value);
	}

	onChangeAllowDocumentsToDefineExpirations = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'allowDocumentsToDefineExpirations', value);
	}

	onChangeCleanupOfProcessedDocumentsInDays = (e) => {
		let value = parseInt(e.target.value);
		if (!!value && value > this.props.companyGeneralSettings.documentSettings.documentCleanupThresholdLimitInDays) {
			value = this.props.companyGeneralSettings.documentSettings.documentCleanupThresholdLimitInDays;
		}

		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'cleanupOfProcessedDocumentsInDays', !value ? 0 : value);
	}

	onChangeCleanupOfPendingDocumentsInDays = (e) => {
		let value = parseInt(e.target.value);
		if (!!value && value > this.props.companyGeneralSettings.documentSettings.documentCleanupThresholdLimitInDays) {
			value = this.props.companyGeneralSettings.documentSettings.documentCleanupThresholdLimitInDays;
		}

		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'cleanupOfPendingDocumentsInDays', !value ? 0 : value);
	}

	onChangeDifferentCleanupSettingsForFoldersAllowed = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'differentCleanupSettingsForFoldersAllowed', value);
	}

	onChangeDocumentForwardingAllowed = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'documentForwardingAllowed', value);
	}

	onChangeDocumentDescriptionMandatory = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'documentDescriptionMandatory', value);
	}

	onChangeDefaultDocumentDescription = (e) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'defaultDocumentDescription', e.target.value);
	}

	onChangeDocumentCollectionMarkAsReadMandatory = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'markAllDocumentsInCollectionAsReadMandatory', value);
	}

	onChangeAddSigningMethodToSignature = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'addSigningMethodToSignature', value);
	}

	onChangeAddFullSigningInfo = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'addFullSigningInfo', value);
	}

	onChangeAddImageToSignature = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'addImageToSignature', value);
	}

	onChangeShowSignerInfo = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('approvalSettings', 'showSignerInfo', value);
	}

	onChangeStyle = (value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingFieldStyle', value);
	}

	onOpenDialogForValidatorAdding = () => {
		this.setState({formFieldValidatorDialogOpen: true, formFieldCurrentValidatorId: null});
	}

	onOpenDialogForValidatorEditing = (formFieldCurrentValidatorId) => {
		this.setState({formFieldCurrentValidatorId, formFieldValidatorDialogOpen: true});
	}

	onCloseValidatorDialog = () => {
		this.setState({formFieldValidatorDialogOpen: false});
	}

	onEditFormFieldValidator = (formFieldValidator) => {
		let validators = [...this.props.companyGeneralSettings.documentSettings.formFieldValidators];

		if (!!this.state.formFieldCurrentValidatorId) {
			validators = validators.map(tmpValidator =>
				(tmpValidator.id === this.state.formFieldCurrentValidatorId || tmpValidator.referenceId === this.state.formFieldCurrentValidatorId)
					? formFieldValidator
					: tmpValidator);
		} else {
			validators.push(formFieldValidator);
		}
		this.setState({
			formFieldValidatorDialogOpen: false,
			formFieldCurrentValidatorId: null
		}, () => this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'formFieldValidators', validators));
	}

	onRemoveFormFieldValidator = (formFieldValidator) => {
		const validatorId = !!formFieldValidator.id ? formFieldValidator.id : formFieldValidator.referenceId;
		let validators = [...this.props.companyGeneralSettings.documentSettings.formFieldValidators];
		const index = validators.findIndex(tmpValidator => (tmpValidator.id === validatorId || tmpValidator.referenceId === validatorId));
		if (index >= 0) {
			validators.splice(index, 1);
			this.setState({
				formFieldValidatorDialogOpen: false,
				formFieldCurrentValidatorId: null
			}, () => this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'formFieldValidators', validators));
		}
	}

	onChangeSignerNeedsSignatureToDownload = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'signerNeedsSignatureToDownload', value);
	}

	onChangeFileTypeValue = (fileType, value) => {
		const fileTypeSettings = this.props.companyGeneralSettings.documentSettings.fileTypeSettings.map(setting => {
			if (setting.fileType !== fileType) {
				return setting;
			} else {
				return {
					...setting,
					value
				}
			}
		});
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'fileTypeSettings', fileTypeSettings);
		}
	}

	onChangeFileTypeApplyToFolder = (fileType, applyToFolder) => {
		const fileTypeSettings = this.props.companyGeneralSettings.documentSettings.fileTypeSettings.map(setting => {
			if (setting.fileType !== fileType) {
				return setting;
			} else {
				return {
					...setting,
					applyToFolder
				}
			}
		});
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'fileTypeSettings', fileTypeSettings);
	}

	onSave = () => {
		const settings = {
			...this.props.companyGeneralSettings,
			signingSettings: {
				...this.props.companyGeneralSettings.signingSettings,
				defaultItsmeConfigId: this.props.companyGeneralSettings.signingSettings.defaultItsmeConfigId === DEFAULT_QUILL_ITSME_CONFIG_ID ? null : this.props.companyGeneralSettings.signingSettings.defaultItsmeConfigId
			}
		};
		this.props.onCompanyUpdateGeneralSettings(settings);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyGeneralSettings: state.company.generalSettings,
		}
	},
	dispatch => {
		return {
			onCompanyFetchGeneralSettings: () => {
				dispatch({
					type: 'COMPANY_FETCH_GENERAL_SETTINGS'
				});
			},
			onCompanyUpdateGeneralSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_GENERAL_SETTINGS',
					settings
				});
			},
			onCompanyChangeGeneralSettingsItem: (key, subkey, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_GENERAL_SETTINGS_ITEM',
					key,
					subkey,
					value
				});
			},
		}
	}
)(CompanyGeneralSettingsComponent));
