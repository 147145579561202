export default {
	lang: 'Nederlands',
	search: 'Zoeken',
	previous: 'Vorige',
	next: 'Volgende',
	cancel: 'Annuleren',
	select: 'Selecteren',
	confirm: 'Bevestigen',
	close: 'Sluiten',
	ok: 'OK',
	back: 'Terug',
	save: 'Opslaan',
	page: 'Pagina',
	attention: 'Opgelet!',
	columns: 'Kolommen',
	export: 'Exporteren',
	filter: 'Filteren',
	clear: 'Wissen',
	new: 'Nieuw',
	edit: 'Wijzigen',
	delete: 'Verwijderen',
	resetColumnsWidth: 'Breedte van kolommen opnieuw instellen',

	session: {
		admin: 'Beheerder van',
		user: 'Gebruiker van',
		loggedInAs: 'Aangemeld als',
		becomeRegisteredUser: 'Geregistreerde gebruiker worden',
		becomeRegisteredUserDetails: 'Wilt u uw toegang als gast omzetten naar een geregistreerde account? Gasttoegang via links, die u eerder werden gemaild, zal niet langer werken. U kan de documenten opnieuw raadplegen na het registreren en inloggen. Na confirmatie ontvangt u een uitnodigingse-mail en wordt u doorverwezen naar de login pagina. Gelieve de instructies in de uitnodigingse-mail te volgen om registratie te voltooien.',
		welcome: 'Welkom',
		login: 'Inloggen',
		email: 'E-mail',
		password: 'Wachtwoord',
		alternativeLogins: 'OF',
		loginMethod_EID: 'Belgische eID',
		resetPassword: 'Verander uw wachtwoord',
		resetPasswordUpdateSuccess: 'Bijwerken wachtwoord is gelukt, klik op \'Volgende\' om naar het inlogscherm te gaan.',
		forgotPassword: 'Wachtwoord vergeten?',
		forgotPasswordSuccess: 'Als $email aan een geregistreerde gebruiker toebehoort, zal een e-mail met verdere instructies verstuurd worden naar dit adres.',
		forgotPasswordSuccessContinue: 'Klik op \'Volgende\' om door te gaan naar de login pagina.',
		pendingChanges: 'Er zijn niet opgeslagen wijzigingen. Weet je zeker dat je deze pagina wilt verlaten?',
		pendingChangesLeave: 'Verlaat pagina, negeer wijzigingen',
		pendingChangesSaveAndLeave: 'Sla op en verlaat pagina',
		versionOutOfSync: 'Een nieuwe update van de applicatie is nu beschikbaar. Klik op \'Vernieuwen\' om door te gaan met de laatste versie.',
		versionOutOfSyncRefresh: 'Vernieuwen',
		mfaActionRequired: 'Uw bedrijf heeft tweefactorauthenticatie afgedwongen om administratieve acties uit te voeren. Dit kan ingesteld worden in de profielinstellingen.',
		mfaConfigure: 'Ga naar de instellingen'
	},

	language: {
		en: 'Engels',
		nl: 'Nederlands',
		fr: 'Frans',
	},

	locale: {
		dateFormat: 'dd/mm/jjjj',
		datetimeFormat: 'dd/mm/jjjj uu:mm'
	},

	notFound: {
		message: 'De gevraagde pagina kon niet gevonden worden'
	},

	changes: {
		saved: 'Wijzigingen opgeslagen',
		required: 'Dit veld is verplicht'
	},

	application: {
		tab_COMPANY_OVERVIEW: 'Bedrijven',
		tab_OIDC_SETTINGS: 'OIDC Configuratie',
		tab_TESTS: 'Testen',
		tab_LOGGING: 'Logging',

		testsMail: 'Stuur een test voor elke mail sjabloon in onderstaande taal',

		loggingHeader: 'Configureer logging',
		loggingLogger: 'Logger',
		loggingLevel: 'Niveau',
		loggingDelete: 'Verwijder',
		loggingAdd: 'Toevoegen',
	},

	info: {
		manualHeader: 'Handleiding',
		manualContent: 'De laatste versie van de handleiding vindt u $link;https://quill.dioss.com/documentation;hier$',
		termsHeader: 'Algemene voorwaarden',
		termsContent: 'Informatie omtrent dit onderwerp vindt u $link;https://smartsolutions.dioss.com/nl/products/quill/general-terms-and-conditions/;hier$.',
		faqHeader: 'FAQ - Veelgestelde vragen',
		faqContent: 'Informatie omtrent dit onderwerp vindt u $link;https://smartsolutions.dioss.com/en/products/quill/FAQ-quill/;hier$.',
		supportHeader: 'Support',
		supportContent: 'Voor assistentie, contacteer $mail$',
		gdprHeader: 'GDPR - Algemene Verordening Gegevensbescherming | Privacybeleid',
		gdprContent: 'Informatie omtrent dit onderwerp vindt u $link;https://smartsolutions.dioss.com/nl/products/quill/privacy-statement/;hier$.',
		isoEidasHeader: 'ISO-gecertificeerd | eIDAS-compatibel',
		isoHeader: 'Is Dioss Smart Solutions ISO27001-compatibel and gecertificeerd?',
		isoContent: 'ISO 27001 is een specificatie voor een informatiebeveiligingsbeheersysteem, het is een raamwerk van beleidslijnen en procedures dat juridische, fysieke en technische controles omvat die betrokken zijn bij onze processen voor informatierisicobeheer. We gebruiken het als een systeem om ervoor te zorgen dat we op een georganiseerde en veilige manier zaken doen en continu onze aanpak en ons beveiligingsniveau verbeteren. Dioss Smart Solutions is ISO27001-compatibel en gecertificeerd. We worden regelmatig gecontroleerd door BSI, een geaccrediteerde certificeringsinstantie.',
		eidasHeader: 'Gebruikt het Quill platform eIDAS-conforme handtekeningen?',
		eidasContent: 'Ja, dit platform is conform de europese eIDAS-verordening.',
		platformHeader: 'Platform versie',
		releaseNotesHeader: 'Release notes',
		releaseNotesContent: 'Informatie omtrent dit onderwerp vindt u $link;https://smartsolutions.dioss.com/en/products/quill/release-notes/;hier$.',

		tab_GENERAL: 'Algemeen',
		tab_FAQ: 'FAQ',
		tab_VERSION: 'Versie'
	},

	tac: {
		accept: 'Aanvaard',
		agree: 'Ik heb volgende gelezen, begrepen en ga ermee akkoord:',
		general: '$link;https://smartsolutions.dioss.com/nl/products/quill/general-terms-and-conditions/;De algemene voorwaarden$',
		privacy: '$link;https://smartsolutions.dioss.com/nl/products/quill/privacy-statement/;De privacyverklaring$',
		title: 'Neem kennis van Quill\'s voorwaarden en beleid',
		subtitle: 'Welkom bij Quill, de e-signing oplossing van Dioss Smart Solutions. Om deze applicatie te gebruiken, dient u volgende documenten te lezen en akkoord te gaan.',
	},

	cookie: {
		message: 'Wij gebruiken cookies en vergelijkbare technologieën om de beste ervaring op onze website te bieden. Raadpleeg ons $link;https://smartsolutions.dioss.com/nl/products/quill/quill-cookie-policy/;Cookiebeleid$$ voor meer informatie.',
		accept: 'Aanvaard',
	},

	outOfOffice: {
		warning: 'U bent momenteel ingesteld als out-of-office.',
	},

	company: {
		createName: 'Bedrijfsnaam',
		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Het nieuwe bedrijf zal worden toegevoegd aan het platform.',

		header: 'Bedrijven',
		create: 'Nieuw bedrijf toevoegen',
		createChild: 'Nieuw subbedrijf toevoegen',
		companyName: 'Bedrijf',
		actions: 'Acties',
		settings: 'Instellingen',
		delete: 'Verwijderen',
		deleteConfirm: 'Bent u zeker dat u dit bedrijf wil verwijderen?',
		deleted: 'Bedrijf is verwijderd',
		child: 'Subbedrijf',
		parentCompany: 'Hoofdbedrijf',
		documentCount: 'Aantal documenten',
		externalStorageSize: 'Grootte external opslag (S3, ...)',
		switch: 'Wissel actief bedrijf',

		adminSettingsHeader: 'Bedrijfsinstellingen',
		adminSettingsInactive: 'Inactief',
		name: 'Naam',

		adminSettingsApiHeader: 'API instellingen',
		adminSettingsApiAccess: 'API toegang',
		adminSettingsSignatureTypes: 'Soorten handtekening',
		adminSettingsMisc: 'Diverse instellingen',
		adminSettingsPdfRecreatedShown: 'Melding weergeven als de structuur van PDF werd gecorrigeerd vanwege incompatibiliteitsproblemen',
		adminSettingsDocuments: 'Document instellingen',
		adminSettingsDocumentEntryDeletionThreshold: 'dag(en) tot permanente verwijdering van documenten (leeg voor standaard 730 dagen)',
		adminSettingsEmail: 'E-mail instellingen',
		adminSettingsMailboxEnabled: 'Dit bedrijf kan mailboxen aanmaken per map',
		adminSettingsWhitelistedEmailDomains: 'Toegelaten e-mail domeinen',
		adminSettingsWhitelistedEmailDomainsHelperText: 'Meerdere domeinen kunnen gescheiden worden door , of ;',
		adminSettingsWhitelistedFrameDomainsDescription: 'Domeinen van waaruit de applicatie in een frame getoond mag worden',
		adminSettingsWhitelistedFrameDomains: 'Toegelaten frame domeinen',
		adminSettingsWhitelistedFrameDomainsHelperText: 'Meerdere domeinen kunnen gescheiden worden door , of ;',
		adminSettingsCanManageChildCompanies: 'Dit bedrijf kan subbedrijven beheren',
		adminSettingsCanRequestSensitiveSignerData: 'Dit bedrijf kan het nationaal identificatienummer gebruiken',
		adminSettingsUseEidMiddlewarePinInput: 'Gebruik Dioss eID Middleware voor eID pin ingave',
		adminSettingsUseClientPdfRendering: 'Gebruik browsergebaseerde PDF rendering',
		adminSettingsItsmePartnerCode: 'Partnercode',
		adminSettingsItsmeServiceCode: 'Servicecode',
		adminSettingsAllowFixedCapacityFromUserAttributes: 'Dit bedrijf kan gefixeerde hoedanigheid op basis van gebruikersattributen gebruiken',
		adminSettingsFinish: 'Alles ingevuld?',
		adminSettingsFinishDescription: 'Druk op opslaan om de wijzigingen te behouden',

		adminSettingsItsmeConfigCreate: "Configuratie aanmaken",
		adminSettingsItsmeConfigDefault: "Standaard",
		adminSettingsItsmeConfigPartnerCode: "Partnercode",
		adminSettingsItsmeConfigHeavyUserEnabled: "Heavy user",
		adminSettingsItsmeConfigNrnEnabled: "Nationaal identificatienummer",
		adminSettingsItsmeConfigDelete: "itsme® configuratie verwijderen",
		adminSettingsItsmeConfigEdit: "itsme® configuratie aanpassen",
		adminSettingsItsmeConfig: "itsme® configuratie",
		adminSettingsItsmeConfigName: "Naam",
		adminSettingsItsmeHeader: "itsme® instellingen",

		adminSettingsDomain: 'Domein instellingen',
		adminSettingsDomainNone: 'Geen',
		adminSettingsDomainSubdomain: 'Subdomein',
		adminSettingsDomainCustomDomain: 'Custom domein',
		adminSettingsDomainSubdomainExample: 'Voorbeeld URL:',
		adminSettingsDomainSubdomainValidation: 'Kleine letters, nummers, - en _ zijn toegelaten',
		adminSettingsDomainCustomDomainValidation: 'Kleine letters, nummers, ., - en _ zijn toegelaten',

		adminSettingsSignature_ELECTRONIC_WITH_WITNESS_SEAL: 'Laat elektronische zegel toe als soort handtekening',
		adminSettingsSignature_BELGIAN_EID: 'Laat Belgische eID toe als soort handtekening',
		adminSettingsSignature_ITSME: 'Laat gekwalificeerde itsme® toe als soort handtekening',
		adminSettingsSignature_ITSME_ADVANCED: 'Laat geavanceerde itsme® toe als soort handtekening',
		adminSettingsSignature_SMS_OTP: 'Laat code via SMS toe als soort handtekening',
		adminSettingsSignature_EMAIL_OTP: 'Laat code via e-mail toe als soort handtekening',
		adminSettingsSignature_HANDWRITTEN: 'Laat basis handgeschreven handtekening toe als soort handtekening',

		settingsTab_GENERAL_SETTINGS: 'Algemeen',
		settingsTab_APIKEY_SETTINGS: 'API sleutels',
		settingsTab_SIGNING_CERTIFICATE_SETTINGS: 'Certificaten',
		settingsTab_EMAIL_SETTINGS: 'E-mail',
		settingsTab_NOTIFICATION_SETTINGS: 'Notificaties',
		settingsTab_STYLE_SETTINGS: 'Huisstijl',
		settingsTab_FORMFIELD_SETTINGS: 'Velden',
		settingsTab_OIDC_SETTINGS: 'OIDC configuraties',
		settingsTab_DELEGATES: 'Afgevaardigden',
		settingsTab_CONNECTORS: 'Connectoren',
		settingsTab_WEBHOOK_SETTINGS: 'Webhook configuraties',
		settingsTab_SWITCH: 'Mijn bedrijven',

		generalSettingsValue_ALWAYS_AVAILABLE: 'altijd beschikbaar',
		generalSettingsValue_DEFAULT_AVAILABLE: 'standaard beschikbaar',
		generalSettingsValue_DEFAULT_UNAVAILABLE: 'standaard onbeschikbaar',
		generalSettingsValue_NEVER_AVAILABLE: 'nooit beschikbaar',
		generalSettingsFor: 'Algemene instellingen voor',
		generalSettingsSigningSettings: 'Handteken instellingen',
		generalSettingsDocumentSettings: 'Document instellingen',
		generalSettingsSigningMethods: 'Soorten handtekeningen',
		generalSettingsSigningMethodsHelp: 'Deze instellingen laten u toe om te bepalen welke soorten handtekeningen gekozen kunnen worden door documenteditors.\nDe "altijd beschikbaar" instelling maakt het beschikbaar voor alle documenten en dit kan niet overruled worden op mapniveau. \nDe "nooit beschikbaar" instelling schakelt het uit voor alle documenten en kan niet op mapniveau worden overruled. \nDe "standaard" instellingen bepalen de beschikbaarheid voor nieuwe mappen, maar laten toe dat de mapinstellingen dit overschrijven. \nEen wijziging aan deze instellingen heeft geen invloed op reeds verzonden documenten.',
		generalSettingsSignatureEdit: 'Configuraties',
		generalSettingsSignatureConfigTranslations: 'Vertalingen',
		generalSettingsSignature_ELECTRONIC_WITH_WITNESS_SEAL: 'Elektronische zegel',
		generalSettingsSignature_BELGIAN_EID: 'Belgische eID',
		generalSettingsSignature_ITSME: 'Gekwalificeerde itsme®',
		generalSettingsSignature_ITSME_ADVANCED: 'Geavanceerde itsme®',
		generalSettingsSignature_SMS_OTP: 'Code via SMS',
		generalSettingsSignature_EMAIL_OTP: 'Code via e-mail',
		generalSettingsSignature_HANDWRITTEN: 'Basis handgeschreven handtekening',
		generalSettingsSignatureConfigItsmeDefaultConfig: 'Quill standaard',
		generalSettingsSignatureConfigItsme: 'itsme® configuratie',
		generalSettingsSignatureConfigLogo: 'Logo',
		generalSettingsSignatureConfigLogoDescription: 'Logo zal getoond worden als een 30x30px afbeelding. Maximum afbeeldingsgrootte 0,5MB',
		generalSettingsSignatureConfigLogoUpload: 'Selecteer afbeelding',
		generalSettingsSignatureConfigLogoDelete: 'Verwijder afbeelding',
		generalSettingsSignatureConfigLogoFileLimitExceeded: 'Bestandsgrootte overschreden. Gelieve een bestand kleiner dan 0.5MB te selecteren.',
		generalSettingsSignatureConfigName: 'Naam',
		generalSettingsSignatureConfigSetting: 'Instelling',
		generalSettingsSignatureConfigEdit: 'Configuratie wijzigen',
		generalSettingsSignatureConfigDelete: 'Configuratie verwijderen',
		generalSettingsSignatureConfigAdd: 'Configuratie toevoegen',
		generalSettingsSignatureConfigLanguage: 'Taal',
		generalSettingsSignatureConfigTranslation: 'Vertaling',
		generalSettingsSignatureConfigGeneral: 'Algemeen',
		generalSettingsSignatureConfigAvailability: 'Beschikbaarheid',
		generalSettingsSignatureConfigMisc: 'Diverse instellingen',
		generalSettingsFixedCapacityFromUserAttributes: 'Gefixeerde hoedanigheid gebaseerd op gebruikersattributen',
		generalSettingsApplyToFolders: 'Pas toe op alle mappen',
		generalSettingsSigningPreferences: 'Ondertekeningsvoorkeuren',
		generalSettingsSigningPreferencesHelp: 'Deze instellingen laten u toe om te bepalen of een ondertekenaar een opmerking en/of reden kan toevoegen. \nDe "altijd beschikbaar" instelling maakt het beschikbaar voor alle documenten en dit kan niet worden overruled op mapniveau. \nDe "nooit beschikbaar" instelling schakelt het uit voor alle documenten en kan niet op mapniveau overschreven worden. \nDe "standaard" instellingen bepalen de beschikbaarheid voor nieuwe mappen, maar laten toe dat instellingen voor een map dit overschrijven.',
		generalSettingsDeclinePreferences: 'Afwijzingsinstellingen',
		generalSettingsSigningAllowDecline: 'Sta afwijzen toe',
		generalSettingsSigningReason: 'Toevoegen van een reden bij het ondertekenen toestaan voor alle gebruikers (niet visueel weergegeven)',
		generalSettingsSigningReasonMandatoryLegalNotice: 'Maak dit een verplichte wettelijke mededeling die de gebruiker moet invoeren. Dit verschijnt ook visueel op het document.',
		generalSettingsSigningReasonLegalNotice: 'Wettelijke mededeling',
		generalSettingsSigningRemark: 'Toevoegen van een opmerking bij het ondertekenen toestaan voor alle gebruikers (weergegeven op document)',
		generalSettingsSigningAddSigningMethod: 'Voeg gebruikte ondertekening methode toe aan iedere handtekening',
		generalSettingsSigningAddFullSigningInfo: 'Voeg volledige onderteken info toe',
		generalSettingsSigningAddImage: 'Voeg handgeschreven naam/afbeelding toe aan visuele handtekening',
		generalSettingsSigningVisualSignatureFont: 'Font visuele handtekening en paraaf',
		generalSettingsSigningVisualSignatureFont_INDIEFLOWER: 'Indie Flower',
		generalSettingsSigningVisualSignatureFont_GREATVIBES: 'Great Vibes',
		generalSettingsSigningVisualSignatureFont_QWIGLEY: 'Qwigley',
		generalSettingsSigningVisualSignatureFont_ARTYSIGNATURE: 'Arty Signature',
		generalSettingsSigningVisualSignatureFontPreview: 'Font voorbeeld',
		generalSettingsApprovalPreferences: 'Goedkeuringsvoorkeuren',
		generalSettingsApprovalShowSignerInfo: 'Toon ondertekenaar info tijdens goedkeuringsflow',
		generalSettingsApplicationOidcOnlyLogin: 'Login enkel via OIDC',
		generalSettingsUsers: 'Gebruikersinstellingen',
		generalSettingsUsersAllowGuestToBecomeRegistered: 'Laat gastgebruikers toe zich te registreren.',
		generalSettingsUsersMfaRequiredForAdmins: '2-stapsverificatie vereist voor beheerders',
		generalSettingsUsersMfaRequiredForAdminsWarning: 'U moet eerst 2-stapsverificatie inschakelen in de beveiligingsinstellingen van uw account voordat u deze optie kunt afdwingen.',
		generalSettingsExpiration: 'Automatisch vervallen van goedkeuringen en handtekeningen in afwachting',
		generalSettingsExpirationEnable: "Vervallen inschakelen",
		generalSettingsExpirationDays: "dag(en) voordat onvoltooide goedkeuringen en handtekeningen vervallen",
		generalSettingsAllowFoldersToDefineExpirations: "Toestaan dat mappen verschillende vervallingsinstellingen gebruiken",
		generalSettingsAllowDocumentsToDefineExpirations: "Toestaan dat documenten verschillende vervallingsinstellingen gebruiken",
		generalSettingsDocumentCleanup: 'Automatisch verwijderen van documenten',
		generalSettingsDocumentCleanupHelp: 'Documenten worden automatisch verwijderd nadat een aantal dagen zijn verstreken sinds hun laatste wijziging.\nDe instelling voor voltooide documenten wordt toegepast op documenten zonder openstaande handtekeningen of die gesloten werden.\nDe instelling voor onvoltooide documenten wordt toegepast op documenten in voorbereiding of die wachten op handtekeningen of goedkeuringen.\nU kan toestaan dat mappen andere limieten instellen voor hun documenten.',
		generalSettingsDocumentCleanupDaysProcessed: 'dag(en) nadat een voltooid document wordt verwijderd',
		generalSettingsDocumentCleanupDaysPending: 'dag(en) nadat een inactief onvoltooid document wordt verwijderd',
		generalSettingsDocumentCleanupAllowFolderSettings: 'Toestaan dat mappen verschillende verwijderingsinstellingen gebruiken',
		generalSettingsDescription: 'Documentomschrijving',
		generalSettingsDescriptionMandatory: 'Maak omschrijving verplicht',
		generalSettingsDescriptionText: 'Standaardomschrijving',
		generalSettingsDocumentCollectionMarkAsReadMandatory: 'Dossiers',
		generalSettingsDocumentCollectionMarkAsReadMandatoryInfo: 'Vereisen dat alle documenten als gelezen gemarkeerd worden voor ondertekening mogelijk is',
		generalSettingsForwarding: 'Doorsturen',
		generalSettingsDocumentForwardingAllowed: 'Toestaan dat verzoeken om te ondertekenen doorgestuurd kunnen worden',
		generalSettingsDefaultSignatureFieldStyle: 'Standaard ondertekeningsstijl',
		generalSettingsDownloading: 'Download',
		generalSettingsSignerNeedsSignatureToDownload: 'Een geplaatste handtekening is noodzakelijk om een document te kunnen downloaden door de ondertekenaar',
		generalSettingsFileTypes: 'Toegestane bestand upload types',
		generalSettingsAllowFileType: 'Laat $FILETYPE toe',
		generalSettingsFileType_PDF: 'PDF',
		generalSettingsFileType_DOC: 'documenten (doc, docx)',
		generalSettingsFileType_PRESENTATION: 'presentaties (ppt, pptx)',
		generalSettingsFileType_IMAGE: 'afbeeldingen (png, jpeg, tiff, ...)',
		generalSettingsFileType_SPREADSHEET: 'spreadsheets (xls, xlsx, ...)',
		generalSettingsFileType_SPREADSHEET_suffix: ' bij dossier uploads',

		styleSettingsFor: 'Huisstijl voor',
		styleSettingsPrimary: 'Primair',
		styleSettingsSecondary: 'Secundair',
		styleSettingsMainColor: 'Hoofdkleur',
		styleSettingsContrastColor: 'Contrastkleur',
		styleSettingsLogo: 'Logo',
		styleSettingsLogoChoose: 'Wijzig logo',

		emailSettingsFor: 'E-mail instellingen voor',
		emailSettingsDistributionList: 'Standaardinstellingen voor de distributielijst na ondertekenen',
		emailSettingsDistributionListDescription: 'Document geadresseerden',
		emailSettingsDistributionListEvidenceReportDescription: 'Document en bewijsrapport geadresseerden',
		emailSettingsDistributionListEmails: 'Lijst met e-mailadressen gescheiden door \';\'',
		emailSettingsCommunicationType: 'E-mail communicatie type',
		emailSettingsDescription_DEFAULT_HEADERS: 'Alias mode: gebruik de Quill e-mail server met het standaard applicatie adres als \'from\' adres. Het \'sender\' en \'reply-to\'-adres kan gebruikt worden (zie \'verzender\')',
		emailSettingsDescription_DEFAULT_SPF: 'SPF geautoriseerde mode: gebruik de Quill e-mail server, maar met de verzender of bedrijf e-mailadres als \'from\' adres. Opgelet: hiervoor dient u onderstaande DNS records in te stellen en de SPF validatie succesvol te doorlopen',
		emailSettingsDescription_SENDGRID_CUSTOM: 'Eigen SendGrid: gebruik een eigen SendGrid account en verstuur e-mails via deze configuratie',
		emailSettingsSendGridServerSettings: 'SendGrid instellingen',
		emailSettingsPersonalizeCompany: 'Gebruik e-mailadres van bedrijf als verzender',
		emailSettingsPersonalizeCompanyHeaders: '(als \'sender\' en \'reply-to\' header)',
		emailSettingsPersonalizeUser: 'Gebruik e-mailadres van gerelateerde gebruiker als verzender waar mogelijk',
		emailSettingsPersonalizeUserHeaders: '(als \'sender\' en \'reply-to\' header)',
		emailSettingsSenderSettings: 'Verzender',
		emailSettingsServerApplicability: 'Toepasbaar op',
		emailSettingsServerApplicabilityDescription: 'Bepaal welke server configuratie gebruikt wordt. Indien de configuratie specifieke domeinen gedefinieerd heeft, zal deze gebruikt worden bij het verzenden vanaf deze domeinen. In andere gevallen zal de standaard configuratie gebruikt worden. Één standaard configuratie is vereist.',
		emailSettingsServerSettings: 'Server instellingen',
		emailSettingsServerDelete: 'Verwijder',
		emailSettingsServerEdit: 'Aanpassen / Valideren',
		emailSettingsServerAdd: 'Toevoegen',
		emailSettingsServerConnectionInvalid: 'Eén of meerdere SendGrid connecties zijn ongeldig. Er zullen geen mails verstuurd worden via deze ongeldige configuratie(s). Ongeldige connecties worden automatisch opnieuw gevalideerd op de achtergrond of kunnen manueel gevalideerd worden via de SendGrid configuratie dialoog.',
		emailSettingsServerConnectionValidate: 'Valideer connectie',
		emailSettingsServerConnectionValidate_VALID: 'Connectie geldig',
		emailSettingsServerConnectionValidate_INVALID_API_KEY: 'Connectie ongeldig: API key ongeldig',
		emailSettingsServerConnectionValidate_MISSING_MAIL_SEND_PRIVILEGE: 'Connectie ongeldig: \'Mail Send\' privilege ontbreekt',
		emailSettingsServerValid: 'Gevalideerd',
		emailSettingsServerDefault: 'Standaard',
		emailSettingsServerDomains: 'Domeinen',
		emailSettingsServerDomainsLabel: 'Lijst van domeinen gescheiden door \';\'',
		emailSettingsServerUserName: 'Gebruikersnaam',
		emailSettingsServerPassword: 'Wachtwoord / API key',
		emailSettingsAuthenticatedDomainValidation: 'SPF validatie',
		emailSettingsAuthenticatedDomainConfiguration: 'SPF domein configuratie',
		emailSettingsAuthenticatedDomainDialog: 'SPF DNS records',
		emailSettingsAuthenticatedDomainDomain: 'Domein',
		emailSettingsAuthenticatedDomainRecordType: 'Type',
		emailSettingsAuthenticatedDomainRecordName: 'Naam',
		emailSettingsAuthenticatedDomainRecordAliasFor: 'Alias voor',
		emailSettingsAuthenticatedDomainValidationDialog: 'Validatie',
		emailSettingsAuthenticatedDomainDnsRecordName: 'Naam',
		emailSettingsAuthenticatedDomainDnsRecordValid: 'Geldig',
		emailSettingsAuthenticatedDomainDnsRecordReason: 'Reden',
		emailSettingsAuthenticatedDomainCreate: 'Domein configuratie aanvragen',
		emailSettingsAuthenticatedDomainView: 'Bekijk domein records',
		emailSettingsAuthenticatedDomainValidate: 'Valideer domein records',
		emailSettingsAuthenticatedDomainValidationResultStatus: 'Status',
		emailSettingsAuthenticatedDomainValidationResult_VALID: 'Geldig',
		emailSettingsAuthenticatedDomainValidationResult_INVALID: 'Ongeldig',
		emailSettingsAuthenticatedDomainValidationResult_PENDING: 'Niet gevalideerd',
		emailSettingsAuthenticatedDomainValidationResult_IDLE: 'Niet gestart',
		emailSettingsAuthenticatedDomainValidationResult_UNKNOWN: 'Onbekend',
		emailSettingsAuthenticatedDomainPendingChanges: 'Om door te gaan in SPF geautoriseerde mode moeten de huidige instellingen eerst opgeslagen worden. Wilt u doorgaan? ',
		emailSettingsAuthenticatedDomainNoWhitelistedDomains: 'Geen toegelaten domeinen gedetecteerd om de SPF validatie te kunnen voltooien. Gelieve Quill support te contacteren om de toegelaten domeinen in te stellen.',
		emailSettingsAuthenticatedDomainDmarcRecord: 'Om afleverbaarheid van emails te maximaliseren is het aangeraden om een DMARC record te configureren indien dit nog niet het geval zou zijn. Bijvoorbeeld:',
		emailSettingsAddress: 'E-mailadres bedrijf',
		emailSettingsName: 'Naam',
		emailSettingsSupport: 'Support',
		emailSettingsSupportEmail: 'Support e-mailadres',
		emailSettingsApiKeys: 'API keys',
		emailSettingsApiKeysNew: 'Nieuwe waarde ingeven',
		emailSettingsApiKeySending: 'API key om te verzenden',
		emailSettingsApiKeySendingNotVisible: 'API key om te verzenden is ingesteld maar verborgen voor veiligheidsredenen',
		emailSettingsLayout: 'E-mail customisatie',
		emailSettingsLayoutCustomize: 'Customisatie toepassen',
		emailSettingsHeaderFooter: 'Header - Footer',
		emailSettingsHeaderEdit: 'Header editeren',
		emailSettingsContentEdit: 'Inhoud editeren',
		emailSettingsFooterEdit: 'Footer editeren',
		emailSettingsColorBackground: 'Achtergrondkleur',
		emailSettingsColorBackgroundContent: 'Inhoud achtergrondkleur',
		emailSettingsColorButtonBackground: 'Knop achtergrondkleur',
		emailSettingsColorButtonText: 'Knop tekst kleur',
		emailSettingsColorDefault: 'Standaard',
		emailSettingsColorCompanyMain: 'Huisstijl primaire kleur',
		emailSettingsColorCompanySecondary: 'Huisstijl secundaire kleur',
		emailSettingsColorCustom: 'Aangepaste kleur',

		notificationSettingsFor: 'Notificatie instellingen voor',
		notificationSettingsReminders: 'Herinneringen in afwachting van goedkeuringen en handtekeningen',
		notificationSettingsEnableReminders: 'Herinneringen inschakelen',
		notificationSettingsRemindersDays: 'dag(en) tussen het sturen van herinneringen voor onvoltooide goedkeuringen en handtekeningen',
		notificationSettingsDailyDigest: 'Dagelijkse samenvatting',
		notificationSettingsDailyDigestTime: 'Verzendtijd',
		notificationSettingsMinimizeCollectionInvites: 'Beperk dossier uitnodigingen',
		notificationSettingsMinimizeCollectionInvitesEnable: 'Wacht op andere goedkeuringen/handtekeningen om het aantal dossier uitnodigingen te beperken',
		notificationSettingsMinimizeCollectionInvitesGracePeriod: 'Maximum wachttijd in minuten',
		notificationSettingsMinimizeCollectionInvitesGracePeriodHelp: 'Laat blanco om geen maximum in te stellen',
		notificationSettingsMinimizeCollectionFinishedNotifications: 'Beperk het aantal meldingen van voltooide documenten binnen een dossier',
		notificationSettingsMinimizeCollectionFinishedNotificationsEnable: 'Stuur meldingen van voltooide documenten pas wanneer het hele dossier klaar is',

		generalSettingsFormFieldValidatorSettings: 'Invulbare velden validator',
		generalSettingsFormFieldValidatorTest: 'Test',
		generalSettingsFormFieldValidatorType_TEXT: 'Tekst',
		generalSettingsFormFieldValidatorType_CHECKBOX: 'Checkbox',
		generalSettingsFormFieldValidatorTestInput: 'Input',
		generalSettingsFormFieldValidatorTestInvalid: 'Ongeldige input',
		generalSettingsFormFieldValidatorTestValid: 'Geldige input',
		generalSettingsFormFieldValidatorName: 'Naam',
		generalSettingsFormFieldValidatorType: 'Type',
		generalSettingsFormFieldValidatorFormat: 'Formaat',
		generalSettingsFormFieldValidatorFormatDescription: 'U kunt # gebruiken als aanduiding voor elk cijfer (0-9). Alle andere tekens zijn letterlijke tekens; de invoer van letterlijke tekens is optioneel. Zo beschrijft het BE## #### #### #### formaat een Belgische IBAN',
		generalSettingsFormFieldValidatorActions: 'Acties',
		generalSettingsFormFieldValidatorAdd: 'Toevoegen',
		generalSettingsFormFieldValidatorEdit: 'Aanpassen',
		generalSettingsFormFieldValidatorDelete: 'Verwijder',

		certificateSettingsFor: 'Certificaat instellingen voor',
		certificateSettingsDefaultConfig: 'Quill standaard',
		certificateSettingsConfigs: 'Key vault certificaat configuraties',
		certificateSettingsConfig: 'Key vault certificaat configuratie',
		certificateSettingsConfigHeader: 'Configuratie naam',
		certificateSettingsConfigVaultConnectionHeader: 'Key vault connectie',
		certificateSettingsConfigCertificateHeader: 'Certificaat',
		certificateSettingsConfigName: 'Naam',
		certificateSettingsConfigVaultUrl: 'Vault URL',
		certificateSettingsConfigCertificateName: 'Certificaat naam',
		certificateSettingsConfigClientId: 'Client ID',
		certificateSettingsConfigClientSecret: 'Client secret',
		certificateSettingsConfigTenantId: 'Tenant ID',
		certificateSettingsConfigKeyId: 'Key ID',
		certificateSettingsConfigAdd: 'Toevoegen',
		certificateSettingsConfigEdit: 'Aanpassen',
		certificateSettingsConfigDelete: 'Verwijderen',
		certificateSettingsConfigUsage: 'Gebruikte certificaten',
		certificateSettingsInfo: 'U kan hieronder certificaten beheren die gebruikt worden bij het goedkeuren of ondertekenen wanneer type \'elektronische zegel\', \'geavanceerde itsme®\', \'code via SMS\' of \'code via e-mail\' gekozen worden.',
		certificateSettingsGuestUserCertificate: 'Certificaat bij ondertekening door gastgebruikers.',
		certificateSettingsRegisteredUserCertificate: 'Certificaat bij ondertekening door geregistreerde gebruikers.',

		apiKeySettingsHeaderFor: 'API sleutel instellingen voor',
		apiKeySettings: 'API sleutel instellingen',
		apiKeySettingClientId: 'API client ID',
		apiKeySettingsValue: 'Sleutel',
		apiKeySettingsAlert: 'Houd er rekening mee dat de sleutel niet meer zichtbaar is nadat deze dialoog is gesloten.',
		apiKeySettingsAlmostExpireDay: 'Verloopt na 1 dag',
		apiKeySettingsAlmostExpireDays: 'Verloopt in {{days}} dagen',
		apiKeySettingsCopy: 'Kopieer',
		apiKeySettingsCreate: 'Aanmaken',
		apiKeySettingsContactEmail: 'Contact e-mail',
		apiKeySettingsDescription: 'Omschrijving',
		apiKeySettingsDuration: 'Geldigheidsduur',
		apiKeySettingsDurationDuring: 'gedurende een periode van',
		apiKeySettingsDuration_DURATION_DAY: 'dag',
		apiKeySettingsDuration_DURATION_DAYS: 'dagen',
		apiKeySettingsDuration_DURATION_MONTH: 'maand',
		apiKeySettingsDuration_DURATION_MONTHS: 'maanden',
		apiKeySettingsDuration_DURATION_WEEK: 'week',
		apiKeySettingsDuration_DURATION_WEEKS: 'weken',
		apiKeySettingsDuration_DURATION_YEAR: 'jaar',
		apiKeySettingsDuration_DURATION_YEARS: 'jaren',
		apiKeySettingsFromUntilHeader: 'Gelieve de geldigheidsperiode van de sleutel te specificeren.',
		apiKeySettingsOptionalDescription: 'Omschrijving van de sleutel',
		apiKeySettingsContactEmailHeader: 'Contact e-mail om te verwittigen wanneer de sleutel bijna gaat vervallen, bvb. de support inbox van het bedrijf',
		apiKeySettingsState: 'Status',
		apiKeySettingsStateActive: 'Actief',
		apiKeySettingsStateExpired: 'Verlopen',
		apiKeySettingsStateNotActive: 'Niet actief',
		apiKeySettingsValidFrom: 'Geldig vanaf',
		apiKeySettingsValidUntil: 'Geldig tot',
		apiKeySettingsRevoke: 'API sleutel intrekken',
		apiKeySettingsRevokeConfirm: 'Bent u zeker dat u deze API sleutel wil intrekken? Deze actie is onomkeerbaar.',

		switchCurrentCompany: 'Actief bedrijf',
		switchToOtherCompany: 'Bedrijf aanpassen',
		switchTo: 'Aanpassen',

		insightsTab_STATISTICS: 'Statistieken',
		insightsTab_ACTION_LOGS: 'Actielogboek',

		statsNoData: 'Geen gegegevens',
		statsSource_SIGNATURES: 'Handtekeningen',
		statsLegendTitle_SIGNATURES: 'Handtekeningen (selecteer type om te verbergen)',
		statsSource_APPROVALS: 'Goedkeuringen',
		statsLegendTitle_APPROVALS: 'Goedkeuringen (selecteer type om te verbergen)',
		statsMode_MONTH: 'Per maand',
		statsMode_DAY: 'Per dag',
		statsBeginDate: 'Begin',
		statsEndDate: 'Einde',
		statsCompanyFilterTitle: 'Bedrijf',
		statsCompanyFilter_ALL: 'Alle',
		statsFolderFilterTitle: 'Folder',
		statsFolderFilter_ALL: 'Alle (afzonderlijk)',
		statsFolderFilter_NONE: 'Alle (gegroepeerd)',
		statsSigningOrigin: 'Handtekening oorsprong',
		statsSigningOrigin_ALL: 'Alle',
		statsSigningOrigin_LOCAL: 'Intern (lokaal)',
		statsSigningOrigin_REMOTE: 'Extern (remote)',
		statsSigningItsmeConfig: 'itsme® configuratie',
		statsInfo: 'U kan hieronder de goedkeuring, onderteken en itsme® statistieken bekijken en exporteren. De gekozen filters zijn van toepassing op zowel de grafiek, detail en geëxporteerde data.',
		statsChart: 'Grafiek',
		statsItsmeDetails: 'itsme® details',
		statsItsmeDetailsFolder: 'Folder',
		statsItsmeDetailsCompany: 'Bedrijf',
		statsItsmeDetailsItsmeConfig: 'itsme® configuratie',
		statsItsmeDetailsCount: 'Handtekeningen',
		statsItsmeDetailsUniqueSigners: 'Unieke gebruikers',
		statsItsmeDetailsInfo: 'Opgelet: het aantal unieke itsme® gebruikers wordt pas vanaf v2.6 (09/07/2024) bijgehouden. Alle itsme® ondertekeningen voor deze versie worden niet meegeteld als unieke gebruiker in deze statistieken.',
		statsItsmeDetailsRatio: 'Verhouding',
		statsItsmeDetailsRatioTooltip: 'Verhouding aantal handtekeningen op aantal unieke ondertekenaars',
		statsItsmeConfigFilter_ALL: 'Alle',

		webhookSettingsHeader: 'Webhookconfiguraties',
		webhookSettingsHeaderDescription: 'U kunt verschillende webhookconfiguraties beheren, momenteel wordt alleen de mTLS-authenticatieconfiguratie ondersteund.',
		webhookSettingsConfigCreate: 'Configuratie maken',
		webhookSettingsConfigDescription: 'Beschrijving',
		webhookSettingsConfigExpiry: 'Vervaldatum van client-certificaat',
		webhookSettingsConfigSelectTitle: 'Selecteer PKCS12-bestand',
		webhookSettingsConfigSelect: 'Selecteer',
		webhookSettingsConfigSelectDescription: 'Dit bestand moet een privésleutel, client-certificaat + keten bevatten. Optioneel kan het met een wachtwoord worden versleuteld.',
		webhookSettingsConfigFilePassword: 'Wachtwoord',
		webhookSettingsConfigFilePasswordDescription: 'Wachtwoord dat is gebruikt om het PKCS12-bestand te maken (optioneel)',
		webhookSettingsConfigDelete: 'Configuratie verwijderen',
		webhookSettingsConfigDeleteConfirm: 'Weet u zeker dat u deze webhookconfiguratie wilt verwijderen? Dit is onomkeerbaar.',

		actionLogPerformedAt: 'Tijdstip',
		actionLogActorFullName: 'Uitvoerder',
		actionLogEvent: 'Gebeurtenis',
		actionLog_COMPANY_CREATED: 'Bedrijf {0} is aangemaakt',
		actionLog_COMPANY_UPDATE_NAME: 'Naam van het bedrijf is aangepast van {0} naar {1}',
		actionLog_COMPANY_API_ENABLE: 'API toegang is verleend',
		actionLog_COMPANY_API_DISABLE: 'API toegang is ingetrokken',
		actionLog_COMPANY_API_KEY_CREATED: 'API sleutel is aangemaakt met een geldigheid van {0} tot {1}',
		actionLog_COMPANY_API_KEY_REVOKED: 'API key is ingetrokken met een geldigheid van {0} tot {1}',
		actionLog_COMPANY_MFA_REQUIRED: 'Multi-factor authenticatie is nu noodzakelijk voor adminrollen',
		actionLog_COMPANY_MFA_NOT_REQUIRED: 'Multi-factor authenticatie is niet langer noodzakelijk voor adminrollen',
		actionLog_DOCUMENT_DELETE: 'Document {0} (id={1}) is verwijderd',
		actionLog_FOLDER_CREATE: 'Map {0} is aangemaakt',
		actionLog_FOLDER_DELETE: 'Map {0} is verwijderd',
		actionLog_FOLDER_GROUP_PERMISSION_DELETE: 'Machtiging {0} is ingetrokken voor groep {1} op map {2}',
		actionLog_FOLDER_GROUP_PERMISSION_CREATE: 'Machtiging {0} is verleend voor groep {1} op map {2}',
		actionLog_FOLDER_USER_PERMISSION_DELETE: 'Machtiging {0} is ingetrokken voor gebruiker {1} op map {2}',
		actionLog_FOLDER_USER_PERMISSION_CREATE: 'Machtiging {0} is verleend voor gebruiker {1} op map {2}',
		actionLog_USER_INVITED: 'Gebruiker met e-mail {0} is uitgenodigd',
		actionLog_USER_INVITATION_RESEND: 'Uitnodiging is herzonden naar {0}',
		actionLog_USER_GUEST_ADDED: 'Gebruiker met e-mail {0} is toegevoegd als gast',
		actionLog_USER_PROMOTED: 'Gebruiker met e-mail {0} heeft nu {1} als rol',
		actionLog_USER_DEMOTED: 'Gebruiker met e-mail {0} heeft rol {1} niet meer',
		actionLog_USER_DELETED: 'Gebruiker met e-mail {0} is verwijderd',
		actionLog_USER_EMAIL_CHANGED: 'Gebruiker met e-mail {0} heeft nu {1} als e-mail',
		actionLog_USER_FIRSTNAME_CHANGED: 'Gebruiker met e-mail {0} heeft nu {2} als voornaam (was {1})',
		actionLog_USER_LASTNAME_CHANGED: 'Gebruiker met e-mail {0} heeft nu {2} als achternaam (was {1})',
		actionLog_USER_NOTIFICATION_ADDED: 'Notificatie {1} is geactiveerd bij gebruiker met email {0}',
		actionLog_USER_NOTIFICATION_UPDATED: 'Notificatie {1} is gewijzigd naar {2} bij gebruiker met email {0}',
		actionLog_USER_NOTIFICATION_REMOVED: 'Notificatie {1} is verwijderd bij gebruiker met email {0}',
		actionLog_USER_MFA_AUTHENTICATOR_ADDED: 'Gebruiker met e-mail {0} heeft een authenticator ingesteld',
		actionLog_USER_MFA_AUTHENTICATOR_REMOVED: 'Gebruiker met e-mail {0} heeft geen authenticator meer ingesteld',
	},

	delegates: {
		listsFor: 'Lijsten van afgevaardigden voor',
		description: 'U kan hier de lijsten voor afgevaardigden beheren. Per lijst kan u het type instellen (goedkeuring of ondertekening) en ook beheren of dit een lijst is voor iedereen of een lijst voor een specifieke gebruiker. Lijsten voor specieke gebruikers hebben altijd voorrang op lijsten voor iedereen.',
		listCreate: 'Lijst aanmaken',
		listEdit: 'Lijst aanpassen',
		count: 'Aantal afgevaardigden',
		actions: 'Acties',
		create: 'Lijst van afgevaardigden aanmaken',
		edit: 'Lijst van afgevaardigden aanpassen',
		type: 'Type afvaardiging',
		type_DOCUMENT_APPROVAL: 'Goedkeuring',
		type_DOCUMENT_SIGNATURE: 'Handtekening',
		for: 'Voor specifieke gebruiker',
		forEveryone: 'Iedereen',
		chooseFor: 'Kies specifieke gebruiker',
		deleteFor: 'Verwijder specifieke gebruiker',
		delegates: 'Afgevaardigden',
		addDelegates: 'Afgevaardigden toevoegen',
		order: 'Volgorde',
		up: 'Plaats naar boven',
		down: 'Plaats naar beneden',
		firstName: 'Voornaam',
		lastName: 'Achternaam',
		email: 'E-mail',
		delete: 'Verwijderen',
		listDelete: 'Lijst verwijderen',
		listDeleteConfirm: 'Bent u zeker dat u deze lijst wil verwijderen?',
		deleted: 'Lijst van afgevaardigden verwijderd',
		delegate: 'Afgevaardigde',
		file: 'Volmacht',
		fileUploadDescription: 'Hier kan u (optioneel) een bestand uploaden die de volmacht beschrijft. Dit bestand wordt toegevoegd aan het bewijsrapport. Opgelet: deze wijziging zal onmiddellijk opgeslagen worden.',
		fileDescription: 'Volmacht is geconfigureerd en zal toegevoegd worden aan het bewijsrapport. Opgelet: het bestand verwijderen of wijzigen zal onmiddellijk toegepast worden.',
		fileName: 'Volmacht bestandsnaam',
		fileDownload: 'Volmacht bestand downloaden',
		fileUpload: 'Upload',
		fileDelete: 'Volmacht bestand verwijderen',
		fileDeleteConfirm: 'Bent u zeker dat u dit bestand wil verwijderen?',
		fileSaveFirst: 'Opgelet: om volmacht bestanden toe te voegen aan nieuwe afgevaardigden moet de huidige lijst eerst opgeslagen worden.',
		uploadFile: 'Volmacht uploaden'
	},

	user: {
		state_INVITED: 'Uitgenodigd',
		state_REGISTERED: 'Geregistreerd',
		state_GUEST: 'Gast',
		state_GUEST_CONTACT: 'Contact in {{folder}}',
		state_REMOVED: 'Verwijderd',
		state_API: 'API',

		header: 'Gebruikers',
		firstName: 'Voornaam',
		lastName: 'Achternaam',
		guest: 'Gast',
		memo: 'Memo',
		email: 'E-mail',
		phoneNumber: 'Telefoonnummer (formaat +32477112233)',
		state: 'Status',
		defaultRemarkVisualSignature: 'Standaard opmerking visuele handtekening',
		actions: 'Acties',
		create: 'Uitnodigen',
		import: 'Importeren',
		delete: 'Verwijderen',
		registerGuest: 'Converteren naar een geregistreerde gebruiker',
		resendInvitation: 'Uitnodiging herzenden',
		deleted: 'Gebruiker verwijderd',

		deleteConfirm: 'Bent u zeker dat u deze gebruiker wil verwijderen?',
		registerGuestConfirm: 'Bent u zeker dat u deze gebruiker wil registeren?',

		createName: 'Naam van de gebruiker',
		createEmail: 'E-mail adres van de gebruiker',
		createLanguage: 'Taal van de gebruiker',
		createPhoneNumber: 'Telefoonnummer van de gebruiker',
		createAddType: 'Toevoegen als',
		createAddType_REGISTERED: 'Geregistreerde gebruiker',
		createAddType_GUEST: 'Gast',
		createAddType_CONTACT: 'Contact in {{folder}}',
		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Na het uitnodigen van de gebruiker kan additionele configuratie gedaan worden (indien de juiste rechten aanwezig zijn).',

		settingsUserMetaAttributes: 'Meta-attributen',
		settingsUserMetaAttributeNew: 'Nieuw meta-attribuut',
		settingsUserMetaAttributeKey: 'Attribuut',
		settingsUserMetaAttributeValue: 'Waarde',

		uploadAllowUpdate: 'Wijziging van bestaande gebruikers toestaan',
		uploadDownloadTemplate: 'Download voorbeeld bestand',
		uploadInvalidFileExtension: 'Ongeldig bestandsformaat. Gelieve een .csv bestand te selecteren.',
		uploadFileLimitExceeded: 'Bestandsgrootte overschreden. Gelieve een bestand kleiner dan 100MB te selecteren.',
		uploadImportedUsersResult: 'Aantal succesvol geïmporteerde gebruikers',
		uploadImportedErrors: 'Fouten gevonden tijdens het importeren van het bestand:',
		uploadParsingError: 'Ongeldig data formaat',
		uploadError_CANNOT_ADD_TO_COMPANY_GROUP: 'Gebruiker kon niet aan één of meerdere gebruikersgroepen toegevoegd worden',
		uploadError_COMPANY_USER_GROUP_NOT_FOUND: 'Kon één of meerdere gebruikersgroepen gespecifieerd voor deze gebruiker niet vinden',
		uploadError_EMAIL_DUPLICATE: 'Email meermaals gespecifieerd in het bestand',
		uploadError_EMAIL_INVALID: 'Ongeldig email adres',
		uploadError_CONVERT_REGISTERED_TO_GUEST: 'Geregistreerde gebruiker kan geen gast worden',
		uploadError_CONTACT_FOLDER_MISSING: 'Kon folder om contact gebruiker in aan te maken niet vinden',
		uploadError_GUEST_AND_CONTACT_CONFLICT: 'Gebruiker kan niet toegevoegd worden als gast en contact tegelijk',
		uploadError_INVALID_NOTIFICATION: 'Een of meerdere herinneringstypes zijn ongeldig',
		uploadError_PARSING_ERROR: 'Ongeldige data',
		uploadError_UNKNOWN: 'Een onbekende fout is opgetreden',
		uploadError_USER_ALREADY_EXISTS: 'Gebruiker bestaat reeds',
		uploadError_NOT_ALLOWED: 'Het is niet toegestaan deze actie te ondernemen',

		outOfOfficeNotActive: 'Niet actief',
		outOfOfficeAlwaysActive: 'Altijd actief',
		outOfOfficeConfiguredActive: 'Actief',
		outOfOfficeConfiguredActiveFrom: 'Van',
		outOfOfficeConfiguredActiveUntil: 'Tot',

		settings: 'Instellingen',
		settingsGeneral: 'Algemeen',
		settingsVisualSignature: 'Handtekening',
		settingsVisualSignatureUpload: 'Nieuwe visuele handtekening opladen (max 1MB)',
		settingsVisualSignatureDelete: 'Verwijder visuele handtekening',
		settingsVisualSignatureDescription: 'Wijzigingen aan de visuele handtekening zullen onmiddelijk toegepast worden.',
		settingsOutOfOffice: 'Out-of-office',
		settingsLanguage: 'Taal',
		settingsCommunication: 'Communicatie',
		settingsCommunicationReceiveEmail: 'Gebruiker ontvangt e-email bij:',
		settingsInviteUserNotification: 'Document goedkeuring/ondertekening aanvraag',
		settingsReminderUserNotification: 'Herinneringen',
		settingsApprovalUserNotification: 'Document goed- of afgekeurd',
		settingsSignatureUserNotification: 'Uw document ondertekend',
		settingsFullySignedUserNotification: 'Document volledig ondertekend',
		settingsClosedUserNotification: 'Document afgesloten',
		settingsExpiredUserNotification: 'Document verlopen',
		settingsMessageUserNotification: 'Document bericht',
		settingsPermissions: 'Rechten',
		settingsPermissionsGuestDisabled: 'Opgelet: instellingen voor rechten niet toepasbaar op gasten/contacten',
		settingsPermissionsUserIs: 'Gebruiker is:',
		settingsPermissionsUserCanManage: 'Gebruiker kan beheren:',
		settingsAdmin: 'Admin',
		settingsUserManager: 'Gebruikers',
		settingsUserGroupManager: 'Gebruikersgroepen',
		settingsUserGroups: 'Lidmaatschap van gebruikersgroep',
		settingsUserGroupsNoAccess: 'U heeft geen toegang om de gebruikersgroepen te beheren',
		settingsUserGroupName: 'Naam',
		settingsUserGroupMember: 'Lid',
		settingsUserGroupAdmin: 'Beheerder',
		settingsSignerGroupManager: 'Ondertekenaarsgroepen',
		settingsSignerGroups: 'Lidmaatschap ondertekenaarsgroep',
		settingsSignerGroupsNoAccess: 'U heeft geen toegang om de ondertekenaarsgroepen te beheren',
		settingsSignerGroupName: 'Naam',
		settingsSignerGroupMember: 'Lid',
		settingsDocumentFolderManager: 'Documentmappen',
		settingsDocumentFolders: 'Machtigingen mappen',
		settingsDocumentFoldersNoAccess: 'U heeft geen toegang om de documentmappen te beheren',
		settingsDocumentFolderName: 'Naam',
		settingsOutOfOfficeManager: 'Out-of-office instellingen andere gebruikers',
		settingsTemplateManager: 'Documentsjablonen',
		settingsPermissionsUserCanView: 'Gebruiker kan bekijken:',
		settingsActionLogViewer: 'Actielogboek van het bedrijf',
		settingsPermissionsUserCanInvite: 'Gebruiker kan uitnodigen:',
		settingsInviteRegisteredUsers: 'Geregistreerde gebruikers',
		settingsInviteGuestUsers: 'Gasten',
		settingsInviteContacts: 'Contacten',
		settingsFinish: 'Alles ingevuld?',
		settingsFinishDescription: 'Druk op opslaan om de wijzigingen te behouden',
		settingsSave: 'Opslaan',

		authenticatorGuide: 'Installeer een authenticator app op uw smartphone, vanuit de Android Play store of de Apple App store (bvb Google Authenticator of Microsoft Authenticator).',
		authenticatorSet: 'Authenticator succesvol ingesteld',
		authenticatorSetAction: 'Instellen',
		authenticatorStepDownload: 'Download',
		authenticatorScanQrCode: 'Scan the onderstaande QR code met uw authenticator app',
		authenticatorStepScanQrCode: 'Scan QR Code',
		authenticatorResetAction: 'Uitschakelen',
		authenticatorTitle: '2-stapsverificatie',
		authenticatorConfigured: 'Authenticator geconfigureerd.',
		authenticatorNotConfigured: 'Authenticator niet geconfigureerd.',
		authenticatorChallenge: 'Code',
		authenticatorChallengeTitle: 'Voer voor extra veiligheid het One Time Password (OTP) gegenereerd door uw authenticator App in.',
		authenticatorEnterChallenge: 'Voer het One Time Password (OTP) gegenereerd door uw authenticator App in.',
		authenticatorRequired: '2-stapsverificatie is vereist voor beheerders van dit bedrijf',
		authenticatorRemoveText: 'Bent u zeker dat u deze 2-stapsverificatie wilt verwijderen?',
		authenticatorRemoveAdminText: 'Bent u zeker dat u deze 2-stapsverificatie wilt verwijderen? Hierdoor verliest u tijdelijk admin rechten totdat 2-stapsverificatie terug geactiveerd wordt.',
		authenticatorRemoveTitle: '2-stapsverificatie uitschakelen',
		authenticatorRemovedMessage: '2-stapsverificatie succesvol uitgeschakeld',

		settingsTab_PROFILE: 'Profiel',
		settingsTab_SECURITY: 'Beveiliging',
		myProfileHeader: 'Mijn gebruiker',
		profileHeader: 'Gebruiker',
		profileSaveWarning: 'Opgelet, als u wijzigingen heeft gedaan op deze pagina, moet u ze nog altijd opslaan om deze niet te verliezen.',
		profileGuestSaveWarning: 'Opgelet, wijzigingen aan de visuele handtekening en initialen zullen onmiddellijk opgeslagen worden.',
		profilePersonalHeader: 'Persoonlijke gegevens',
		profilePersonalFirstName: 'Voornaam',
		profilePersonalLastName: 'Achternaam',
		profilePersonalMemo: 'Memo',
		profilePersonalEmail: 'E-mail',
		profilePersonalEidCardNumber: 'eID kaart nummer',
		profilePersonalDefaultRemarkVisualSignature: 'Standaard opmerking visuele handtekening',
		profileCapacities: 'Hoedanigheden',
		profileCapacityTitleHeader: 'Titel van de hoedanigheid',
		profileCapacityTitle: 'Titel',
		profileCapacityRemarkHeader: 'Standaard opmerking',
		profileCapacityRemark: 'Opmerking',
		profileCapacityActions: 'Acties',
		profileCapacityDelete: 'Verwijder hoedanigheid',
		profileCapacityAdd: 'Toevoegen',
		profileCapacityUpdate: 'Hoedanigheid aanpassen',
		profileCapacityCreate: 'Hoedanigheid aanmaken',
		profileVisualData: 'Visuele handtekening/paraaf',
		profileItsmeInfo: 'Uw itsme® identiteit werd opgeslagen tijdens een ondertekening. Dit laat u toe om de itsme® identificatie over te slaan. U kan hier deze identiteit van uw Quill account verwijderen.',
		profileItsmeForget: 'Itsme® identiteit wissen',
		profileItsmeForgetSuccess: 'Uw itsme® identiteit werd succesvol van uw Quill account verwijderd.',
		profileGeneralHeader: 'Algemene instellingen',
		profileGeneralDefaultRowsPerPage: 'Standaard aantal rijen in tabellen',
		profileGeneralLanguage: 'Taal',
		profileOutOfOfficeHeader: 'Out-of-office',
		profileOutOfOfficeNotActive: 'Niet actief',
		profileOutOfOfficeAlwaysActive: 'Altijd actief',
		profileOutOfOfficeConfiguredActive: 'Actief',
		profileOutOfOfficeConfiguredActiveFrom: 'Van',
		profileOutOfOfficeConfiguredActiveUntil: 'Tot',
		profileCommunicationHeader: 'Communicatie instellingen',
		profileCommunicationSubHeader: 'Ontvang e-mails voor:',
		profileCommunicationEmailInvite: 'Verzoeken om een document goed te keuren of te ondertekenen',
		profileCommunicationEmailReminder: 'Herinneringen om een document goed te keuren of te ondertekenen',
		profileCommunicationEmailFullySigned: 'Documenten die volledig ondertekend zijn',
		profileCommunicationEmailSignaturePlaced: 'Handtekeningen die geplaatst zijn op een document dat u heeft verzonden',
		profileCommunicationEmailApproval: 'Documenten die worden goedgekeurd of afgekeurd',
		profileCommunicationEmailClosed: 'Document die worden afgesloten',
		profileCommunicationEmailExpired: 'Documenten die zijn verlopen',
		profileCommunicationEmailMessage: 'Document berichten',
		profileUpdatePasswordHeader: 'Verander wachtwoord',
		profileApiAccess: 'API-toegang',
		profileApiAccessNotEnabled: 'De toegang tot de API is niet ingeschakeld voor dit account. Klik hieronder om dit in te schakelen. Houd er rekening mee dat uw API-sleutel slechts één keer zichtbaar is!',
		profileApiAccessEnable: 'Schakel API-toegang in',
		profileApiAccessEnabled: 'De toegang tot de API is ingeschakeld voor dit account. Klik hieronder om dit uit te schakelen.',
		profileApiAccessSecret: 'API-sleutel',
		profileApiAccessSecretCopy: 'Kopiëer',
		profileApiAccessDisable: 'Schakel API-toegang uit',
		password: 'Wachtwoord',
		currentPassword: 'Huidig wachtwoord',
		newPassword: 'Nieuw wachtwoord',
		newPasswordConfirmation: 'Bevestiging nieuw wachtwoord',
		passwordConfirmation: 'Wachtwoord bevestiging',
		passwordRequirements: 'Gebruik een wachtwoord dat minimaal 8 tekens lang is en minstens één symbool, één cijfer, één kleine letter en één hoofdletter bevat, of gebruik een wachtwoord met minimaal 16 tekens zonder specifieke vereisten.',

		notification_ON: 'Aan',
		notification_OFF: 'Uit',
		notification_DAILY_DIGEST: 'Dagelijkse samenvatting',

		importNoHeader: 'Zonder header',
		importPasteOrDropFile: 'Kopieer gebruikersdata hier of sleep een CSV bestand (UTF-8)',
		importDownloadTemplate: 'Download voorbeeld bestand',
		importInviteUsers: 'Nieuwe gebruikers uitnodigen',
		importLookupCreatedUsersResult: 'Aantal gebruikers succesvol aangemaakt',
		importLookupFoundUsersResult: 'Aantal gebruikers succesvol teruggevonden',
		importLookupUsersNotFound: 'Gebruikers die niet werden teruggevonden:',
	},

	userGroup: {
		header: 'Gebruikersgroepen',
		name: 'Naam',
		description: 'Omschrijving',
		actions: 'Acties',
		create: 'Gebruikersgroep aanmaken',
		delete: 'Verwijderen',
		deleteConfirm: 'Bent u zeker dat u deze groep wil verwijderen?',
		deleted: 'Gebruikersgroep verwijderd',

		createName: 'De naam van de gebruikersgroep',
		createDescription: 'De omschrijving van de gebruikersgroep',
		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Na het aanmaken van de gebruikersgroep kunnen leden toegevoegd en geconfigureerd worden.',

		settings: 'Instellingen',
		settingsNameDescription: 'Gebruikersgroep naam en omschrijving',
		settingsFinish: 'Alles ingevuld?',
		settingsFinishDescription: 'Druk op opslaan om de wijzigingen te behouden',
		settingsSave: 'Opslaan',

		import: 'Importeer gebruikers',

		users: 'Leden',
		userName: 'Naam',
		userEmail: 'E-mail',
		userAdmin: 'Groep admin',
		addUser: 'Lid toevoegen',
		userRemove: 'Lid verwijderen',
		userRemoveConfirm: 'Bent u zeker dat u dit lid wil verwijderen?',
		userState: 'Status',

		userSelect: 'Selecteer lid/leden om toe te voegen',
		usersDescription: 'Leden aan/van deze groep toevoegen, verwijderen en configureren. Merk op dat deze veranderingen onmiddelijk toegepast zullen worden.',
	},

	folder: {
		header: 'Mappen',
		name: 'Naam',
		description: 'Omschrijving',
		actions: 'Acties',
		settings: 'Instellingen',
		permissions: 'Machtigingen',
		delegates: 'Afgevaardigden',
		delete: 'Verwijderen',
		deleteConfirm: 'Bent u zeker dat u deze map wil verwijderen?',
		deleted: 'Map verwijderd',

		create: 'Map aanmaken',
		createName: 'De naam van de map',
		createDescription: 'De omschrijving van de map',
		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Na het aanmaken van een map kunnen instellingen en machtigingen worden toegevoegd. De instellingen en machtigingen hebben betrekking op alle documenten in de map.',

		settingsNameDescription: 'Mapnaam en omschrijving',
		settingsSignatureTypes: 'Types handtekeningen',
		settingsSignatureType_ELECTRONIC_WITH_WITNESS_SEAL: 'Elektronisch zegel',
		settingsSignatureType_BELGIAN_EID: 'Belgische eID',
		settingsSignatureType_ITSME: 'Gekwalificeerde itsme®',
		settingsSignatureType_ITSME_ADVANCED: 'Geavanceerde itsme®',
		settingsSignatureType_SMS_OTP: 'Code via SMS',
		settingsSignatureType_EMAIL_OTP: 'Code via e-mail',
		settingsSignatureType_HANDWRITTEN: 'Handgeschreven',
		settingsBlocked: 'instelling bepaald op bedrijfsniveau',
		settingsReasonAndRemark: 'Motivatie en opmerking',
		settingsReason: 'Ondertekenaar kan een motiverende reden toevoegen',
		settingsReasonLegalNoticeMandatory: 'Maak dit een verplichte wettelijke mededeling voor de gebruiker in te voeren. Dit verschijnt ook visueel op het document.',
		settingsReasonLegalNoticeText: 'Vooringevulde waarde',
		settingsEmailDistributionList: 'Standaardinstellingen voor de distributielijst na ondertekenen',
		settingsEmailDistributionLabel: 'Lijst met e-mailadressen gescheiden door \';\'',
		settingsEmailDocumentDistributionListDescription: 'Document geadresseerden',
		settingsEmailDocumentAndEvidenceReportDistributionListDescription: 'Document en bewijsrapport geadresseerden',
		settingsEmailEvidenceReportIncludedInPostSignEmail: 'Voeg bewijsrapport toe',
		settingsOverrideOnFolderLevelRemove: 'Klik hier om de standaard bedrijfsinstellingen te gebruiken',
		settingsOverrideOnFolderLevelSet: 'Klik hier om de standaard bedrijfsinstellingen te overschrijven',
		settingsRemark: 'Ondertekenaar kan opmerking toevoegen',
		settingsMailbox: 'Mailbox',
		settingsMailboxEnabled: 'Activeer een mailbox voor deze map',
		settingsMailboxAddress: 'E-mailadres voor deze mailbox',
		settingsFolderCleanup: 'Automatisch verwijderen van documenten',
		settingsFolderCleanupOverwrite: 'Overschrijf de standaard bedrijfsinstellingen',
		settingsCleanupDaysProcessed: 'dag(en) nadat een voltooid document wordt verwijderd',
		settingsCleanupDaysPending: 'dag(en) nadat een inactief onvoltooid document wordt verwijderd',
		settingsFolderExpiration: 'Automatisch verlopen van documenten',
		settingsExpirationOverride: 'Overschrijf bedrijfsinstellingen voor deze folder',
		settingsExpirationEnable: 'Schakel het verlopen van verzoeken tot goedkeuring of ondertekenen in',
		settingsSignatureExpirationDays: 'dag(en) voordat een open verzoek tot goedkeuring of ondertekenen vervalt',
		settingsFolderForwarding: 'Doorsturen van verzoeken om goedkeuring en ondertekening',
		settingsAllowForwarding: 'Sta doorsturen toe',
		settingsDecline: 'Document ondertekening afwijzen',
		settingsDeclineOverride: 'Overschrijf bedrijfsinstellingen voor deze folder',
		settingsDeclineEnabled: 'Sta afwijzen toe',
		settingsForwardingEnabledByDefault: 'Doorsturen standaard inschakelen',
		settingsReminders: 'Herinneringen',
		settingsRemindersOverwrite: 'Overschrijf bedrijfsinstellingen voor deze folder',
		settingsRemindersIntervalInDays: 'dag(en) tussen herinneringen. Gebruik 0 om uit te schakelen.',
		settingsMinimizeCollectionInvites: 'Beperk dossier uitnodigingen',
		settingsMinimizeCollectionInvitesFolder: 'Overschrijf bedrijfsinstellingen voor deze folder',
		settingsMinimizeCollectionInvitesEnable: 'Wacht op andere goedkeuringen/handtekeningen om het aantal dossier uitnodigingen te beperken',
		settingsMinimizeCollectionInvitesGracePeriod: 'Maximum wachttijd in minuten',
		settingsMinimizeCollectionInvitesGracePeriodHelp: 'Laat blanco om geen maximum in te stellen',
		settingsMinimizeCollectionFinishedNotifications: 'Beperk het aantal meldingen van voltooide documenten binnen een dossier',
		settingsMinimizeCollectionFinishedNotificationsFolder: 'Overschrijf bedrijfsinstellingen voor deze folder',
		settingsMinimizeCollectionFinishedNotificationsEnable: 'Stuur meldingen van voltooide documenten pas wanneer het hele dossier klaar is',
		settingsSignerNeedsSignatureToDownload: 'Download vereist handtekening ondertekenaar',
		settingsSignerNeedsSignatureToDownloadFolder: 'Overschrijf bedrijfsinstellingen voor deze folder',
		settingsSignerNeedsSignatureToDownloadEnable: 'Een geplaatste handtekening is noodzakelijk om een document te kunnen downloaden door de ondertekenaar in deze folder',
		settingsDocumentCollection: 'Dossier moet als gelezen gemarkeerd worden voor ondertekening',
		settingsDocumentCollectionMarkAsReadMandatory: 'Overschrijf bedrijfsinstellingen voor deze folder',
		settingsDocumentCollectionMarkAsReadMandatoryEnable: 'Vereisen dat alle documenten als gelezen gemarkeerd worden voor ondertekening mogelijk is',
		settingsPlaceholders: 'Opzoeken van placeholders in documenten',
		settingsPlaceholderValue: 'Trefwoord om te zoeken',
		settingsPlaceholderFieldType: 'Type veld',
		settingsPlaceholderFieldType_SIGNATURE: 'Handtekening veld',
		settingsPlaceholderFieldType_PARAPH: 'Paraaf veld',
		settingsPlaceholderFieldType_FORM: 'Invulbaar veld',
		settingsPlaceholderFormFieldType: 'Type invulbaar veld',
		settingsPlaceholderFormFieldType_TEXT: 'Text',
		settingsPlaceholderFormFieldType_CHECKBOX: 'Checkbox',
		settingsPlaceholderFormFieldType_DATE: 'Datum',
		settingsPlaceholderFormFieldType_NUMERIC: 'Numeriek',
		settingsPlaceholderFormFieldType_ATTRIBUTE: 'Attribuut van ondertekenaar',
		settingsPlaceholderAdd: 'Toevoegen',
		settingsUserUsage: 'Welke gebruikers mogen documenten goedkeuren/ondertekenen',
		settingsUserUsageRegisteredUser: 'Geregistreerde gebruikers',
		settingsUserUsageGuest: 'Gasten',
		settingsUserUsageContact: 'Contacten',
		settingsUserUsageContactHelp: 'Contacten zijn gekoppeld aan een specifieke map',
		settingsUserInvite: 'Welke gebruikers mogen worden uitgenodigd',
		settingsUserInviteRegisteredUsers: 'Geregistreerde gebruikers',
		settingsUserInviteGuests: 'Gasten',
		settingsUserInviteContacts: 'Contacten',
		settingsMailRedirect: 'Redirect URL bij herbezoek onderteken link via mail inschakelen',
		settingsMailRedirectEnabled: 'Mail redirect inschakelen',
		settingsSigningKioskMode: 'Ondertekenen in kiosk mode',
		settingsSigningKioskModeEnabled: 'Kiosk mode inschakelen',
		settingsCollectionPartialDocumentHandling: 'Dossier individuele document afhandeling',
		settingsAllowPartialCollectionDocumentHandling: 'Sta ondertekenen/afwijzen van geselecteerd(e) document(en) binnen dossier toe',
		settingsFileTypes: 'Toegestane bestand upload types',
		settingsFileType_PDF: 'PDF',
		settingsFileType_DOC: 'Document (doc, docx)',
		settingsFileType_PRESENTATION: 'Presentatie (ppt, pptx)',
		settingsFileType_IMAGE: 'Afbeelding (png, jpeg, tiff, ...)',
		settingsFileType_SPREADSHEET: 'Spreadsheet (xls, xlsx, ...)',
		settingsFileType_SPREADSHEET_suffix: ' bij dossier uploads',

		settingsFinish: 'Alles ingevuld?',
		settingsFinishDescription: 'Druk op opslaan om de wijzigingen te behouden',
		settingsSave: 'Opslaan',

		permissionsGroup: 'Groepsmachtigingen',
		permissionsGroupDescription: 'Het verlenen van een machtiging aan een groep geeft deze machtiging aan al zijn gebruikers. Dit betekent dat wanneer een gebruiker lid is van een bepaalde groep die een machtiging is toegewezen, de gebruiker deze machtiging ook toegewezen krijgt. Zelfs indien de gebruiker in een groep zit waarbij deze machtiging niet is toegewezen.',
		permissionsGroupLabel: 'Groep',
		permissionsGroupSelect: 'Selecteer groep(en) om toe te voegen',
		permissionsEditFolderPermission: 'Map bewerken',
		permissionsEditDocumentsPermission: 'Documenten bewerken',
		permissionsViewDocumentsPermission: 'Documenten bekijken',
		permissionsAddUserNoPermissions: 'Gebruiker(s) toevoegen zonder machtigingen',
		permissionsAddUserAllPermissions: 'Gebruiker(s) toevoegen met alle machtigingen',
		permissionsAddGroupNoPermissions: 'Groep(en) toevoegen zonder machtigingen',
		permissionsAddGroupAllPermissions: 'Groep(en) toevoegen met alle machtigingen',
		permissionsUser: 'Gebruikersmachtigingen',
		permissionsUserDescription: 'Bij voorkeur worden de machtigingen ingesteld op groepsniveau hierboven. Indien het niet mogelijk zou zijn om een gebruiker lid van een bepaalde groep te maken of indien de gebruiker extra machtigingen nodig heeft, kunnen deze hier worden toegevoegd.',
		permissionsUserSelect: 'Selecteer gebruiker(s) om toe te voegen',
		permissionsFinish: 'Alles ingesteld?',
		permissionsFinishDescription: 'Druk op bewaren om de machtigingen op te slaan',
		permissionsSave: 'Bewaren',
	},

	template: {
		name: 'Naam',
		creatorName: 'Aanmaker',
		folderName: 'Map',
		actions: 'Acties',
		edit: 'Sjabloon bewerken',
		view: 'Sjabloon bekijken',
		delete: 'Sjabloon verwijderen',
		deleted: 'Sjabloon verwijderd',
		select: 'Sjabloon selecteren',
		overviewHeader: 'Documentsjablonen',
		deleteTemplateConfirm: 'Weet u zeker dat u het sjabloon wilt verwijderen?',
		createName: 'Sjabloon naam',
		createFolder: 'Sjabloon map',
		createType: 'Sjabloon type',
		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Druk op opslaan om het sjabloon aan te maken.',
		create: 'Sjabloon aanmaken',
		folder: 'Map',
		type: 'Type',
		typeSingleDocNoPdf: 'Enkelvoudig document, zonder PDF',
		typeSingleDocPdf: 'Enkelvoudig document, met PDF',
		typeCollection: 'Dossier',
		uploadDoc: 'Opladen document',
		uploadErrorGeneralError: 'Fout',
	},

	document: {
		name: 'Naam',
		createdAt: 'Aangemaakt op',
		changedAt: 'Aangepast op',
		requestedAt: 'Aangevraagd op',
		description: 'Omschrijving',
		folderName: 'Map',
		status: 'Status',
		status_FUTURE: 'In afwachting',
		status_PREPARING: 'In voorbereiding',
		status_WAITING_FOR_APPROVAL: 'Wachten op goedkeuring',
		status_APPROVED: 'Goedgekeurd',
		status_NOT_APPROVED: 'Niet goedgekeurd',
		status_WAITING_FOR_ACTION: 'Wacht op actie',
		status_WAITING_FOR_ACTION_BUSY: 'Wacht op actie',
		status_WAITING_FOR_SIGNATURES: 'Wacht op handtekeningen',
		status_WAITING_FOR_CHILD_DOCUMENTS: 'Wacht op subdocumenten',
		status_SIGNED: 'Ondertekend',
		status_SIGNED_WITH_DECLINATIONS: 'Ondertekend (met afwijzingen)',
		status_CLOSED: 'Afgesloten',
		status_DELETED: 'Verwijderd',
		status_DECLINED: 'Afgewezen',
		status_ATTACHMENT: 'Bijlage',
		status_ATTACHMENT_GENERIC: 'Generieke bijlage',
		status_EXPIRED: 'Verlopen',
		creator: 'Aanmaker',
		approvers: 'Goedkeurders',
		signers: 'Ondertekenaars',
		actions: 'Acties',
		upload: 'Opladen',
		createFromTemplate: 'Op basis van sjabloon',
		download: 'Download',
		downloadEvidenceReport: 'Bewijsrapport downloaden',
		downloadAttachment: 'Download bijlage',
		downloadDocuments: 'Documenten',
		downloadEvidenceReports: 'Bewijsrapporten',
		downloadAll: 'Documenten en bewijsrapporten',
		downloadNoResult: 'Geen downloadresultaat gevonden',
		edit: 'Bewerken',
		editAll: 'Bewerken (in bulk)',
		view: 'Bekijken',
		reminder: 'Stuur herinnering',
		deleteDocuments: 'Verwijder documenten',
		deleteDocumentsConfirm: 'Bent u zeker dat u deze documenten wil verwijderen? Deze documenten zullen verwijderd worden voor alle gebruikers.',
		signingOrderType_PARALLEL: 'Parallel',
		signingOrderType_SERIAL: 'Serieel',
		signingOrderType_KIOSK: 'Kiosk',
		approvalOrderType_PARALLEL: 'Parallel',
		approvalOrderType_SERIAL: 'Serieel',
		signOrderType: 'Ondertekenvolgorde',
		approvalOrderType: 'Goedkeuringsvolgorde',
		sign: 'Onderteken',
		signDocuments: 'Start ondertekenen (in bulk)',
		approve: 'Goedkeuren',
		approveDocuments: 'Start goedkeuring (in bulk)',
		allSelected: 'Alle {0} rijen zijn geselecteerd. $link;Selectie ongedaan maken$',
		selectAll: '{0} rijen geselecteerd. Om alle {1} rijen te selecteren, $link;klik hier$',
		selectionCountSingle: '1 rij geselecteerd',
		selectionCountMultiple: '{0} rijen geselecteerd',

		downloadDisclaimer: 'Niet alle documenten en bewijsrapporten kunnen worden gedownload. Dit kan het geval zijn voor documenten waarvoor u niet de juiste machtigingen heeft, voor vertrouwelijke documenten, documenten die niet volledig zijn ondertekend of nog in voorbereiding zijn.',

		generalOverviewHeader: 'Documenten',

		uploadHeader: 'Documenten opladen',
		uploadFolder: 'Map',
		uploadTemplate: 'Sjabloon',
		uploadTemplateApply: 'Sjabloon toepassen na uploaden',
		uploadFiles: 'Opladen bestanden',
		uploadSelectFiles: 'Selecteer één of meerdere bestanden op uw computer',
		uploadDragFilesHere: '...of sleep uw bestand(en) hier!',
		uploadItemName: 'Naam',
		uploadItemSize: 'Grootte',
		uploadItemState: 'Status',
		uploadItemState_NEW: 'Nieuw',
		uploadItemState_UPLOADING: 'Opladen...',
		uploadItemState_UPLOADED: 'Opgeladen',
		uploadItemState_ERROR: 'Fout',
		uploadItemState_SIZE_INVALID: 'Ongeldige grootte, maximale grootte is {0}',
		uploadItemDetails: 'Details',
		uploadItemDelete: 'Verwijderen',
		uploadItemEdit: 'Start met aanpassen',
		uploadNoFilesYet: 'Er zijn nog geen bestanden, druk op bovenstaande knop, of sleep bestanden hierboven om te beginnen',
		uploadFileConversionWarning: 'Opgelet: sommige bestandstypes (docx, pptx, ...) worden automatisch geconverteerd naar PDF-formaat. Het kan gebeuren dat bepaalde lettertypes/lay-outs niet naar behoren worden omgezet. Daarom: kijk het document na conversie steeds goed na of gebruik rechtstreeks bestanden in PDF-formaat.',
		uploadNormal: 'Opladen',
		uploadCollection: 'Dossier opladen',
		uploadAsCollection: 'Opladen als dossier',
		uploadOptionalCollectionName: 'Optionele naam van het dossier',
		uploadGoToCollection: 'Ga naar dossier',

		signingOverviewHeader: 'Documenten voor mij',
		signingOverviewTab_ALL: 'Alle documenten',
		signingOverviewTab_WAIT_FOR_SIGNATURE: 'Wacht op handtekening',
		signingOverviewTab_DOWNLOAD: 'Download documenten',

		approvalOverviewHeader: 'Documenten voor mij',
		approvalOverviewTab_ALL: 'Alle documenten',
		approvalOverviewTab_WAIT_FOR_APPROVAL: 'Wacht op goedkeuring',
		approvalOverviewTab_DOWNLOAD: 'Download documenten',

		createName: 'Document naam',
		createFolder: 'Document map',
		createTemplate: 'Bibliotheek sjabloon',
		selectTemplate: 'Selecteer sjabloon',
		create: 'Toevoegen',
		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Na het toevoegen kan het document verder geconfigureerd worden.',

		messageTimeline: 'Berichten voor dit document',
		messageDescription: 'Hier kunt u berichten sturen naar alle personen die relevant zijn in deze fase van het document. U kunt een bepaalde persoon taggen door @ te typen en die persoon uit de lijst te selecteren. Deze persoon ontvangt een melding via e-mail.',
	},

	visualSignatureOrParaph: {
		signatureDraw: 'Teken een nieuwe visuele handtekening in het gebied hieronder',
		signatureHeader: 'Handtekening',
		signatureCurrent: 'Uw huidige visuele handtekening',
		paraphDraw: 'Teken een nieuwe visuele paraaf in het gebied eronder',
		paraphHeader: 'Initialen',
		paraphCurrent: 'Uw huidige visuele initialen',
		draw: 'Teken',
		clear: 'Herbegin',
		upload: 'Bestand opladen (max 1MB)',
		delete: 'Verwijder',
	},

	approval: {
		headerSingle: 'Document goedkeuren',
		headerMultiple: 'Documenten goedkeuren',
		header: 'Documenten goedkeuren',
		instructionsSingle: '\'Goedkeuren\' geeft goedkeuring om het geselecteerd document door te sturen naar de ondertekenaars. In het document wordt een elektronisch zegel opgenomen. \'Afkeuren...\' zal het geselecteerde document weigeren. Dit kan de workflow stoppen.',
		instructionsMultiple: '\'Goedkeuren\' geeft goedkeuring om de geselecteerde documenten door te sturen naar de ondertekenaars. In de documenten wordt een elektronisch zegel opgenomen. \'Afkeuren...\' zal de geselecteerde documenten weigeren. Dit kan de workflow stoppen.',
		view: 'Bekijken',
		download: 'Downloaden',
		downloadAll: 'Alles downloaden',

		attachment: 'Bijlage',
		approvalRequestState_NEW: 'In voorbereiding',
		approvalRequestState_WAITING: 'In afwachting',
		approvalRequestState_APPROVED: 'Goedgekeurd',
		approvalRequestState_DECLINED: 'Afgekeurd',
		approvalRequestState_CLOSED: 'Afgesloten',
		approvalRequestState_EXPIRED: 'Verlopen',
		approvalRequestState_OBSOLETE: 'Obsoleet',

		approve: 'Goedkeuren',
		declineDialog: 'Afkeuren...',
		decline: 'Afkeuren',
		declineHeader: 'Goedkeuring afwijzen',
		declineWhyHeader: 'Uw verzoek tot goedkeuring afwijzen',
		declineWhyDetails: 'Door te bevestigen wordt u verzoek tot afkeuren geregistreerd. Afhankelijk van de instellingen van het document kan dit de workflow vroegtijdig beëindigen. Zo niet kunnen andere goedkeurders het document alsnog goedkeuren. Gelieve er rekening mee te houden dat deze actie onomkeerbaar is.',
		declineReasonHeader: 'Geef een reden',
		declineReasonDetails: 'Omschrijf kort uw reden om het document af te keuren. Dit veld is verplicht.',
		declineReasonLabel: 'Reden',

		everythingApprovedDeclined: 'Alles is goedgekeurd/afgekeurd',
		documentsDeclined: '{0} document(en) is(zijn) afgekeurd',
		documentsApproved: '{0} document(en) is(zijn) goedgekeurd',
		approvers: 'Goedkeurders',
		approversDescription: 'Lijst van goedkeurders voor document:',
		signers: 'Ondertekenaars',
		signersDescription: 'Lijst van ondertekenaars voor document:',
	},

	signing: {
		signHeaderSingle: 'Document ondertekenen',
		signHeaderMultiple: 'Documenten ondertekenen',
		declineHeaderSingle: 'Verzoek tot ondertekenen afwijzen',
		declineHeaderMultiple: 'Verzoek tot ondertekenen afwijzen',
		instructionsSingle: 'Hieronder vindt u het te ondertekenen document. Klik op \'Ondertekenen\' en volg de stappen.',
		instructionsMultiple: 'Hieronder vindt u de selectie van de te ondertekenen documenten. Klik op \'Ondertekenen\' en volg de stappen. Het oogje geeft aan welk document getoond wordt. U kan een ander document bekijken, door op de knop \'bekijken\' te klikken.',

		attachment: 'Bijlage',
		signRequestState_NEW: 'In voorbereiding',
		signRequestState_WAITING_FOR_SIGNATURE: 'In afwachting',
		signRequestState_SIGNED: 'Ondertekend',
		signRequestState_DECLINED: 'Afgewezen',
		signRequestState_CLOSED: 'Afgesloten',
		signRequestState_EXPIRED: 'Verlopen',
		signRequestState_OBSOLETE: 'Obsoleet',

		view: 'Bekijken',
		download: 'Downloaden',
		downloadAll: 'Alles downloaden',
		location: 'Uw handtekening zal hier geplaatst worden',
		locationParaph: 'Uw paraaf zal hier geplaatst worden',
		readAllDocuments: 'Ik heb alle documenten gelezen',

		signingMethodChoose: 'Kies een methode van ondertekening',
		signingMethod_ELECTRONIC_WITH_WITNESS_SEAL: 'Elektronische zegel',
		signingMethod_BELGIAN_EID: 'Belgische eID handtekening',
		signingMethod_ITSME: 'Gekwalificeerde itsme®',
		signingMethod_ITSME_ADVANCED: 'Geavanceerde itsme®',
		signingMethod_SMS_OTP: 'Ondertekenen met OTP SMS-code',
		signingMethod_EMAIL_OTP: 'Ondertekenen met OTP e-mail-code',
		signingMethod_HANDWRITTEN: 'Handgeschreven handtekening',
		signingMethodNoBulkSigning: 'Momenteel ondersteunt deze methode het ondertekenen van meer dan 70 documenten tegelijk niet. Enkel de eerste 70 beschikbare documenten zullen ondertekend worden.',
		signingMethodDisabledDevice: 'Deze methode is niet ondersteund op uw toestel',
		signingMethodDisabledFixedCapacity: 'Deze methode is niet ondersteund voor uw huidige hoedanigheid',
		signingMethodNoSameDocuments: 'Deze methode ondersteunt het gelijktijdig tekenen van hetzelfde document niet. Enkel de eerste instantie van het document zal worden ondertekend.',
		signingMethodDiffers: 'Sommige documenten hebben verschillende ondertekening methodes, mogelijks zijn meerdere onderteken sessies nodig.',

		acquiringLockSingle: 'Vergrendeling van het document',
		acquiringLockMultiple: 'Vergrendeling van de documenten',
		acquiringLockFailed: 'Niet alle documenten konden worden vergrendeld; iemand anders ondertekent momenteel.',
		acquiringLockQueued: 'Er zijn teveel verzoeken om te ondertekenen op dit moment. U wordt in een wachtrij geplaatst.',
		acquiringLockQueuedPosition: 'Positie in de wachtrij:',

		eidCardHeader: 'Plaats uw eID kaart in de kaartlezer',
		eidCardNotFound: 'Gelieve uw eID kaart in te voeren',
		eidCardFound: 'eID kaart gevonden',
		eidEnterPin: 'Geef uw pin in',
		eidMiddlewareNotRunning: 'U moet Dioss eID Middleware op uw computer installeren voordat u verder kunt gaan met de authenticatie van de eID-kaart.',
		eidMiddlewareDownloadHere: 'Download de Dioss eID Middleware hier:',
		eidWrongPin: 'Foutieve pin code',
		eidPinTimeoutOrCancel: 'U heeft de pincode niet op tijd ingevoerd of deze is geannuleerd',
		eidSignError: 'Er is een fout opgetreden bij het uitlezen van de eID-kaart. We raden u aan de kaart te verwijderen en het opnieuw te proberen, of een andere lezer te gebruiken.',
		eidExternalPinPad: 'Gelieve uw pin in te geven op de kaartlezer wanneer deze wordt gevraagd',
		eidExternalPinDialog: 'Gelieve uw pin in te geven in het externe pin input venster wanneer deze wordt gevraagd',

		smsOtpAuthenticate: 'Authenticeer uzelf',
		smsOtpAuthenticateDescription: 'Gelieve de laatste 4 cijfers van uw mobiel nummer in te voeren, zodat we uw identiteit kunnen bevestigen en u uw identificatiecode per SMS kunnen sturen.',
		smsOtpAuthenticateLabel: 'Laatste 4 cijfers van uw mobiele nummer',
		smsOtpAuthenticateSend: 'Verstuur identificatiecode',
		smsOtpAuthenticateSendAgain: 'Verstuur opnieuw',
		smsOtpAuthenticateSuccess: 'De identificatiecode werd verstuurd',
		smsOtpChallenge: 'Voer de identificatiecode in die u per SMS ontvangen heeft',
		smsOtpChallengeLabel: 'Uw identificatiecode',

		emailOtpChallenge: 'Klik op \'Versturen identificatiecode\' om de identificatiecode via e-mail te ontvangen en geef ze nadien in',
		emailOtpAuthenticateSend: 'Verstuur identificatiecode',
		emailOtpAuthenticateSendAgain: 'Verstuur opnieuw',
		emailOtpChallengeLabel: 'Uw identificatiecode',

		handwrittenSignature: 'Gelieve een handgeschreven handtekening te tekenen of op te laden',

		reasonHeader: 'Motiveer uw reden',
		reasonLabel: 'Reden',
		reasonLegalNoticeLabel: 'Legale melding',
		reasonLegalNoticeMandatoryText: 'U moet onderstaande legale melding overnemen, deze wordt toegevoegd aan de elektronische handtekening en is zichtbaar afgebeeld op het document.',
		reasonLegalNoticeText: 'Gelieve het volgende in te geven',
		reasonLegalNoticeMandatory: 'U moet een verplichte legale melding ingeven, deze wordt toegevoegd aan de elektronische handtekening en is zichtbaar afgebeeld op het document.',
		reasonOptional: 'U mag optioneel een reden ingeven, deze wordt dan toegevoegd aan de elektronische handtekening en is niet zichtbaar afgebeeld op het document.',
		reasonDisabledForSomeDocumentsContent: 'Voor de andere documenten is de optie om een motiverende reden toe te voegen uitgeschakeld. Uw reden zal niet worden toegepast op die documenten.',

		remarkHeader: 'Schrijf een opmerking op het document',
		remarkOptional: 'De opmerking wordt toegevoegd aan de handtekening (bijv. gelezen en goedgekeurd). Het ingeven van een opmerking is optioneel.',
		remarkLabel: 'Opmerking',
		remarkDisabledForSomeDocumentsContent: 'Voor sommige van de geselecteerde documenten is de optie om een opmerking toe te voegen uitgeschakeld. Uw opmerking zal niet worden toegepast op die documenten.',

		declineInfoSingle: 'Uw verzoek tot ondertekenen afwijzen',
		declineInfoMultiple: 'Uw verzoek tot ondertekenen afwijzen',
		declineInfoDetailsSingle: 'Door het het verzoek tot ondertekenen af te wijzen, kan u niet meer op uw beslissing terugkomen en kan u dit document later niet meer ondertekenen.',
		declineInfoDetailsMultiple: 'Door het het verzoek tot ondertekenen af te wijzen, kan u niet meer op uw beslissing terugkomen en kan u deze documenten later niet meer ondertekenen.',
		declineReasonDetails: 'Gelieve de reden van uw afwijzing hier mee te delen. Dit veld is verplicht.',
		declineReasonLabel: 'Reden',
		declineAttachment: 'Bestand toevoegen',
		declineReasonUploadError: 'Documentformaat niet geaccepteerd. Voeg een bestand bij van het volgende type: .pdf, .jpeg, .tiff, .doc, .docx .xls, .xlsx, .xlsb, .xlsb',

		itsmeSigningLabel: 'Ondertekenen met itsme',
		itsmeSigningDetails: 'Door te ondertekenen met itsme® wordt de handtekening gemaakt met uw persoonlijk itsme® certificaat. U heeft een itsme® account en de itsme® app nodig op uw telefoon. Als u uw itsme® identiteit nog niet heeft ingesteld, kan u dit eerst doen via onderstaande link: $link;https://www.itsme.be/;www.itsme.be$',
		itsmeForgetUserCodeDetails: 'Uw itsme® identiteit werd opgeslagen tijdens een vorige sessie. Dit laat u toe om de identificatie over te slaan. U kan de "itsme® identiteit wissen" knop klikken om deze te verwijderen. Itsme® zal u dan opnieuw vragen zich te identificeren. U krijgt de optie om die nieuwe identiteit op te slaan.',
		itsmeForgetUserCode: 'Itsme® identiteit wissen',
		itsmeLinkUserCodeDetails: 'Wanneer u voor het eerst met itsme® tekent in Quill zal u zich moeten identificeren. U kan ervoor kiezen om uw itsme® identiteit te laten onthouden en zo deze stap over te slaan voor latere handtekeningen. Het is mogelijk om deze link later te verwijderen. Voor handtekeningen via een gedeeld e-mailadres is het aangeraden deze optie uitgeschakeld te laten.',
		itsmeLinkUserCode: 'Mijn itsme® identiteit onthouden',
		itsmeDirections: 'U wordt doorgestuurd om de itsme® ondertekening uit te voeren. Gelieve dit venster open te houden tot het tekenen voltooid is. Na validatie wordt u teruggestuurd naar Quill en wordt de handtekening op het document geplaatst.',
		itsmeSessionBusyTitle: 'Ondertekenen met itsme® is bezig',
		itsmeSessionBusyStarted: 'U wordt doorgestuurd om de itsme® ondertekening uit te voeren...',
		itsmeSessionBusySigning: 'Uw gevalideerde handtekening wordt opgehaald van itsme® en geplaatst op het document...',
		itsmeRedirectError: 'Er was een probleem met het afhandelen van de ondertekensessie, probeer opnieuw...',

		redirectTitle: 'Doorsturen...',
		redirectDetails: 'U wordt binnen 5 seconden doorgestuurd. Klik $link;hier$ als dit niet automatisch gebeurt.',

		sign: 'Ondertekenen',
		decline: 'Afwijzen',
		forward: 'Doorsturen',
		signingBusy: 'Bezig met ondertekenen...',
		decliningBusy: 'Bezig met afwijzen...',

		everythingSignedDeclined: 'U hebt alle documenten verwerkt.',
		everythingSignedSingle: 'Alles is in orde. Uw handtekening is op het document aangebracht.',
		everythingSignedMultiple: 'Alles is in orde. Uw handtekening is op de documenten aangebracht.',
		everythingDeclined: 'Alles is afgewezen',
		documentsDeclinedSingle: 'Document is afgewezen',
		documentsDeclinedMultiple: '{0} documenten zijn afgewezen',
		documentsSignedSingle: 'Document is ondertekend',
		documentsSignedMultiple: '{0} documenten zijn ondertekend',
		approvers: 'Goedkeurders',
		approversDescription: 'Lijst van goedkeurders voor document:',
		documentDeleted: 'Document is verwijderd',

		capacities: 'Hoedanigheden',
		capacitiesInfo: 'Via onderstaande tabel kan u voor elk handtekenveld de hoedanigheid selecteren (of invullen). U kan deze ook blanco laten indien u geen hoedanigheid wil koppelen aan de digitale handtekening.',
		capacitiesDocumentName: 'Naam van het document',
		capacitiesPage: 'Pagina',
		capacitiesSelectedTitle: 'Geselecteerde hoedanigheid',
		capacitiesSelectedRemark: 'Geselecteerde opmerking',
		continue: 'Verdergaan',

		documents: 'Document selectie',
		documentsInfo: 'Hieronder kunnen de documenten geselecteerd worden die u wenst te {0}.',
		documentsInfoSign: 'ondertekenen',
		documentsInfoDecline: 'afwijzen',
		documentName: 'Document naam',

		forwardHeader: 'Documenten doorsturen',
		forwardSelectSignRequests: 'Selecteer welke verzoeken doorgestuurd moeten worden',
		forwardSelectTargetUser: 'Kies de persoon naar wie de verzoeken door te sturen',
		forwardUseDelegate: 'Gebruik de standaard regels voor afgevaardigden',
		forwardUserDetails: 'Stuur door naar een specifieke persoon',
		forwardEmail: 'Email',
		forwardFirstName: 'Voornaam',
		forwardLastName: 'Achternaam',
		forwardRemarkHeader: 'Boodschap voor de ontvanger van het verzoek (optioneel)',
		forwardRemark: 'Opmerking',
		forwardSuccess: 'Verzoeken succesvol doorgestuurd',

		formFields: 'Invulbare velden op dit document',
		formFieldsDocumentName: 'Naam',
		formFieldsFill: 'Vul in',
		formFieldsAttribute_FIRST_NAME: 'Voornaam',
		formFieldsAttribute_LAST_NAME: 'Achternaam',
		formFieldsAttribute_EMAIL: 'E-mail',
		formFieldsAttribute_MOBILE_NUMBER: 'Mobiel nummer (SMS OTP)',
		formFieldsAttribute_MEMO: 'Memo',
		formFieldsAttribute_META_FIELD: 'Meta attribuut',
		formFieldsGoTo: 'Ga naar veld op pagina',
		formFieldsRequired: 'Er zijn verplichte velden op (het) document(en) die nog ingevuld moeten worden.',
		formFieldsValidValueRequired: 'Er zijn invulbare velden die een ongeldige waarde hebben.',
		formFieldsValueInvalid: 'Ongeldige waarde',

		required: 'Verplicht*',

		visualSignatureSaveAsDefault: 'Opslaan als standaard handtekening',
		visualParaphSaveAsDefault: 'Opslaan als standaard paraaf',

		signingKioskHeader: 'Kiosk ondertekenaars',
		signingKioskInstructions: 'De onderstaande gebruikers maken deel uit van de kiosk documenten. Kies een gebruiker om de relevante documenten te bekijken en/of het ondertekenen te starten.',
		signingKioskPendingChangesLabel: 'U staat op het punt op de kiosk mode te verlaten. Bent u zeker dat u wilt verdergaan?',
		signingKioskPendingChangesSaveAndLeaveLabel: 'Verdergaan',

		guestAccessRenew: 'Vernieuwen',
		guestAccessExpired: 'De link om het document te ondertekenen is verlopen. Klik op de knop "Vernieuwen" hieronder om een nieuwe link per e-mail te ontvangen. De nieuwe link is geldig voor {{duration}}',
		guestAccessRenewedSuccessfully: 'Een nieuwe link voor toegang tot het document is via e-mail verzonden',

		signerDataMismatchUnexpected: 'Onverwachte {{attribute}}',
		signerDataMismatchFirstName: 'voornaam',
		signerDataMismatchLastName: 'achternaam',
		signerDataMismatchBirthday: 'geboortedatum',
		signerDataMismatchNationalIdentificationNumber: 'rijksregisternummer',
		signerDataMismatchExpected: 'Verwacht',
		signerDataMismatchGiven: 'Gegeven',
	},

	editor: {
		editDocumentHeader: 'Document bewerken',
		editTemplateHeader: 'Sjabloon bewerken',
		editCollectionHeader: 'Dossier bewerken',
		editVirtualCollectionHeader: 'Meerdere documenten bewerken',

		collectionAllDocuments: 'Alle documenten in ',

		actionLogs: 'Document acties',
		save: 'Opslaan',
		send: 'Versturen',
		sign: 'Ondertekenen',
		sendAndSign: 'Versturen en tekenen',
		sent: 'Document is verstuurd',
		sendingFailed: 'Document kon niet verstuurd worden',
		sentButNothingToSign: 'Document is verstuurd. Er is niets te tekenen.',
		close: 'Intrekken',
		restart: 'Herstarten',
		restarted: 'Document is herstart',
		delete: 'Verwijderen',
		closeConfirm: 'Weet u zeker dat u de vraag om dit document goed te keuren/ondertekenen wilt intrekken? Als gevolg hiervan wordt dit document voor alle goedkeurders/ondertekenaars gesloten.',
		documentDeleteConfirm: 'Bent u zeker dat u dit document wil verwijderen? Het geselecteerd document zal voor alle gebruikers verwijderd worden.',
		documentDeleteCollectionConfirm: 'Bent u zeker dat u dit dossier in zijn geheel wil verwijderen? De geselecteerde documenten zullen voor alle gebruikers verwijderd worden.',
		templateDeleteConfirm: 'Bent u zeker dat u dit sjabloon wil verwijderen?',
		tab_OVERVIEW: 'Overzicht',
		tab_GENERAL: 'Algemeen',
		tab_APPROVERS: 'Goedkeurders',
		tab_SIGNERS: 'Ondertekenaars',
		tab_MULTI_SIGN: 'Multi-Sign',
		tab_POST_ACTIONS: 'Acties na ondertekenen',
		signingOrder_SERIAL: 'Serieel',
		signingOrder_PARALLEL: 'Parallel',
		signingOrder_KIOSK: 'Kiosk',
		approveOrder_SERIAL: 'Serieel',
		approveOrder_PARALLEL: 'Parallel',
		orderUp: 'Omhoog',
		orderDown: 'Omlaag',

		overviewApprovers: 'Goedkeurders',
		overviewApproversDescription: 'Lijst van goedkeurders',
		overviewSigners: 'Ondertekenaars',
		overviewSignersDescription: 'Lijst van ondertekenaars',
		overviewMultisigners: 'Multi-sign',
		overviewMultisignersDescription: 'Lijst van ondertekenaars',
		overviewDocuments: 'Documenten',
		overviewDocumentsDescription: 'Lijst van subdocumenten',

		generalAttachment: 'Dit document is een bijlage',
		generalAttachmentHasIncompleteSignersWarning: 'Dit document is niet ondertekend door alle ondertekenaars. Als u dit document als bijlage configureert, gaan deze ondertekenaars verloren.',
		generalDescription: 'Documentomschrijving',
		generalDescriptionMandatory: 'De documentomschrijving is verplicht',
		generalFolder: 'Map',
		generalConfidentiality: 'Vertrouwelijkheid',
		generalConfidentialitySet: 'Dit document mag enkel zichtbaar zijn voor de aanmaker, de ondertekenaars en de goedkeurders.',
		generalConfidentialWarningWhenNotCreator: 'Let op: u bent niet de eigenaar van het document; wanneer u het document vertrouwelijk maakt, zal u geen toegang meer hebben tot dit document.',
		generalPdf: 'PDF',
		generalPdfRecreated: 'Er waren enkele compatibiliteitsproblemen met de structuur van dit PDF-document die automatisch zijn gecorrigeerd.',
		generalPdfADetected: 'Gedetecteerde PDF/A conformiteit',
		generalPdfADetectedNone: 'Geen',
		generalPdfADetectedNoneItsme: 'Merk op dat één of meer handtekeningen kunnen geplaatst worden of zijn geplaatst met itsme® maar het document is niet PDF/A conform. itsme® raadt PDF/A conformiteit aan.',
		generalMotivation: 'Motivatie',
		generalOverrideLegalNoticeSettings: 'Standaard motivatie instellingen overschrijven.',
		generalReasonAvailable: 'Ondertekenaar kan een motiverende reden toevoegen',
		generalReasonLegalNoticeMandatory: 'Maak dit een verplichte wettelijke mededeling voor de gebruiker in te voeren. Dit verschijnt ook visueel op het document.',
		generalReasonLegalNoticeText: 'Wettelijke mededeling',
		generalExpiration: 'Verloop',
		generalExpirationOverride: 'Standaard verloop instellingen overschrijven',
		generalExpirationEnable: 'Verloop van goedkeuringen en handtekeningen inschakelen',
		generalExpirationDays: 'dag(en) voordat onvoltooide goedkeuringen en handtekeningen vervallen',
		generalForwarding: 'Doorsturen',
		generalAllowForwarding: 'Verzoeken mogen doorgestuurd worden',
		generalTemplatePages: 'Sjabloon pagina\'s',
		generalTemplateOptions: 'Sjabloon opties',
		generalDecline: 'Afwijzen',
		generalOverrideDeclineEnabled: 'Standaard afwijzingsinstellingen overschrijven',
		generalAllowDecline: 'Sta afwijzen toe',

		approvalOrder: 'Goedkeuringsvolgorde',
		approvalList: 'Lijst van goedkeurders',
		approvalListEmpty: 'Geen goedkeurders geconfigueerd',
		approvalDelete: 'Verwijder deze goedkeurder',
		approvalAddApprover: 'Goedkeurder toevoegen',
		approvalAddSignerGroup: 'Goedkeurdersgroep toevoegen',
		approvalImportApprovers: 'Goedkeurders importeren',
		approvalDeclineReason: 'Reden van afwijzing',
		approvalSeal: 'Goedkeuring met elektronische zegel',
		approvalRequired: 'Verplichte goedkeuring',
		approvalNumberApproversToDecide: 'Aantal goedkeurders die moeten beslissen (goed- of afkeuren)',
		approvalNumberApproversToDecideError: 'Dit aantal moet groter of gelijk zijn aan het aantal verplichte goedkeurders, maar kleiner of gelijk aan het totaal aantal goedkeurders',
		approvalNumberApproversToApprove: 'Aantal goedkeurders die het document moeten goedkeuren',
		approvalNumberApproversToApproveError: 'Dit aantal moet groter of gelijk zijn aan het aantal verplichte goedkeurders, maar kleiner of gelijk aan het aantal goedkeurders die moeten beslissen',
		approvalNumberSerial: 'Bij seriele volgorde is het aantal niet instelbaar.',

		signingList: 'Lijst van ondertekenaars',
		signingListEmpty: 'Geen ondertekenaars geconfigureerd',
		signingPage: 'Pagina',
		signingSignatureSettings: 'Configureer',
		signingDelete: 'Verwijder deze ondertekenaar',
		signingNotPlaced: 'Sommige handtekenvelden zijn nog niet op het document geplaatst, u kan dit doen door het veld van de ondertekenaar te slepen naar de correcte locatie op een specifieke pagina',
		signingAddSigner: 'Ondertekenaar toevoegen',
		signingAddSignerGroup: 'Ondertekenaarsgroep toevoegen',
		signingImportSigners: 'Importeer ondertekenaars',
		signingChangeSignatureTypes: 'Pas de ondertekening methoden aan',
		signingBulkSignatureTypes: 'Pas de ondertekening methoden voor alle ondertekenaars aan',
		signingSignatureTypeError: 'Gelieve een onderteken methode te kiezen',
		signingSmsOtpNumberError: 'Kies GSM-nummer voor code met SMS',
		signingSmsOtpKnownNumber: 'Gebruik het nummer dat gekend is voor deze ondertekenaar, dit nummer eindigt op $SUFFIX.',
		signingSmsOtpEnterNumber: 'Gebruik een nieuw nummer, dit nummer wordt bewaard zodat het later opnieuw gebruikt kan worden',
		signingMethod_ELECTRONIC_WITH_WITNESS_SEAL: 'Elektronische zegel',
		signingMethod_BELGIAN_EID: 'Belgische eID handtekening',
		signingMethod_ITSME: 'Gekwalificeerde itsme® handtekening',
		signingMethod_ITSME_ADVANCED: 'Geavanceerde itsme® handtekening',
		signingMethod_SMS_OTP: 'SMS OTP handtekening',
		signingMethod_EMAIL_OTP: 'E-mail OTP handtekening',
		signingMethod_HANDWRITTEN: 'Handgeschreven handtekening',
		signingSignatureFieldOptions: 'Handtekenveld opties',
		signingSignatureFieldDelete: 'Verwijder',
		signingSignatureFieldDeleteAll: 'Verwijder alles',
		signingSignatureFieldSettings: 'Instellingen',
		signingParaphField: 'Paraaf veld',
		signingParaphFieldDescription: 'Paraaf velden kunnen gedupliceerd worden naar andere pagina\'s (zonder ondertekenveld), door er rechts op te klikken en \'dupliceer naar andere pagina\'s\' te selecteren.',
		signingParaphFieldPlaceOnDocument: 'Sleep op een pagina om het paraaf veld te plaatsen.',
		signingParaphFieldDeleteAll: 'Verwijder alle paraaf velden',
		signingParaphFieldDuplicate: 'Dupliceer naar andere pagina\'s',
		signingExtraSignatureField: 'Extra visuele handtekenvelden',
		signingExtraSignatureFieldPage: 'Pagina',
		signingExtraSignatureFieldPlaceOnDocument: 'Sleep dit veld op een pagina om een extra visueel handtekenveld te plaatsen.',
		signingFormField: 'Invulbare velden',
		signingFormFieldPage: 'Pagina',
		signingFormFieldPlaceOnDocument: 'Sleep dit veld op een pagina om een nieuw invulbaar veld toe te voegen aan het document.',
		signingDistributeSignerInCollection: 'Zet dit veld in alle documenten binnen het dossier',
		signingCapacity: 'Hoedanigheid van ondertekenaar',
		signingPlaceholder: 'Toe te kennen ondertekenaar',
		signingPlaceholderAdd: 'Plaatshouder voor ondertekenaar toevoegen',
		signingChooseSigner: 'Ondertekenaar kiezen',
		signingOrderGroupSettings: 'Instellingen voor ondertekenaarsgroepen',
		signingOrderGroupDrag: 'Sleep om de volgorde te veranderen of om ondertekenaars te groeperen',
		signingOrderGroupDisband: 'Groep opheffen',
		signingOrderGroupMinimalNumberOfSignersDescription: 'Minimum aantal ondertekenaars. Dit aantal moet groter of gelijk zijn aan het aantal verplichte ondertekenaars en ook minstens 1 bedragen.',
		signingOrderGroupMinimalNumberOfSigners: 'Aantal ondertekenaars',
		signingOrderGroupRequiredSigners: 'Verplichte ondertekenaars',
		signingFieldPlaceholder_SIGNATURE: 'Handtekening placeholder',
		signingFieldPlaceholder_PARAPH: 'Paraaf placeholder',
		signingFieldPlaceholder_FORM: 'Invulveld placeholder',
		signingKioskMode: 'Kiosk mode',
		signingKioskModeConfirm: 'In kiosk mode kan er maar 1 geregistreerde gebruiker als ondertekenaar toegevoegd worden, het aantal gastgebruikers is onbeperkt. Goedkeurders en multi-ondertekenaars zullen automatisch verwijderd worden. Bent u zeker dat u wilt doorgaan?',
		signingKioskModeRegisteredUserError: 'Er zijn meerdere geregistreerde gebruikers toegevoegd op het kiosk document. Er kan maximaal 1 geregistreerde gebruiker (en meerdere gastgebruikers) toegevoegd worden.',
		signingExpiry: 'Geldigheid gastlink',
		signingExpiryDescription: 'Hier kunt u de tijd in minuten configureren waarin de gastlink na verzending geldig blijft. Wanneer deze is verlopen, moet de gastgebruiker een nieuwe aanvragen.',
		signingExpiryError: 'De minimum vervaltijd is 10 minuten en de maximum vervaltijd is 1440 minuten.',
		signingExpiryReset: 'Geen vervaltijd',

		formFieldTypeDescription: 'Type veld',
		formFieldType_TEXT: 'Tekst',
		formFieldType_CHECKBOX: 'Checkbox',
		formFieldType_DATE: 'Datum',
		formFieldType_NUMERIC: 'Numeriek',
		formFieldType_ATTRIBUTE: 'Attribuut van ondertekenaar',
		formFieldAttributeType_FIRST_NAME: 'Voornaam',
		formFieldAttributeType_LAST_NAME: 'Achternaam',
		formFieldAttributeType_EMAIL: 'E-mail',
		formFieldAttributeType_MOBILE_NUMBER: 'Mobiel nummer (SMS OTP)',
		formFieldAttributeType_MEMO: 'Memo',
		formFieldAttributeType_META_FIELD: 'Meta attribuut',
		formFieldAttributeTypeUsage: 'De waarde van dit attribuut wordt automatisch toegepast op het moment van ondertekenen. De ondertekenaar kan de waarde niet aanpassen',
		formFieldAttributeTypeDescription: 'Type attribuut',
		formFieldNameDescription: 'Naam van het veld',
		formFieldName: 'Naam',
		formFieldMetaAttributeUnknown: 'Onbekend attribuut, voeg het toe aan de meta-attributen van de gebruiker vooraleer te verzenden',
		formFieldValueDescription: 'Waarde van het veld',
		formFieldValue: 'Waarde',
		formFieldOptions: 'Opties',
		formFieldEditable: 'Aanpasbaar door ondertekenaar',
		formFieldRequired: 'Verplicht',
		formFieldStyle: 'Stijl opties',
		formFieldValidator: 'Validator',
		formFieldValueInvalid: 'Ongeldige waarde voor validator',

		emailMessageHeaderSigners: 'E-mailbericht aan de ondertekenaars',
		emailMessageForUserHeaderSigners: 'E-mailbericht aan ondertekenaar',
		emailMessageHeaderApprovers: 'E-mailbericht aan de goedkeurders',
		emailMessageForUserHeaderApprovers: 'E-mailbericht aan goedkeurder',
		emailMessageDescriptionSigners: 'Elke ondertekenaar ontvangt een e-mail met het verzoek om het document te ondertekenen. Het onderstaande gepersonaliseerde bericht in de voorkeurstaal van de ondertekenaar wordt in de e-mail opgenomen. $signer fungeert als tijdelijke aanduiding en wordt vervangen door de daadwerkelijke naam van de ondertekenaar waarnaar deze e-mail wordt gestuurd. $document wordt vervangen door de naam van het document. Bewerk het bericht als u de voorkeur geeft aan een ander bericht.',
		emailMessageForUserDescriptionSigners: 'Elke ondertekenaar ontvangt een e-mail met het verzoek om het document te ondertekenen. Het onderstaande gepersonaliseerde bericht in de voorkeurstaal van de ondertekenaar wordt in de e-mail opgenomen. $signer fungeert als tijdelijke aanduiding en wordt vervangen door de daadwerkelijke naam van de ondertekenaar waarnaar deze e-mail wordt gestuurd. $document wordt vervangen door de naam van het document. Bewerk het bericht als u het bericht wil personaliseren voor de geselecteerde gebruiker. Dit heeft geen invloed op gepersonaliseerde berichten voor andere ondertekenaars.',
		emailMessageDescriptionApprovers: 'Elke goedkeurder ontvangt een e-mail met het verzoek om het document te ondertekenen. Het onderstaande gepersonaliseerde bericht in de voorkeurstaal van de goedkeurder wordt in de e-mail opgenomen. $approver fungeert als tijdelijke aanduiding en wordt vervangen door de daadwerkelijke naam van de goedkeurder waarnaar deze e-mail wordt gestuurd. $document wordt vervangen door de naam van het document. Bewerk het bericht als u de voorkeur geeft aan een ander bericht.',
		emailMessageForUserDescriptionApprovers: 'Elke goedkeurder ontvangt een e-mail met het verzoek om het document te ondertekenen. Het onderstaande gepersonaliseerde bericht in de voorkeurstaal van de goedkeurder wordt in de e-mail opgenomen. $approver fungeert als tijdelijke aanduiding en wordt vervangen door de daadwerkelijke naam van de goedkeurder waarnaar deze e-mail wordt gestuurd. $document wordt vervangen door de naam van het document. Bewerk het bericht als u het bericht wil personaliseren voor de geselecteerde gebruiker. Dit heeft geen invloed op gepersonaliseerde berichten voor andere ondertekenaars.',
		emailMessageResetCurrentLang: 'Standaard bericht ($LANGUAGE)',
		emailMessageResetAll: 'Standaard bericht (elke taal)',
		emailMessageReset: 'Standaard bericht',

		multiSignDescription: 'Voor elke hieronder gedefinieerde ondertekenaar wordt een nieuwe kopie van het document gemaakt. De ondertekenaar moet tekenen in het tekenveld.',
		multiSignDefaultSignatureTypes: 'Selecteer de standaard ondertekening methoden',
		multiSignPlaceSignatureField: 'Plaats het Multi-Sign veld op het document',
		multiSignDownload: 'Downloaden',

		postActionsDocumentEmailDistributionList: 'Document e-mail distributielijst na ondertekenen (zonder bewijsrapport)',
		postActionsDocumentAndEvidenceReportEmailDistributionList: 'Document e-mail distributielijst na ondertekenen (met bewijsrapport)',
		postActionsDistributionListAddExistingUser: 'E-mail toevoegen op basis van een gekende gebruiker',
		postActionsDistributionListCustom: 'Aangepaste e-mail toevoegen',
		postActionsDistributionListAdd: 'Toevoegen',
		postActionsDistributionListAddEvidenceReport: 'Bewijsrapport toevoegen aan mail',
		postActionsDistributionListRemoveDistributionList: 'Lijst wissen',

		actionLogPerformedAt: 'Tijdstip',
		actionLogIpAddress: 'IP-adres',
		actionLogEvent: 'Gebeurtenis',
		actionLog_CREATED: 'Document aangemaakt door {0}',
		actionLog_NAME_MODIFIED: 'Document hernoemd van {0} naar {1} door {2}',
		actionLog_SAVED: 'Document opgeslagen door {0}',
		actionLog_VIEWED: 'Document bekeken door {0}',
		actionLog_DOWNLOADED: '{1} gedownload door {0}',
		actionLog_DELEGATED_TO_APPROVER: 'Document doorverwezen van {0} naar {1} ter goedkeuring',
		actionLog_DELEGATED_TO_APPROVER_POWER_OF_ATTORNEY: 'Document doorverwezen van {0} naar {1} ter goedkeuring (ondersteund door volmacht {2})',
		actionLog_INVITED_TO_APPROVE: 'Uitnodiging om goed te keuren verstuurd naar {0} door {1}',
		actionLog_APPROVED: 'Document goedgekeurd door {0}',
		actionLog_NOT_APPROVED: 'Document afgekeurd door {0} met als reden {1}',
		actionLog_APPROVER_REMOVED: 'Goedkeurder {0} is verwijderd van dit document',
		actionLog_DELEGATED_TO_SIGNER: 'Document doorverwezen van {0} naar {1} ter ondertekening',
		actionLog_DELEGATED_TO_SIGNER_POWER_OF_ATTORNEY: 'Document doorverwezen van {0} naar {1} ter ondertekening (ondersteund door volmacht {2})',
		actionLog_INVITED: 'Uitnodiging om te tekenen verstuurd naar {0} door {1}',
		actionLog_INVITED_FOR_KIOSK: '{0} uitgenodigd voor kiosk ondertekening door {1}',
		actionLog_SIGNED: 'Document ondertekend door {0} met {1}',
		actionLog_STOPPED: 'Document ondertekenen gestopt door {0}',
		actionLog_RESTARTED: 'Document herstart door {0}',
		actionLog_DELETED: 'Document verwijderd door {0}',
		actionLog_SIGNING: 'Document ondertekenen gestart door {0}',
		actionLog_SIGNING_FAILED_USER_MISMATCH: 'Document ondertekenen mislukt: verwachte data van ondertekenaar {0} niet correct',
		actionLog_DECLINED: 'Ondertekenen afgewezen door {0} met als reden {1}',
		actionLog_REMINDER_SENT: 'Herinnering om te tekenen verstuurd naar {0} door {1}',
		actionLog_EXPIRED: 'Document is verlopen',
		actionLog_CONVERTED_TO_ATTACHMENT: 'Document omgezet naar bijlage tijdens herstart door {0}',
		actionLog_CHILD_DOCUMENT_CREATED: 'Document {0} aangemaakt op basis van {1}',
		actionLog_CHILD_DOCUMENT_SIGNED: 'Subdocument {0} ondertekend door {1} met {2}',
		actionLog_CHILD_DOCUMENT_DECLINED: 'Subdocument {0} afgewezen door {1}',
		actionLog_CHILD_DOCUMENT_APPROVED: 'Subdocument {0} goedgekeurd',
		actionLog_CHILD_DOCUMENT_NOT_APPROVED: 'Subdocument {0} afgekeurd',
		actionLog_CHILD_DOCUMENT_CONVERTED_TO_ATTACHMENT: 'Subdocument {0} omgezet naar bijlage',
		actionLog_CHILD_DOCUMENT_DELEGATED_TO_SIGNER: 'Subdocument {0} doorverwezen van {1} naar {2} ter ondertekening',
		actionLog_CHILD_DOCUMENT_DELEGATED_TO_SIGNER_POWER_OF_ATTORNEY: 'Subdocument {0} doorverwezen van {1} naar {2} ter ondertekening (ondersteund door volmacht {3})',
		actionLog_CHILD_DOCUMENT_DELEGATED_TO_APPROVER: 'Subdocument {0} doorverwezen van {1} naar {2} ter goedkeuring',
		actionLog_CHILD_DOCUMENT_DELEGATED_TO_APPROVER_POWER_OF_ATTORNEY: 'Subdocument {0} doorverwezen van {1} naar {2} ter goedkeuring (ondersteund door volmacht {3})',
		actionLog_TEMPLATE_CREATED: 'Sjabloon aangemaakt door {0}',
		actionLog_TEMPLATE_NAME_MODIFIED: 'Sjabloon hernoemd van {0} naar {1} door {2}',
		actionLog_TEMPLATE_SAVED: 'Sjabloon opgeslagen door {0}',
		actionLog_DOCUMENT_REPLACED: 'Document vervangen met {0} door {1}',
		actionLog_CHILD_DOCUMENT_REPLACED: 'Subdocument {0} vervangen met {1} door {2}',
		actionLog_CHILD_DOCUMENT_ADDED_TO_COLLECTION: 'Subdocument {0} toegevoegd door {1}',
		actionLog_CHILD_DOCUMENT_REMOVED_FROM_COLLECTION: 'Subdocument {0} verwijderd door {1}',

		downloadArtifact_DOCUMENT: "Document",
		downloadArtifact_EVIDENCE_REPORT: "Bewijsrapport",
		downloadArtifact_PREVIOUS_VERSIONS: "Document versies",
		downloadArtifact_ATTACHMENT: "Bijlages",
		downloadArtifact_DECLINE_ATTACHMENT: "Bijlages (afwijzing)",
		downloadArtifact_DELEGATE_ATTACHMENT: "Bijlages (afgevaardigde)",

		templateApply: 'Sjabloon toepassen op document',
		templateSave: 'Bewaar sjabloon op basis van document',
		templateApplySignersApproversPlaced: 'Sjabloon is toegepast op het document. {0} ondertekenaar(s) en {1} goedkeurder(s) toegevoegd',
		templateNameDescription: 'Geef het sjabloon een naam',
		templateName: 'Naam',
		templateNameSaveDescription: 'Bewaar dit sjabloon',
		templateNameSaveLabel: 'Het opslaan van een sjabloon wijzigt niks aan uw bestaande document. Vergeet dus zeker niet om uw huidige document ook nog te bewaren of te verzenden.',
		templateSaved: 'Sjabloon is bewaard',

		templateOptions: 'Template opties',
		templateOption: 'Optie',
		templateOption_CONFIDENTIALITY: 'Vertrouwelijkheid',
		templateOption_APPROVERS: 'Goedkeurders',
		templateOption_APPROVERS_MAIL: 'Goedkeurders e-mailbericht',
		templateOption_SIGNERS: 'Ondertekenaars',
		templateOption_SIGNERS_MAIL: 'Ondertekenaars e-mailbericht',
		templateOption_POSTSIGN_DISTRIBUTION_LIST_WITHOUT_EVIDENCE_REPORT: 'E-mail distributielijst na ondertekenen (zonder bewijsrapport)',
		templateOption_POSTSIGN_DISTRIBUTION_LIST_WITH_EVIDENCE_REPORT: 'E-mail distributielijst na ondertekenen (met bewijsrapport)',
		templateWarningApproversInCollectionChild: 'Alle bestaande goedkeurders in subdocumenten worden verwijderd wanneer goedkeurders vanuit een sjabloon op een collectie worden toegepast',
		templateWarningSignersInCollectionChild: 'Alle bestaande ondertekenaars in subdocumenten worden verwijderd wanneer ondertekenaars vanuit een sjabloon op een collectie worden toegepast',

		changeDocumentTitle: 'Veranderen van document',
		changeDocumentUpload: 'Laad document op',
		changeDocumentSignaturesWarning: 'Het huidige document bevat één of meerdere handtekeningen. Deze zullen verloren gaan wanneer een nieuw document opgeladen wordt.',

		documentCollectionOverview: 'Dossier documenten',
		documentChildAdd: 'Document toevoegen',
		documentNameEdit: 'Naam aanpassen',
		documentChange: 'Document veranderen',
		documentChangePendingChanges: 'Er zijn niet opgeslagen wijzigingen op dit document. Weet u zeker dat u van document wil veranderen?',
		documentChangePendingChangesIgnoreChanges: 'Verander document, negeer wijzigingen',
		documentChangePendingChangesSave: 'Verander document, bewaar wijzigingen',
		documentRemove: 'Verwijder',

		fieldStyleTitle: "Stijl instellingen",
		fieldStyleFontSize: "Lettergrootte",
		fieldStyleBackgroundColor: "Achtergrondkleur",
		fieldStyleBackgroundOpacity: "Dekking achtergrond",
		fieldStyleAlignment: "Tekstpositie",
		fieldStyleTextAlignHorizontalLeft: 'Links (horizontaal)',
		fieldStyleTextAlignHorizontalCenter: 'Midden (horizontaal)',
		fieldStyleTextAlignHorizontalRight: 'Rechts (horizontaal)',
		fieldStyleTextAlignVerticalTop: 'Boven (verticaal)',
		fieldStyleTextAlignVerticalCenter: 'Midden (verticaal)',
		fieldStyleTextAlignVerticalBottom: 'Onder (verticaal)',
	},

	signerGroup: {
		header: 'Ondertekenaarsgroepen',
		create: 'Ondertekenaarsgroep aanmaken',
		name: 'Naam',
		description: 'Omschrijving',
		actions: 'Acties',

		createName: 'Naam van de ondertekenaarsgroep',
		createDescription: 'Omschrijving van de ondertekenaarsgroep',
		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Na het aanmaken van de ondertekenaarsgroep kunnen leden toegevoegd worden.',

		settings: 'Instellingen',
		settingsNameDescription: 'Ondertekenaarsgroep naam en omschrijving',
		settingsFinish: 'Alles ingevuld?',
		settingsFinishDescription: 'Druk op opslaan om de wijzigingen te behouden',
		settingsSave: 'Opslaan',

		users: 'Leden',
		userName: 'Naam',
		userEmail: 'E-mail',
		userAdd: 'Lid toevoegen',
		userRemove: 'Lid verwijderen',
		userRemoveConfirm: 'Bent u zeker dat u dit lid wil verwijderen?',
		userState: 'Status',

		userSelect: 'Selecteer lid/leden om toe te voegen',
		usersDescription: 'Leden aan/van deze groep toevoegen of verwijderen. Merk op dat deze veranderingen onmiddelijk toegepast zullen worden.',

		delete: 'Verwijderen',
		deleteConfirm: 'Bent u zeker dat u deze ondertekenaarsgroep wil verwijderen?',
		deleted: 'Ondertekenaarsgroep verwijderd',
	},

	oidcInstance: {
		global: 'algemeen',
		company: 'bedrijf',
		oidcInstancesFor: 'OIDC configuraties voor',

		generalConfiguration: 'Algemene OIDC configuraties',
		description: 'Deze algemene configuraties worden gebruikt wanneer er geen bedrijfsspecifieke OIDC configuratie toepasbaar is.',
		companyOverview: 'OIDC configuratie overzicht per bedrijf',
		companyOverviewDescription: 'Overzicht van alle OIDC configuraties ingesteld door bedrijven. Configuratie wordt gedaan op de instellingen pagina van het bedrijf.',
		settings: 'Instellingen',

		name: 'Naam',
		companyName: 'Bedrijf',
		serverUrl: 'Server URL',
		clientId: 'Client ID',
		clientSecret: 'Client secret',
		autoCreate: 'Automatisch aanmaken',
		actions: 'Acties',

		create: 'Aanmaken',
		edit: 'Aanpassen',
		delete: 'Verwijderen',
		save: 'Opslaan',

		createName: 'Naam van de OIDC configuratie',
		serverConfiguration: 'Server configuratie',
		autoCreateDescription: 'Sta automatische aanmaak van nieuwe gebruikers toe',
		logo: 'Logo',
		logoDescription: 'Wijzigingen aan het logo zullen onmiddellijk toegepast worden. Wordt getoond als 24 x 24 px afbeelding.',
		logoUpload: 'Upload nieuw logo (max 1MB)',
		logoDelete: 'Verwijder logo',

		createFinish: 'Alles ingevuld?',
		createFinishDescription: 'Na het aanmaken kan additionele configuratie gedaan worden.',
		settingsFinishDescription: 'Druk op opslaan om de wijzigingen te behouden',

		deleteConfirm: 'Bent u zeker dat u deze OIDC configuratie wil verwijderen?',
		deleted: 'OIDC configuratie verwijderd',
	},

	connector: {
		connectorsFor: 'Connectoren voor',
		add: 'Voeg connector toe',
		edit: 'Aanpassen',
		delete: 'Verwijderen',
		deleteConfirm: 'Ben je zeker dat je deze connector wilt verwijderen?',
		connectorType_SFTP: 'SFTP',
		connectorType_SHAREPOINT: 'Sharepoint',
		connectorType_GOOGLE_DRIVE: 'Google Drive',
		connectorType_AZURE_FILES: 'Azure Files',

		sftpConfig: 'SFTP configuratie',
		host: 'Naam van de host',
		port: 'Poort',
		password: 'Paswoord',

		sharepointConfig: 'Sharepoint configuratie',
		tenant: 'Tenant',
		siteId: 'Site ID',
		clientId: 'Client ID',
		clientSecret: 'Client secret',
		uploadFileNameDocumentIdPrefix: 'Prefix document ID',
		uploadFileNameDocumentIdPrefixDescription: 'Prefix bestandsnaam van geüpload document met de document ID',

		googleDriveConfig: 'Google Drive configuratie',
		googleDriveServiceAccountJson: 'JSON bestand van het service account',
		googleDriveFolder_UPLOAD_COMPLETED_DOCUMENTS: 'De Google Drive folder ID naar waar afgewerkte bestanden moeten geüpload worden',
		googleDriveFolder_DOWNLOAD_DOCUMENTS: 'De Google Drive folder ID waarvan bestanden moeten gedownload worden',

		azureFilesConfig: 'Azure Files configuratie',
		azureFilesFolderName_UPLOAD_COMPLETED_DOCUMENTS: 'Azure Files folder naam naar waar afgewerkte bestanden moeten geüpload worden (laat leeg voor root folder)',
		azureFilesFolderName_DOWNLOAD_DOCUMENTS: 'Azure Files folder waarvan bestanden moeten gedownload worden (laat leeg voor root folder)',
		remoteFolder: 'Externe folder',
		storageAccount: 'Storage account',
		shareName: 'Share naam',

		testConnection: 'Test verbinding',
		test_AUTHENTICATION_FAILED: 'Authenticatie gefaald',
		test_NOT_ALLOWED: 'Permissie geweigerd',
		test_CONNECTION_REFUSED: 'Verbinding geweigerd',
		test_CANNOT_CONNECT: 'Verbinding kon niet worden gemaakt',
		test_CONNECTOR_NOT_FOUND: 'Verbinding niet gevonden',
		test_INVALID_CLIENT_ID: 'Ongeldige client ID',
		test_INVALID_CLIENT_SECRET: 'Ongeldige client secret',
		test_INVALID_CONFIG: 'Ongeldige configuratie',
		test_INVALID_TENANT: 'Ongeldige tenant ID',
		test_SUCCESS: 'Verbinding geslaagd',
		test_TOO_MANY_ATTEMPTS: 'Teveel test pogingen, probeer binnen 1 minuut opnieuw',
		test_UNKNOWN_HOST: 'Host niet gevonden',

		actionType: 'Soort actie',
		folder: 'Folder',
		user: 'Gebruiker',
		actions: 'Acties',
		lastDiscoveryAt: 'Laatste ontdekking',
		lastSyncAt: 'Laatste sync',
		restart: 'Herstarten',
		documentName: 'Document naam',
		externalId: 'Externe ID',
		state: 'Status',
		syncAttempts: 'Sync pogingen',
		lastUpdateAt: 'Laatste update',
		addActivity: 'Voeg activiteit toe',
		actionType_DOWNLOAD_DOCUMENTS: 'Download documenten',
		actionType_UPLOAD_COMPLETED_DOCUMENTS: 'Upload voltooide documenten',

		activitySettings: 'Connector activiteit instellingen',
		activityActionType: 'Soort activiteit',
		activityFolder: 'Folder om toe te passen',
		activityFolderName: 'Folder naam',
		activityFolderInfo_DOWNLOAD_DOCUMENTS: 'Specifieer in welke folder de gedownloade documenten moeten komen of laat leeg om het systeem automatisch een folder te laten kiezen.',
		activityFolderInfo_UPLOAD_COMPLETED_DOCUMENTS: 'Specifieer op welke folder deze activiteit moet toegepast worden of laat het leeg om het op alle folders toe te passen.',
		activityUser: 'De gebruiker die deze activiteit zal uitvoeren',
		activityUserEmail: 'Email gebruiker',
		activityUserInfo: 'De gebruiker die zal gekoppeld worden aan de documenten. U kan hieronder enkel mappen selecteren waar de gebruiker documenten kan editeren.',
		activityAdditionalConfiguration: 'Bijkomende configuratie',
		activitySettingsFinish: 'Alles ingevuld?',
		activitySettingsFinishDescription: 'Druk op opslaan om de wijzigingen te behouden',
		activityDelete: 'Verwijder activiteit',
		activityDeleteConfirm: 'Ben je zeker dat je deze activiteit wilt verwijderen?',
		activityGrid: 'Connector activiteiten',

		instanceAdd: 'Voeg connector toe',
		instanceAddInfo: 'Je kan slechts één connector per type tegelijkertijd hebben.',

		directory: 'Map',
		sftpDirectory_DOWNLOAD_DOCUMENTS: 'SFTP map waarvan documenten naar Quill gedownload worden',
		sftpDirectory_UPLOAD_COMPLETED_DOCUMENTS: 'SFTP map waarnaar voltooide documenten van Quill geüpload worden',
		sharepointSite_DOWNLOAD_DOCUMENTS: 'Sharepoint site waarvan documenten gedownload worden naar Quill',
		sharepointSite_UPLOAD_COMPLETED_DOCUMENTS: 'Sharepoint site waarnaar voltooide documenten van Quill geüpload worden',
		sharepointDrive: 'Drive',
		sharepointDriveDescription: 'Sharepoint drive (laat leeg voor de default drive)',
		sharepointParentPath_DOWNLOAD_DOCUMENTS: 'Sharepoint folder waarvan documenten gedownload worden naar Quill. Indien leeg, worden alle documenten binnen de hoofdfolder gedownload.',
		sharepointParentPath_UPLOAD_COMPLETED_DOCUMENTS: 'Sharepoint folder waarnaar documenten geüpload worden. Indien de folder nog niet bestaat, dan zal die aangemaakt worden.',

		inventoryGrid: 'Verwerkte items',
		inventoryState_AWAITING_SYNC: 'Wachten op sync',
		inventoryState_SYNCING: 'Aan het syncen',
		inventoryState_SYNCED: 'Gesynced',
		inventoryState_ERROR: 'Fout',
	},

	eid: {
		login: 'eID Login',
		userProfileCardNumber: 'Om deze functionaliteit te gebruiken moet het eID kaartnummer ingevuld zijn op het gebruikersprofiel.',
		cardHeader: 'Plaats uw eID kaart in de kaartlezer',
		cardNotFound: 'Gelieve uw eID kaart in te voeren.',
		enterPin: 'Geef uw pin in',
		middleware: 'Dioss eID Middleware',
		middlewareNotRunning: 'U moet Dioss eID Middleware op uw computer installeren voordat u verder kunt gaan met de authenticatie van de eID-kaart.',
		middlewareDownloadHere: 'Download de Dioss eID Middleware hier:',
		middlewareAdditionalSupport: 'Vragen? Raadpleeg onze $link;https://smartsolutions.dioss.com/en/products/eid-middleware/dioss-eid-middelware-faq;FAQ$',
		wrongPin: 'U heeft een foutieve pin ingegeven. {0} pogingen over.',
		pinTimeoutOrCancel: 'U heeft de pincode niet op tijd ingevoerd of deze is geannuleerd.',
		externalPinPad: 'Gelieve uw pin in te geven op de kaartlezer wanneer deze wordt gevraagd.',
		externalPinDialog: 'Gelieve uw pin in te geven in het externe pin input venster wanneer deze wordt gevraagd.',
		pincode: 'Pincode'
	},

	reminder: {
		success: 'Herinneringen zijn verstuurd',

		nothingToRemind: 'Geen verzoeken waarvoor een herinnering gestuurd kan worden',
		notSentReason_TOO_SOON: 'Herinneringen kunnen slechts eenmaal per uur verzonden worden',
		notSentReason_CANNOT_REMIND_REQUESTER: 'U kan geen herinnering naar uzelf sturen',
		notSentReason_USER_REMINDER_NOTIFICATIONS_DISABLED: 'U heeft met succes een herinnering geactiveerd, maar ten minste één van de ondertekenaars/goedkeurders heeft deze meldingen uitgeschakeld',
		notSentReason_OTHER: 'Er ging iets mis tijdens het versturen van herinneringen',
	},

	mail: {
		success: 'Mail(s) verstuurd',

		preview: 'Voorbeeld',
		test: 'Test verzenden',
		templateType: 'Type',
		fieldOverride: 'Overschrijven',

		templateSubject: 'Onderwerp',
		templateHeaderTitle: 'Titel',

		emailFieldsLinkEdit: 'Link toevoegen / editeren',
		emailFieldsImageEdit: 'Afbeelding toevoegen / editeren',
		emailFieldsRows: 'Rijen',
		emailFieldsPlaceholder: 'Placeholder',
		emailFieldsPlaceholder_APPROVER: 'Goedkeurder',
		emailFieldsPlaceholder_ATTACHMENT_MAX_SIZE: 'Bijlage max grootte',
		emailFieldsPlaceholder_COMPANY: 'Bedrijf',
		emailFieldsPlaceholder_COMPANY_LOGO: 'Bedrijf logo',
		emailFieldsPlaceholder_CONTENT_TABLE: 'Tabel inhoud',
		emailFieldsPlaceholder_CREATOR: 'Aanmaker',
		emailFieldsPlaceholder_CUSTOM_MESSAGES: 'Custom boodschap',
		emailFieldsPlaceholder_DAILY_DIGEST_TABLE: 'Dagelijkse samenvatting tabel',
		emailFieldsPlaceholder_DATE: 'Datum',
		emailFieldsPlaceholder_DEADLINE: 'Deadline',
		emailFieldsPlaceholder_DECLINE_REASON: 'Afkeurreden',
		emailFieldsPlaceholder_DECLINED_TABLE: 'Tabel afwijzingen',
		emailFieldsPlaceholder_DIOSS_LOGO: 'Dioss logo',
		emailFieldsPlaceholder_DOCUMENT: 'Document',
		emailFieldsPlaceholder_DOCUMENT_COUNT: 'Document aantal',
		emailFieldsPlaceholder_DOCUMENT_URL: 'Document URL',
		emailFieldsPlaceholder_OTP: 'OTP (Eenmalige code)',
		emailFieldsPlaceholder_RECEIVER: 'Ontvanger',
		emailFieldsPlaceholder_REJECT_REASON: 'Afwijzingsreden',
		emailFieldsPlaceholder_SIGNER: 'Ondertekenaar',
		emailFieldsPlaceholder_TITLE: 'Titel',
		emailFieldsPlaceholder_QUILL_LOGO: 'Quill logo',
		emailFieldsPlaceholder_USER_INVITE_LINK_EXPIRY: 'Uitnodigingslink vervaldatum',
		emailFieldsPlaceholder_USER_URL: 'Gebruiker URL',
	},

	serverError: {
		GENERAL: 'Algemene fout',

		COMPANY_NOT_FOUND: 'Bedrijf niet gevonden',
		COMPANY_SUBDOMAIN_ALREADY_EXISTS: 'Bedrijf subdomein bestaat reeds',
		COMPANY_UNABLE_TO_DELETE: 'Bedrijf kan niet worden verwijderd: er zijn documenten, subbedrijven of het verzoek is gedaan vanuit de bedrijfscontext',
		COMPANY_USER_GROUP_ALREADY_EXISTS: 'Groep bestaat reeds',
		COMPANY_USER_GROUP_NOT_FOUND: 'Groep niet gevonden',
		COMPANY_USER_NOT_FOUND: 'Gebruiker niet gevonden',
		COMPANY_CHILD_EXISTS: 'Eén of meer subbedrijven bestaan reeds',
		COMPANY_IS_CHILD_COMPANY: 'Bedrijf is een subbedrijf',

		DOCUMENT_FOLDER_ALREADY_EXISTS: 'Map bestaat reeds',
		DOCUMENT_FOLDER_NOT_FOUND: 'Map niet gevonden',
		DOCUMENT_FORM_PROBLEM: 'Een probleem met het formulier op het document',
		DOCUMENT_INVALID_CONTENTS: 'Het document heeft conflicterende inhoud',
		DOCUMENT_INVALID_STATE: 'Het document heeft een ongeldige toestand',
		DOCUMENT_MISSING_DESCRIPTION: 'Het document heeft een vereiste beschrijving nodig',
		DOCUMENT_NOT_FOUND: 'Het document werd niet gevonden. Mogelijks omdat het document ondertussen werd afgesloten of verwijderd.',
		DOCUMENT_NO_ACTORS: 'Er zijn geen actoren op het document',
		DOCUMENT_PAGE_INDEX_OUT_OF_BOUNDS: 'Geen geldige pagina index',
		DOCUMENT_PASSWORD_PROTECTED: 'Het document is beschermd met een wachtwoord',
		DOCUMENT_READ_ONLY: 'Het document kan alleen gelezen worden',
		DOCUMENT_REQUIRED_FORM_FIELD_MISSING: 'Een verplicht veld is niet ingevuld',
		DOCUMENT_SIGNING_IN_PROGRESS: 'Het document is vergrendeld en wordt momenteel ondertekend',
		DOCUMENT_CONVERT_PROBLEM: 'Probleem met de omzetting van het document',

		DOCUMENT_TEMPLATE_NOT_FOUND: 'Document sjabloon niet gevonden',
		DOCUMENT_TEMPLATE_ALREADY_EXISTS: 'Document sjabloon bestaat reeds',

		EMAIL_ALREADY_IN_USE: 'Het e-mailadres is reeds in gebruik',
		EMAIL_INVALID: 'Ongeldig e-mailadres, zorg dat het correct gestructureerd is en dat het geen letters met een diakritisch teken bevat (accent, cedille ...)',
		EMAIL_CHALLENGE_INVALID: 'Deze e-mail link om uw wachtwoord te wijzigen is niet langer geldig',

		EMAIL_DOMAIN_AUTHENTICATION_ERROR: 'Fout bij verwerking geauthenticeerd e-mail domein',

		NAME_INVALID: 'Ongeldige naam',

		FOLDER_APPROVER_NOT_ALLOWED: 'Het is voor deze gebruiker niet toegelaten om goedkeurder te zijn in deze map',
		FOLDER_SIGNER_NOT_ALLOWED: 'Het is voor deze gebruiker niet toegelaten om ondertekenaar te zijn in deze map',

		SIGNER_GROUP_NOT_FOUND: 'De ondertekenaarsgroep is niet gevonden',

		APPROVAL_CONSTRAINT_VIOLATION: 'De goedkeurings parameters zijn foutief',
		APPROVAL_REASON_MISSING: 'De reden ontbreekt',
		SIGNING_ITSME_PROBLEM: 'Er is een itsme probleem',
		SIGNING_ITSME_SESSION_NOT_FOUND: 'De itsme® sessie kon niet gevonden worden',
		SIGNING_ITSME_SIGNING_TIMEOUT: 'Er is een time-out opgetreden in de itsme® flow',
		SIGNING_ITSME_SERVICE_ERROR: 'Er was een probleem bij het plaatsen van de de itsme® handtekening',
		SIGNING_ITSME_RESPONSE_ERROR: 'Er was een probleem met de data afkomstig van itsme®',
		SIGNING_ITSME_SIGNING_USER_TIMEOUT: 'Het ondertekenen van het document(en) met itsme® duurde te lang, probeer opnieuw',
		SIGNING_ITSME_SIGNING_USER_REJECTED: 'Gelieve het document(en) in Quill af te wijzen, als dit een vergissing was dan kunt u de itsme® ondertekening opnieuw proberen',
		SIGNING_ITSME_EXPIRED: 'De itsme® sessie is verlopen, probeer opnieuw',
		SIGNING_ITSME_BUSY: 'Er is reeds een itsme® sessie bezig, deze wordt automatisch opgeschoond na 5 minuten...',

		SIGNING_OTP_CHALLENGE_FAILED: 'Ongeldige identificatiecode',
		SIGNING_OTP_NUMBER_CHECK_FAILED: 'Ongeldig SMS nummer',
		SIGNING_SIGN_REQUEST_CLOSED: 'Het is niet meer mogelijk dit document te ondertekenen',
		SIGNATURE_PLACEHOLDER_NOT_FOUND_ERROR: 'Kon de voorziene plaats niet vinden in het document',
		SIGNING_SIGN_INVALID_REQUEST: 'Ongeldig verzoek om te ondertekenen, ofwel moet de handtekeninglocatie ofwel de voorziene plaats(en) opgegeven worden',
		SIGNING_DECLINE_NOT_ALLOWED: 'Ondertekenen afwijzen is niet toegestaan',
		SIGNING_CERTIFICATE_MISSING: 'U kunt niet ondertekenen met deze kaart aangezien deze geen ondertekeningscertificaat heeft (bv. kids eID).',
		SIGNING_CERTIFICATE_PROBLEM: 'Ongeldig ondertekencertificaat',
		SIGNING_CERTIFICATE_REVOKED: 'Het ondertekencertificaat is ingetrokken',
		SIGNING_CERTIFICATE_RETRIEVAL_PROBLEM: 'Het ondertekencertificaat kon niet worden opgehaald',
		SIGNING_GENERAL_PROBLEM: 'Er is een probleem opgetreden tijdens het ondertekenen',
		SIGNER_DATA_MISMATCH_ERROR: 'Data ondertekenaar komt niet overeen',
		SIGNATURE_PLACEHOLDER_PARSING_ERROR: 'Mislukt om de voorziene handtekenplaats(en) te ontleden',
		SIGNATURE_PLACEHOLDER_NOT_UNIQUE: 'Er zijn meerdere voorziene handtekenplaats(en) met dezelfde waarden',
		SIGNATURE_TYPE_CONFIG_USAGE_NOT_ALLOWED: 'Het gebruik van dit type handteken configuratie is niet toegestaan',

		USER_ALREADY_ADDED: 'Deze gebruiker is reeds toegevoegd',
		USER_EXISTS_AS_GUEST: 'Deze gebruiker bestaat reeds als gast',
		USER_NOT_FOUND: 'Gebruiker niet gevonden',
		USER_NOT_FOUND_BY_EID: 'Gebruiker niet gevonden. Zorg ervoor dat uw huidig eID kaartnummer overeen stemt met het kaartnummer op het gebruikersprofiel.',
		USER_USED_BY_CONNECTOR_ACTIVITY: 'Gebruiker is nog gekoppeld aan één of meerdere connector activiteiten.',
		USER_INVALID_DATA: 'De gegeven gebruikersdata is ongeldig of onvolledig',
		USER_INVALID_DATA_FORMAT: 'De gegeven gebruikersdata is in het verkeerde formaat',
		USER_DUPLICATE_META_FIELD: 'Een of meerdere meta-attributen zijn reeds gedefinieerd voor de gebruiker',
		USER_INVALID_META_FIELD: 'Een of meerdere meta-attributen zijn niet beschikbaar voor de ondertekenaar',
		USER_INVALID_NOTIFICATION: 'Een of meerdere herinneringstypes zijn ongeldig',

		PASSWORD_INVALID: 'Ongeldig wachtwoord',
		PASSWORD_MISMATCH: 'Wachtwoorden komen niet overeen',
		PASSWORD_DOES_NOT_MEET_REQUIREMENTS: 'Het wachtwoord voldoet niet aan de vereisten',
		PASSWORD_SHOULD_RESET: 'Wachtwoord reset vereist. Een wachtwoord reset uitnodiging is verstuurd naar uw mailbox.',
		PASSWORD_RESET_EXPIRED: 'De link voor het opnieuw instellen van het wachtwoord is verlopen',

		AUTHENTICATION_FACTOR_METHOD_INVALID_AUTHENTICATOR: 'Een authenticator is niet geactiveerd als 2-staps verificatie op uw account',
		AUTHENTICATION_FACTOR_CHALLENGE_INVALID_AUTHENTICATOR: 'De ingevoerde authenticator code is niet correct. Controleer de code in uw app en probeer het opnieuw',
		AUTHENTICATION_FACTOR_CHALLENGE_EXPIRED_AUTHENTICATOR: 'De ingevoerde authenticator code is vervallen. Controleer de code in uw app en probeer het opnieuw',

		LOGIN_FAILED: 'Inloggen mislukt',
		OIDC_ERROR: 'Er is een probleem opgetreden bij het verbinden met de OIDC-server. Controleer uw configuratie-instellingen en zorg ervoor dat de server toegankelijk is. Als het probleem zich blijft voordoen, probeer het later opnieuw.',

		INVALID_URL: 'Ongeldige URL',
		NOT_ALLOWED: 'Het is niet toegestaan deze actie te ondernemen',
		OTP_INVALID_NUMBER: 'Ongeldig SMS nummer',
		SMS_SEND: 'Er is een probleem bij het versturen van de SMS',
		EID_NUMBER_ALREADY_IN_USE: 'Dit eID kaartnummer is reeds in gebruik',
		SIGNATURE_TYPE_NOT_ALLOWED: 'Dit type handtekening is niet toegestaan',
		SIGNATURE_TYPE_CONFIG_NOT_ALLOWED: 'Deze configuratie van handtekening type is niet toegestaan',
		SIGNATURE_TYPE_CONFIG_INVALID_NAME: 'Ongeldige handtekening type configuratie naam',
		TIME_STAMP_EXCEPTION: 'Er was een probleem bij het zetten van een timestamp',
		WEBHOOK_PROCESSING: 'Er was een probleem bij het verwerken van de webhook',

		IMAGE_INVALID_SIZE: 'Afbeeldingen mogen maximaal 1MB groot zijn',
		IMAGE_INVALID_MIME_TYPE: 'Dit bestand is niet toegelaten, de afbeelding moet van het type png of jpeg zijn.',

		FORWARD_TARGET_NOT_FOUND: 'Geen gebruiker gevonden om naar door te sturen',
		FORWARD_NOT_POSSIBLE: 'Het doorsturen is niet mogelijk',
		ITSME_AUTHENTICATION_ERROR: 'Een probleem bij itsme® authenticatie',

		DELEGATE_FILE_ERROR: 'Er is een probleem opgetreden bij het verwerken van het bestand',

		REPORT_UNAVAILABLE: 'Rapport(en) momenteel niet beschikbaar. Gelieve het over enkele ogenblikken opnieuw te proberen.',

		FILE_TYPE_NOT_ALLOWED: 'Bestandstype niet toegestaan',

		WEBHOOK_CONFIG_NOT_FOUND: 'Webhook-configuratie niet gevonden',
		WEBHOOK_CONFIG_PKCS12_INVALID_PASSWORD: 'Ongeldig wachtwoord gedetecteerd bij openen PKCS12',
		WEBHOOK_CONFIG_PKCS12_UNABLE_TO_PARSE: 'Kan PKCS12-bestand niet openen, controleer of het een binair bestand is. Dit kan worden gemaakt/geëxporteerd door OpenSSL via de pkcs12 -export module'
	},

	upload: {
		selectFile: 'Selecteer bestand',
		fileName: 'Bestandsnaam',
		dragFileHere: '..of sleep uw bestand hier',
		invalidSize: 'Ongeldige grootte, maximale grootte is {0}',
		unknownError: 'Een onbekende fout is opgetreden',
	},

};
