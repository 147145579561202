import React, {Component} from "react";
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SIGNATURE_TYPES} from "../common/Constants";

class EditorBulkSignatureTypesDialog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			signatureTypeConfigs: []
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState({
				signatureTypeConfigs: [...this.props.possibleSignatureTypeConfigs.map(config => config.id)]
			});
		}
	}

	render() {

		const signatureTypeMap = this.props.possibleSignatureTypeConfigs.reduce((prev, current) => ({
			...prev,
			[current.signatureType]: [...prev[current.signatureType] || [], {...current}]
		}), {});

		return <Dialog open={this.props.open}
					   onClose={this.onClose}
					   fullWidth
					   maxWidth="sm">
			<DialogTitle>{this.props.t('editor.signingBulkSignatureTypes')}</DialogTitle>
			<DialogContent>
				<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center'}}>
					{Object.keys(SIGNATURE_TYPES)
						.filter(signatureType => !!signatureTypeMap[signatureType])
						.map(type => {
							return signatureTypeMap[type].map(config => {
								const checked = this.state.signatureTypeConfigs.indexOf(config.id) >= 0;
								const disabled = checked && this.state.signatureTypeConfigs.length <= 1;
								return <FormControlLabel
									key={config.id}
									control={<Checkbox
										checked={checked}
										onChange={(e) => this.onChangeSignatureType(config.id, e.target.checked)}
										disabled={disabled}
										id={`input-editor-signers-signaturetype-bulk-checkbox-${type}`}
									/>}
									label={<Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
										<img src={!!config.logoMimeType && !!this.props.signatureTypeConfigLogos[config.id] ? `data:${config.logoMimeType};base64,${this.props.signatureTypeConfigLogos[config.id]}`: SIGNATURE_TYPES[type].img}
											 style={{width: '30px', height: 'auto'}}/>
										<Typography variant="body2">{config.label}</Typography>
									</Box>}
								/>
							})
						})}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-selection-cancel">{this.props.t('back')}</Button>
				<Button onClick={this.onConfirm} variant="contained" id="btn-selection-confirm">{this.props.t('confirm')}</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeSignatureType = (configId, checked) => {
		const signatureTypeConfigs = (this.state.signatureTypeConfigs|| [])
			.filter(search => search !== configId);
		if (checked) {
			signatureTypeConfigs.push(configId);
		}
		this.setState({signatureTypeConfigs});
	}

	onConfirm = () => {
		this.props.onChangeSignatureTypeConfigs(this.state.signatureTypeConfigs);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(EditorBulkSignatureTypesDialog));
