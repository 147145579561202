import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {createHashHistory} from "history";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {createRouterMiddleware, ReduxRouter} from "@lagunovsky/redux-react-router";
import { pdfjs } from 'react-pdf';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {LicenseInfo} from "@mui/x-license";
import createRootReducer from './reducers';
import rootSaga from './sagas';
import App from "./components/App";
import './i18n';

import "@fontsource/lato";
import "pdfjs-dist/web/pdf_viewer.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './style.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

LicenseInfo.setLicenseKey('ebb412d5f4b890d65a467cde38a51c1eTz05ODIxMCxFPTE3NTgwOTQwMTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==');

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const history = createHashHistory();

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (process.env.REACT_APP_DEVTOOLS_ENABLED === 'true' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(
    createRootReducer(history),
    composeEnhancers(
        applyMiddleware(
            createRouterMiddleware(history),
            sagaMiddleware
        ),
    ),
);

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ReduxRouter history={history}>
            <DndProvider backend={HTML5Backend}>
                <App />
            </DndProvider>
        </ReduxRouter>
    </Provider>,
);
