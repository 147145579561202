import React, {Component, Fragment} from "react";
import {Box, Button, IconButton, Typography} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import FlexibleToolbar from "../common/FlexibleToolbar";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import CompanyCreationDialog from "../company/CompanyCreationDialog";
import CompanyChildCreationDialog from "../company/CompanyChildCreationDialog";
import CompanyAdminSettingsDialog from "../company/CompanyAdminSettingsDialog";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {FORMAT_BYTES, ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE_SELECT} from "../common/Constants";
import ConfirmationDialog from "../common/ConfirmationDialog";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import SyncIcon from "@mui/icons-material/Sync";
import {DEBOUNCE} from "../common/DebounceUtil";

class ApplicationCompanyOverviewComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// list
			filterValue: '',
			page: 0,
			pageSize: ROWS_PER_PAGE_OPTIONS.at(0),
			sortModel: [{field: 'name', sort: 'asc'}],

			// dialogs
			creationDialogOpen: false,
			childCreationDialogOpen: false,
			updateDialogOpen: false,
			deleteDialogOpen: false,
		}
	}

	componentDidMount() {
		this.setState({
			pageSize: ROWS_PER_PAGE_SELECT(this.props.sessionInfo.defaultRowCount)
		}, () => this.onCompanyFetchOverviewList());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.companyActionSuccessfully && !prevProps.companyActionSuccessfully) {
			this.onCompanyFetchOverviewList();
		}
	}

	render() {
		const busy = this.props.companyBusy
			&& !this.state.updateDialogOpen && !this.state.creationDialogOpen && !this.state.childCreationDialogOpen
			&& !this.state.deleteDialogOpen;

		let dataGridColumns = [
			{
				field: 'type',
				headerName: '',
				editable: false,
				align: 'center',
				width: 50,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (cellValues) => (!!cellValues.row.parentCompanyName && <DomainAddIcon titleAccess={this.props.t('company.child')} fontSize="small"/>)
			},
			{
				field: 'name',
				headerName: this.props.t('company.companyName'),
				editable: false,
				flex: 1,
				renderCell: (cellValues) => (<Box sx={{...(cellValues.row.inactive && {fontStyle: 'italic', color: '#A2A2A2'})}}>
					{cellValues.row.name + (cellValues.row.inactive ? ' (' + this.props.t('company.adminSettingsInactive') + ')' : '')}
				</Box>)
			},
			{
				field: 'parentCompanyName',
				headerName: this.props.t('company.parentCompany'),
				editable: false,
				flex: 1
			}
		];

		// columns visible for application admins only
		if (this.props.sessionInfo.applicationAdmin) {
			dataGridColumns = dataGridColumns.concat([
				{
					field: 'documentCount',
					headerName: this.props.t('company.documentCount'),
					editable: false,
					flex: 1
				},
				{
					field: 'externalStorageSize',
					headerName: this.props.t('company.externalStorageSize'),
					editable: false,
					flex: 1,
					valueGetter: (value) => FORMAT_BYTES((value || 0))
				}]);
		}

		dataGridColumns = dataGridColumns.concat({
			field: 'actions',
			type: 'actions',
			headerName: this.props.t('company.actions'),
			editable: false,
			sortable: false,
			disableColumnMenu: true,
			width: 120,
			renderCell: (cellValues) => (<>
				<IconButton
					variant="contained"
					color="primary"
					title={this.props.t('company.settings')}
					onClick={() => {
						this.setState({activeCompanyId: cellValues.row.id});
						this.onUpdateDialogOpen();
					}}>
					<SettingsIcon fontSize="small"/>
				</IconButton>

				<IconButton
					variant="contained"
					color="primary"
					title={this.props.t('company.switch')}
					onClick={() => {
						this.props.onCompanySwitch({companyId: cellValues.row.id});
					}}>
					<SyncIcon fontSize="small"/>
				</IconButton>

				<IconButton
					variant="contained"
					color="primary"
					title={this.props.t('company.delete')}
					onClick={() => {
						this.setState({activeCompanyId: cellValues.row.id});
						this.onDeleteDialogOpen();
					}}>
					<DeleteIcon fontSize="small"/>
				</IconButton>
			</>)
		},)


		return <Box sx={{mt: 2}}>
			<Typography variant="h6">{this.props.t('company.header')}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
				{this.props.sessionInfo.applicationAdmin &&
					<Button variant="contained"
							onClick={this.onCreationDialogOpen}
							startIcon={<AddIcon/>}
							sx={{mr: 1}}
							disabled={busy}
							id="btn-company-create"
					>
						{this.props.t('company.create')}
					</Button>
				}

				<Button variant="contained"
						onClick={this.onChildCreationDialogOpen}
						startIcon={<AddIcon/>}
						sx={{mr: 1}}
						disabled={busy}
						id="btn-company-create-child"
				>
					{this.props.t('company.createChild')}
				</Button>
			</Box>
			<DataGrid autoHeight
					  disableColumnSelector
					  columns={dataGridColumns}
					  slots={{toolbar: FlexibleToolbar}}
					  slotProps={{
						  toolbar: {
							  filterId: 'input-company-overview-search-text',
							  filterValue: this.state.filterValue,
							  onChangeFilterValue: this.onFilterValueChange,
						  }
					  }}

					  loading={busy}

					  pagination
					  paginationMode="server"
					  paginationModel={{page: this.state.page, pageSize: this.state.pageSize}}
					  onPaginationModelChange={this.onPaginationModelChange}
					  pageSizeOptions={ROWS_PER_PAGE_OPTIONS}

					  sortingMode="server"
					  sortModel={this.state.sortModel}
					  onSortModelChange={this.onSortModelChange}

					  disableColumnFilter
					  disableRowSelectionOnClick

					  rows={this.props.companyOverviewList}
					  rowCount={this.props.companyOverviewCount}
					  density="compact"/>

			<CompanyCreationDialog
				open={this.state.creationDialogOpen}
				onClose={this.onCreationDialogClose}
				onCreate={this.onCompanyCreate}/>

			<CompanyChildCreationDialog
				open={this.state.childCreationDialogOpen}
				onClose={this.onChildCreationDialogClose}
				onCreate={this.onCompanyCreate}/>

			<CompanyAdminSettingsDialog
				companyId={this.state.activeCompanyId}
				open={this.state.updateDialogOpen}
				onClose={this.onUpdateDialogClose}
				onUpdateAdminSettings={this.onUpdateAdminSettings}/>

			<ConfirmationDialog
				title={this.props.t('company.delete')}
				confirm={this.props.t('company.deleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onDeleteDialogClose}
				onConfirm={this.onCompanyDelete}/>
		</Box>
	}

	onCreationDialogOpen = () => {
		this.setState({creationDialogOpen: true});
	}

	onCreationDialogClose = () => {
		this.setState({creationDialogOpen: false});
	}

	onChildCreationDialogOpen = () => {
		this.setState({childCreationDialogOpen: true});
	}

	onChildCreationDialogClose = () => {
		this.setState({childCreationDialogOpen: false});
	}

	onCompanyCreate = (request) => {
		this.setState({creationDialogOpen: false, childCreationDialogOpen: false}, () => this.props.onCompanyCreate(request));
	}

	onUpdateDialogOpen = () => {
		this.setState({updateDialogOpen: true});
	}

	onUpdateDialogClose = () => {
		this.setState({updateDialogOpen: false});
	}

	onDeleteDialogOpen = () => {
		this.setState({deleteDialogOpen: true});
	}

	onDeleteDialogClose = () => {
		this.setState({deleteDialogOpen: false});
	}

	onCompanyDelete = () => {
		this.setState({deleteDialogOpen: false}, () => this.props.onCompanyDelete(this.state.activeCompanyId));
	}

	onFilterValueChange = (e) => {
		this.setState({filterValue: e.target.value}, DEBOUNCE(this.onCompanyFetchOverviewList, 300));
	}

	onPaginationModelChange = ({page, pageSize}) => {
		this.setState({page, pageSize}, this.onCompanyFetchOverviewList)
	}

	onSortModelChange = (sortModel) => {
		this.setState({sortModel}, this.onCompanyFetchOverviewList);
	}

	onUpdateAdminSettings = (settings) => {
		this.setState({updateDialogOpen: false}, () => this.props.onUpdateAdminSettings(this.state.activeCompanyId, settings));
	}

	onCompanyFetchOverviewList = () => {
		this.props.onCompanyFetchOverviewList({
			page: this.state.page,
			pageSize: this.state.pageSize,
			filterValue: this.state.filterValue,
			sortField: this.state.sortModel.length > 0 ? {
				name: this.state.sortModel[0].field,
				sortOrder: this.state.sortModel[0].sort.toUpperCase()
			} : null
		})
	}
}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyOverviewList: state.company.overviewList,
			companyOverviewCount: state.company.overviewCount,
			companyActionSuccessfully: state.company.companyActionSuccessfully,
		}
	},
	dispatch => {
		return {
			onCompanyFetchOverviewList: (request) => {
				dispatch({
					type: 'COMPANY_FETCH_OVERVIEW_LIST',
					request
				});
			},
			onCompanyCreate: (request) => {
				dispatch({
					type: 'COMPANY_CREATE',
					request
				});
			},
			onUpdateAdminSettings: (companyId, settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_ADMIN_SETTINGS',
					companyId,
					settings
				});
			},
			onCompanyDelete: (companyId) => {
				dispatch({
					type: 'COMPANY_DELETE',
					companyId
				});
			},
			onCompanySwitch: (request) => {
				dispatch({
					type: 'SESSION_SWITCH_COMPANY',
					request
				});
			}
		}
	}
)(ApplicationCompanyOverviewComponent));
