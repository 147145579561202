import React, {Children, cloneElement, Component, useCallback, useMemo, useState} from "react";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {useDrag} from "react-dnd";
import {format, parse} from "date-fns";
import {Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, TextField, Typography} from "@mui/material";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PdfViewerComponent from "../common/PdfViewerComponent";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopy from "@mui/icons-material/ContentCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import WarningIcon from "@mui/icons-material/Warning";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EditorUploadPdfDialog from "./EditorUploadPdfDialog";
import {BACKGROUND_COLOR_TO_RGBA, HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION, PDF_ERROR_COUNT, VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION} from "./EditorUtils";
import FixedWidthHeaderComponent from "../common/FixedWidthHeaderComponent";
import SwapDocumentIcon from "./SwapDocumentIcon";
import {FORM_FIELD_DATE_VALUE_FORMAT, FORM_FIELD_DATE_VISUAL_FORMAT} from "../common/Constants";
import PendingChangesDialog from "../common/PendingChangesDialog";
import {withRouter} from "../common/RouterHelper";

const SignatureOverlay = ({grab, signer, signerIndex, readOnly, index, field, onRemove, onEditSettings}) => {
	const {t} = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		event.preventDefault();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const label = useMemo(() => {
		if (signer === 'multisign') {
			return 'Multi-Sign';
		} else if (!signer.person) {
			return t('editor.signingPlaceholder') + ' ' + (1 + signerIndex);
		} else {
			return signer.person.firstName + ' ' + signer.person.lastName;
		}
	}, [signer]);

	return <Paper
		elevation={4}
		onContextMenu={handleClick}
		onDoubleClick={onEditSettings}
		sx={{
			backgroundColor: BACKGROUND_COLOR_TO_RGBA(field?.style?.backgroundColor, field?.style?.backgroundOpacity),
			display: 'flex',
			flexDirection: 'column',
			justifyContent: VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.verticalAlignment),
			alignItems: HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.horizontalAlignment),
			width: '100%',
			height: '100%',
			userSelect: 'none',
			fontSize: field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(1.2vw, 16px)',
			p: 0.5,
			...(!!grab && {cursor: 'grab'})
		}}>
		<Box
			sx={{
				display: 'flex',
			}}
		>
			<DriveFileRenameOutlineIcon sx={{fontSize: field?.style?.fontSize ? field.style.fontSize * 2 : 32}}/>{label}
		</Box>
		{!!signer.capacityTitle && <Box
			sx={{
				display: 'flex',
				fontSize: 'smaller'
			}}
		>
			{signer.capacityTitle}
		</Box>}
		{!readOnly && !!onRemove  && <Menu
			id="menu-editor-pdf-viewer-signer-paraph"
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}>
			<MenuList>
				{<MenuItem onClick={() => {
					onRemove(signer, index);
					setAnchorEl(null);
				}}>
					<ListItemIcon>
						<DeleteIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('editor.signingSignatureFieldDelete')}</ListItemText>
				</MenuItem>}
				{!!onEditSettings && <MenuItem onClick={() => {
					onEditSettings(field);
					setAnchorEl(null);
				}}>
					<ListItemIcon>
						<SettingsIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('editor.signingSignatureFieldSettings')}</ListItemText>
				</MenuItem>}
			</MenuList>
		</Menu>}
	</Paper>
}

const ParaphOverlay = ({grab, signer, signerIndex, readOnly, index, field, onRemove, onDuplicate, onEditSettings}) => {
	const {t} = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		event.preventDefault();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return <Paper
		elevation={4}
		onContextMenu={handleClick}
		onDoubleClick={onEditSettings}
		sx={{
			backgroundColor: BACKGROUND_COLOR_TO_RGBA(field?.style?.backgroundColor, field?.style?.backgroundOpacity),
			display: 'flex',
			flexDirection: 'column',
			justifyContent: VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.verticalAlignment),
			alignItems: HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.horizontalAlignment),
			width: '100%',
			height: '100%',
			userSelect: 'none',
			p: 0.5,
			...(!!grab && {cursor: 'grab'})
		}}>

		{'multisign' !== signer && !!signer.person && <>
			<Box sx={{
				fontSize: field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(1.2vw, 16px)',
				whiteSpace: 'nowrap'
			}}>{(signer.person.firstName.charAt(0) + ' ' + signer.person.lastName.split(' ').map(e => e.charAt(0)).join(' '))}</Box>
			<Box sx={{
				fontSize: field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(0.6vw, 10px)',
				textAlign: 'center', lineHeight: 1
			}}>{signer.person.firstName + ' ' + signer.person.lastName}</Box>
		</>}

		{'multisign' !== signer && !signer.person && <>
			<Box sx={{
				fontSize: field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(1.1vw, 11px)',
				textAlign: 'center', mb: 1
			}}>{t('editor.signingPlaceholder') + ' ' + (1 + signerIndex)}</Box>
			<Box sx={{
				fontSize: field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(0.6vw, 10px)',
				textAlign: 'center', lineHeight: 1
			}}>{t('editor.signingParaphField')}</Box>
		</>}

		{'multisign' === signer && <>
			<Box sx={{
				fontSize: field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(1.1vw, 14px)',
				whiteSpace: 'nowrap'
			}}>{'Multi-Sign'}</Box>
			<Box sx={{
				fontSize: field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(0.6vw, 10px)',
				textAlign: 'center', lineHeight: 1
			}}>{t('editor.signingParaphField')}</Box>
		</>}

		{!readOnly && <Menu
			id="menu-editor-pdf-viewer-signer-paraph"
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}>
			<MenuList>
				{!!onRemove && <MenuItem onClick={() => {
					onRemove(signer, index);
					setAnchorEl(null);
				}}>
					<ListItemIcon>
						<DeleteIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('editor.signingSignatureFieldDelete')}</ListItemText>
				</MenuItem>}
				{!!onDuplicate && <MenuItem onClick={() => {
					onDuplicate(signer, index);
					setAnchorEl(null);
				}}>
					<ListItemIcon>
						<ContentCopy fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('editor.signingParaphFieldDuplicate')}</ListItemText>
				</MenuItem>}
				{!!onEditSettings && <MenuItem onClick={() => {
					onEditSettings(field);
					setAnchorEl(null);
				}}>
					<ListItemIcon>
						<SettingsIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('editor.signingSignatureFieldSettings')}</ListItemText>
				</MenuItem>}
			</MenuList>
		</Menu>}
	</Paper>
}

const FormFieldOverlay = ({grab, readOnly, field, onRemove, onEditSettings}) => {
	const {t} = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const onHandleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget);
		event.preventDefault();
	}, []);
	const onHandleDoubleClick = useCallback(() => {
		if (!readOnly) {
			onEditSettings(field);
		}
	}, [field, readOnly, onEditSettings]);
	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const iconSize = field?.style?.fontSize ? field.style.fontSize * 2 : 32;
	const regularFontSize = field?.style?.fontSize ? field.style.fontSize + 'pt' : 'min(1.2vw, 16px)';
	const smallerFontSize = field?.style?.fontSize ? 0.625 * field.style.fontSize + 'pt' : 'min(1.0vw, 10px)';

	return <Paper
		elevation={4}
		onContextMenu={onHandleClick}
		onDoubleClick={onHandleDoubleClick}
		sx={{
			backgroundColor: BACKGROUND_COLOR_TO_RGBA(field?.style?.backgroundColor, field?.style?.backgroundOpacity),
			display: 'flex',
			flexDirection: 'column',
			justifyContent: VERTICAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.verticalAlignment),
			alignItems: HORIZONTAL_ALIGNMENT_TO_FLEX_DIRECTION(field?.style?.horizontalAlignment),
			width: '100%',
			height: '100%',
			userSelect: 'none',
			p: 0.5,
			...(!!grab && {cursor: 'grab'})
		}}>
		<Box sx={{
			fontSize: regularFontSize,
			whiteSpace: 'nowrap', display: 'flex', gap: 0.5, p: 0.5
		}}>
			{!!field.value && <Box sx={{display: 'flex', justifyContent: 'center'}}>
				{('TEXT' === field.type || 'NUMERIC' === field.type) && <Typography sx={{fontSize: regularFontSize}}>{field.value}</Typography>}
				{'CHECKBOX' === field.type && <>
					{'true' === field.value && <CheckBoxOutlinedIcon sx={{fontSize: iconSize, pr: 0.5}}/>}
					{'true' !== field.value && <CheckBoxOutlineBlankIcon sx={{fontSize: iconSize, pr: 0.5}}/>}
					{field.name + (field.required ? '*' : '')}
				</>}
				{'DATE' === field.type && <Typography sx={{fontSize: regularFontSize}}>{format(parse(field.value, FORM_FIELD_DATE_VALUE_FORMAT, Date.now()), FORM_FIELD_DATE_VISUAL_FORMAT)}</Typography>}
			</Box>}
			{!field.value && <>
				{field.type === 'CHECKBOX' && <CheckBoxOutlinedIcon sx={{fontSize: iconSize}}/>}
				{field.type === 'TEXT' && <>
					<FormatAlignJustifyIcon sx={{fontSize: iconSize}}/>
					{!field.editable && !field.value && <WarningIcon sx={{fontSize: iconSize}}/>}
				</>}
				{field.type === 'ATTRIBUTE' && <>
					<ContactPageIcon sx={{fontSize: iconSize}}/>
					<Typography sx={{fontSize: regularFontSize}}>{field.attributeType === 'META_FIELD' ? field.name : t('editor.formFieldAttributeType_' + field.attributeType)}</Typography>
				</>}
				{field.type !== 'ATTRIBUTE' && <>
					{field.name}
					{field.required && '*'}
				</>}
			</>}
		</Box>
		{!!field.value && field.type !== 'CHECKBOX' && <Box sx={{
			fontSize: smallerFontSize
		}}>
			{field.name + (field.required ? '*' : '')}
		</Box>}
		{!readOnly && <Menu
			id={'menu-editor-pdf-viewer-form-field-' + field.id}
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}>
			<MenuList>
				{!!onRemove && <MenuItem onClick={() => {
					onRemove(field);
					setAnchorEl(null);
				}}>
					<ListItemIcon>
						<DeleteIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('editor.signingSignatureFieldDelete')}</ListItemText>
				</MenuItem>}
				{!!onEditSettings && <MenuItem onClick={() => {
					onEditSettings(field);
					setAnchorEl(null);
				}}>
					<ListItemIcon>
						<SettingsIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('editor.signingSignatureFieldSettings')}</ListItemText>
				</MenuItem>}
			</MenuList>
		</Menu>}
	</Paper>
}

const FieldPlaceholderComponent = ({fieldType, disabled, onFieldPlaceholderClicked}) => {
	const {t} = useTranslation();
	return <Paper
		elevation={4}
		sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '100%',
			userSelect: 'none',
		}}>
		<Box sx={{fontSize: 'min(0.5vw, 20px)', whiteSpace: 'nowrap', display: 'flex', gap: 0.5, p: 0.5, alignItems: 'center'}}>
			<Button
				onClick={onFieldPlaceholderClicked}
				size="small"
				startIcon={<PersonPinIcon />}
				disabled={disabled}
			>
				{t('editor.signingFieldPlaceholder_' + fieldType)}
			</Button>
		</Box>
	</Paper>;
};

const DraggableOverlay = ({ signer, onFieldDropped, children, pageIndex, instance, field, resizable, onFieldResize }) => {
	const [, drag] = useDrag(() => ({
		type: 'signer',
		item: signer,
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult()
			if (item && dropResult) {
				const {pageIndex, rect} = dropResult;
				onFieldDropped({signer: item, pageIndex, rect, instance, field});
			}
		},
		collect: (monitor) => ({
			handlerId: monitor.getHandlerId(),
		}),
	}));

	const signatureField = field || signer?.signatureField;

	const resizeData = {
		moving: false,
		startX: 0,
		startY: 0,
		initialRelativeWidth: signatureField?.relativeWidth,
		initialRelativeHeight: signatureField?.relativeHeight,
	};

	const onMouseMove = (e) => {
		e.preventDefault();

		if (resizable && resizeData.moving) {
			const relativeScaleFactor = window.innerWidth / 220;

			let relativeWidth = resizeData.initialRelativeWidth + (e.screenX - resizeData.startX) / 100 / relativeScaleFactor;
			if (relativeWidth < 0.10) relativeWidth = 0.10;
			if (relativeWidth > 0.70) relativeWidth = 0.70;
			let relativeHeight = resizeData.initialRelativeHeight + (e.screenY - resizeData.startY) / 100 / relativeScaleFactor;
			if (relativeHeight < 0.025) relativeHeight = 0.025;
			if (relativeHeight > 0.25) relativeHeight = 0.25;

			onFieldResize(signer, relativeWidth, relativeHeight, instance, field);
		}
	}

	const onMouseUp = (e) => {
		e.preventDefault();

		resizeData.moving = false;

		window.removeEventListener('mousemove', onMouseMove);
		window.removeEventListener('mouseup', onMouseUp)
	}

	const onMouseDown = (e) => {
		e.preventDefault();

		if (!resizable) {
			return;
		}

		resizeData.moving = true;
		resizeData.startX = e.screenX;
		resizeData.startY = e.screenY;
		// capture mouse move outside our element
		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('mouseup', onMouseUp)
	}

	return <Box ref={drag} sx={{ width: '100%', height: '100%' }}>
		{Children.map(children, child => cloneElement(child, {pageindex: pageIndex}))}
		{resizable && <Box
			sx={{
				position: 'absolute',
				width: 15,
				height: 15,
				bottom: 3,
				right: 0,
				cursor: 'nw-resize'
			}}
			onMouseDown={onMouseDown}
		/>}
	</Box>
}

class EditorPdfComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			editing: false,
			name: '',
			paraphContextMenuAnchorEl: null,
			paraphActiveSigner: null,
			paraphActiveParaph: -1,
			changePdfPendingChangesDialogOpen: false,
			changePdfDialogOpen: false,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// handle save in pending change dialog
		if (this.state.changePdfPendingChangesDialogOpen) {
			if (!this.props.editorPendingChanges && prevProps.editorPendingChanges) {
				this.setState({changePdfPendingChangesDialogOpen: false, changePdfDialogOpen: true});
			}
			if (this.props.editorEditInfo !== prevProps.editorEditInfo) {
				this.setState({changePdfPendingChangesDialogOpen: false});
			}
		}
	}

	render() {
		const document = this.props.document;

		if (!document) {
			return null;
		}

		const suffix = !!document.version ? '?version=' + document.version : '';
		const pdfUrl = (document.documentCollection || !document.hasPdf) ?
			undefined : `/api/internal/document/${document.id}/pdf`; // cache busting/management is done server side
		const statusUrl = (document.documentCollection || !document.hasPdf) ?
			undefined : `/api/internal/document/${document.id}/preview/status${suffix}`;

		let pages = [];
		if (document.attachmentType === 'EXCEL') {
			// Excel attachments have no page count, but still require a placeholder
			pages = [{
				placeholder: true,
				logo: 'excel',
			}];
		} else {
			pages = Array(document.pageCount)
				.fill(null)
				.map((ign, index) => {
					if (document.documentCollection || !document.hasPdf) {
						return {
							placeholder: true,
						}
					} else {
						return {
							imageUrl: `/api/internal/document/${document.id}/preview/${index}/image${suffix}`,
							generating: this.props.regenerating
						};
					}
				});
		}

		const overlays = [];
		(document?.signers || []).forEach((signer, signerIndex) => {
			if (!!signer.signatureField && !signer.signed) {
				overlays.push({
					pageIndex: signer.signatureField.pageIndex,
					relativeLocationX: signer.signatureField.relativeLocationX,
					relativeLocationY: signer.signatureField.relativeLocationY,
					relativeWidth: signer.signatureField.relativeWidth,
					relativeHeight: signer.signatureField.relativeHeight,
					component:
						this.props.readOnly ?
							<SignatureOverlay signer={signer}
											  signerIndex={signerIndex}
											  readOnly={this.props.readOnly}
											  field={signer.signatureField}
							/>
							:
							<DraggableOverlay
								key={'sign-' + signer.id}
								signer={signer}
								onFieldDropped={this.props.onSignerDropped}
								resizable
								onFieldResize={this.props.onSignatureFieldResize}
							>
								<SignatureOverlay grab
												  signer={signer}
												  signerIndex={signerIndex}
												  readOnly={this.props.readOnly}
												  field={signer.signatureField}
												  onRemove={this.props.onSignerRemoveSignatureField}
												  onEditSettings={() => this.props.onFieldEditSettings({
													  fieldType: 'SIGNATURE',
													  signerId: signer.id,
													  style: signer.signatureField.style,
												  })}
								/>
							</DraggableOverlay>
				});
			}

			if (!signer.signed) {
				(signer?.paraphFields || []).forEach((field, index) =>
					overlays.push({
						pageIndex: field.pageIndex,
						relativeLocationX: field.relativeLocationX,
						relativeLocationY: field.relativeLocationY,
						relativeWidth: field.relativeWidth,
						relativeHeight: field.relativeHeight,
						component:
							this.props.readOnly ?
								<ParaphOverlay signer={signer}
											   signerIndex={signerIndex}
											   readOnly={this.props.readOnly}
											   index={index}
											   field={field}
								/>
								:
								<DraggableOverlay
									key={'paraph-' + signer.id + '-' + index}
									signer={signer}
									instance={index}
									onFieldDropped={this.props.onParaphFieldDropped}
									field={field}
									resizable
									onFieldResize={this.props.onParaphFieldResize}
								>
									<ParaphOverlay grab
												   signer={signer}
												   signerIndex={signerIndex}
												   readOnly={this.props.readOnly}
												   index={index}
												   field={field}
												   onRemove={this.props.onParaphFieldRemove}
												   onDuplicate={this.props.onParaphFieldDuplicate}
												   onEditSettings={() => this.props.onFieldEditSettings({
													   fieldType: 'PARAPH',
													   signerId: signer.id,
													   instance: index,
													   style: field.style,
												   })}
									/>
								</DraggableOverlay>
					})
				);

				(signer?.extraSignatureFields || []).forEach((field, index) =>
					overlays.push({
						pageIndex: field.pageIndex,
						relativeLocationX: field.relativeLocationX,
						relativeLocationY: field.relativeLocationY,
						relativeWidth: field.relativeWidth,
						relativeHeight: field.relativeHeight,
						component:
							this.props.readOnly ?
								<SignatureOverlay
									signer={signer}
									signerIndex={signerIndex}
									field={field}/>
								:
								<DraggableOverlay
									key={'sign-extra-' + signer.id + '-' + index}
									signer={signer}
									instance={index}
									onFieldDropped={this.props.onExtraSignFieldDropped}
									field={field}
									resizable
									onFieldResize={this.props.onExtraSignFieldResize}
								>
									<SignatureOverlay grab
													  signer={signer}
													  signerIndex={signerIndex}
													  index={index}
													  field={field}
													  readOnly={this.props.readOnly}
													  onRemove={this.props.onExtraSignFieldRemove}
													  onEditSettings={() => this.props.onFieldEditSettings({
														  fieldType: 'EXTRA_SIGNATURE',
														  signerId: signer.id,
														  instance: index,
														  style: field.style,
													  })}
									/>
								</DraggableOverlay>
					})
				);
			}

			if (!signer.signed) {
				(signer?.formFields || []).forEach((field, index) => {
					overlays.push({
						pageIndex: field.pageIndex,
						relativeLocationX: field.relativeLocationX,
						relativeLocationY: field.relativeLocationY,
						relativeWidth: field.relativeWidth,
						relativeHeight: field.relativeHeight,
						component:
							this.props.readOnly ?
								<FormFieldOverlay signer={signer} readOnly={this.props.readOnly} field={field}/>
								:
								<DraggableOverlay
									key={'form-field-overlay' + field.id}
									signer={signer}
									instance={index}
									field={field}
									onFieldDropped={this.props.onFormFieldDropped}
									resizable
									onFieldResize={this.props.onFormFieldResize}
								>
									<FormFieldOverlay grab
													  signer={signer}
													  readOnly={this.props.readOnly}
													  field={field}
													  onRemove={this.props.onFormFieldRemove}
													  onEditSettings={field => this.props.onFormFieldEditSettings(field, signer)}/>
								</DraggableOverlay>
					})
				})
			}
		})

		const multiSignSignatureField = this.props.multiSignSignatureField;
		if (!!multiSignSignatureField) {
			overlays.push({
				pageIndex: multiSignSignatureField.pageIndex,
				relativeLocationX: multiSignSignatureField.relativeLocationX,
				relativeLocationY: multiSignSignatureField.relativeLocationY,
				relativeWidth: multiSignSignatureField.relativeWidth,
				relativeHeight: multiSignSignatureField.relativeHeight,
				component:
					this.props.readOnly ?
						<SignatureOverlay signer="multisign" field={multiSignSignatureField}/>
						:
						<DraggableOverlay
							key="multisign"
							signer="multisign"
							field={multiSignSignatureField}
							onFieldDropped={this.props.onMultiSignSignatureFieldDropped}
							resizable
							onFieldResize={this.props.onMultiSignSignatureFieldResize}
						>
							<SignatureOverlay grab
											  signer="multisign"
											  field={multiSignSignatureField}
											  onEditSettings={() => this.props.onFieldEditSettings({
												  fieldType: 'MULTI_SIGNATURE',
												  style: multiSignSignatureField.style,
											  })}/>
						</DraggableOverlay>
			});
		}

		(this.props.multiSignParaphFields || []).forEach((field, index) =>
			overlays.push({
				pageIndex: field.pageIndex,
				relativeLocationX: field.relativeLocationX,
				relativeLocationY: field.relativeLocationY,
				relativeWidth: field.relativeWidth,
				relativeHeight: field.relativeHeight,
				component:
					this.props.readOnly ?
						<ParaphOverlay signer="multisign" readOnly={this.props.readOnly} index={index} field={field}/>
						:
						<DraggableOverlay
							signer="multisign"
							instance={index}
							onFieldDropped={this.props.onMultiSignParaphFieldDropped}
							field={field}
							resizable
							onFieldResize={this.props.onMultiSignParaphFieldResize}
						>
							<ParaphOverlay grab
										   signer="multisign"
										   readOnly={this.props.readOnly}
										   index={index}
										   field={field}
										   onRemove={this.props.onMultiSignParaphFieldRemove}
										   onDuplicate={this.props.onMultiSignParaphFieldDuplicate}
										   onEditSettings={() => this.props.onFieldEditSettings({
											   fieldType: 'MULTI_PARAPH',
											   instance: index,
											   style: field.style,
										   })}
							/>
						</DraggableOverlay>
			})
		);

		(this.props.multiSignExtraSignatureFields || []).forEach((field, index) => {
			overlays.push({
				pageIndex: field.pageIndex,
				relativeLocationX: field.relativeLocationX,
				relativeLocationY: field.relativeLocationY,
				relativeWidth: field.relativeWidth,
				relativeHeight: field.relativeHeight,
				component:
					this.props.readOnly ?
						<SignatureOverlay signer="multisign" field={field}/>
						:
						<DraggableOverlay
							signer="multisign"
							instance={index}
							onFieldDropped={this.props.onMultiSignExtraSignFieldDropped}
							field={field}
							resizable
							onFieldResize={this.props.onMultiSignExtraSignFieldResize}
						>
							<SignatureOverlay grab
											  signer="multisign"
											  index={index}
											  field={field}
											  readOnly={this.props.readOnly}
											  onRemove={this.props.onMultiSignExtraSignFieldRemove}
											  onEditSettings={() => this.props.onFieldEditSettings({
												  fieldType: 'MULTI_EXTRA_SIGNATURE',
												  instance: index,
												  style: field.style,
											  })}
							/>
						</DraggableOverlay>
			})
		});

		(this.props.multiSignFormFields || []).forEach((field, index) => {
			overlays.push({
				pageIndex: field.pageIndex,
				relativeLocationX: field.relativeLocationX,
				relativeLocationY: field.relativeLocationY,
				relativeWidth: field.relativeWidth,
				relativeHeight: field.relativeHeight,
				component:
					this.props.readOnly ?
						<FormFieldOverlay signer="multisign" readOnly={this.props.readOnly} field={field}/>
						:
						<DraggableOverlay
							signer="multisign"
							instance={index}
							field={field}
							onFieldDropped={this.props.onMultiSignFormFieldDropped}
							resizable
							onFieldResize={this.props.onMultiSignFormFieldResize}
						>
							<FormFieldOverlay grab
											  signer="multisign"
											  readOnly={this.props.readOnly}
											  field={field}
											  onRemove={this.props.onMultiSignFormFieldRemove}
											  onEditSettings={this.props.onMultiSignFormFieldEditSettings}/>
						</DraggableOverlay>
			})
		});

		const usedPlaceholderIds = [];
		(document?.signers || []).forEach(signer => {
			if (!!signer?.signatureField?.placeholderId) {
				usedPlaceholderIds.push(signer.signatureField.placeholderId);
			}
			(signer?.extraSignatureFields || []).forEach(field => {
				if (!!field?.placeholderId) {
					usedPlaceholderIds.push(field.placeholderId);
				}
			});
			(signer?.paraphFields || []).forEach(field => {
				if (!!field?.placeholderId) {
					usedPlaceholderIds.push(field.placeholderId);
				}
			});
			(signer?.formFields || []).forEach(field => {
				if (!!field?.placeholderId) {
					usedPlaceholderIds.push(field.placeholderId);
				}
			});
		});

		(document.placeholders || []).filter(placeholder => usedPlaceholderIds.indexOf(placeholder.id) === -1).forEach(placeholder => {
			overlays.push({
				pageIndex: placeholder.location.pageIndex,
				relativeLocationX: placeholder.location.relativeX,
				relativeLocationY: placeholder.location.relativeY,
				relativeWidth: placeholder.location.relativeWidth,
				relativeHeight: placeholder.location.relativeHeight,
				component: <FieldPlaceholderComponent
					disabled={this.props.readOnly || !this.props.fieldPlaceholderCanSelect}
					fieldType={placeholder.fieldType}
					formFieldType={placeholder.formFieldType}
					onFieldPlaceholderClicked={() => this.props.onFieldPlaceholderClicked(placeholder)}
				/>
			});
		});

		return <PdfViewerComponent
			key={document.id + document?.version}
			useClientPdfRendering={!!this.props.sessionInfo.useClientPdfRendering}
			pdfUrl={pdfUrl}
			statusUrl={statusUrl}
			pages={pages}
			overlays={overlays}
			limitHeight
			onPagePlaceholderClicked={this.props.onPagePlaceholderClicked}
		>
			{!this.state.editing && <>
				<FixedWidthHeaderComponent maxWidth={300} value={document.name}/>

				{!this.props.readOnly && <IconButton
					title={this.props.t('editor.documentNameEdit')}
					sx={{ml: 1}}
					onClick={this.onStartEditing}
				>
					<EditIcon/>
				</IconButton>
				}

				{(!this.props.readOnly || document.state === 'ATTACHMENT_GENERIC') &&
					!document.documentCollection &&
					!(document.state === "TEMPLATE" && !document.hasPdf) && <>
						<IconButton
							title={this.props.t('editor.documentChange')}
							onClick={this.onChangeDocument}
							color={PDF_ERROR_COUNT(document) > 0 ? 'error' : 'default'}
						>
							<SwapDocumentIcon/>
						</IconButton>
					</>
				}
				{!this.state.readOnly && document.childType === 'COLLECTION' && <IconButton
					title={this.props.t('editor.documentRemove')}
					onClick={() => this.props.onRemoveChildDocument(document.id)}
					disabled={this.props.editorEditInfo.document.children.length === 1}
				>
					<DeleteIcon/>
				</IconButton>}
			</>}
			{!this.props.readOnly && this.state.editing && <>
				<TextField
					value={this.state.name}
					onChange={this.onChangeName}
					onKeyDown={this.onChangeNameKeyPress}
					autoComplete="off"
					size="small"
					autoFocus
					inputProps={{maxLength: document.documentCollection ? 255 : 251}}
				/>
				<IconButton onClick={this.onConfirmEditing}>
					<CheckIcon/>
				</IconButton>
				<IconButton onClick={this.onCancelEditing}>
					<BlockIcon/>
				</IconButton>
			</>}

			<PendingChangesDialog
				open={this.state.changePdfPendingChangesDialogOpen}
				onClose={this.onCloseChangePdfPendingChangesDialog}
				onConfirm={this.onChangePdfPendingChangesConfirm}
				onSaveAndConfirm={this.onChangePdfPendingChangesSaveAndConfirm}
				labelContent={this.props.t('editor.documentChangePendingChanges')}
				labelConfirm={this.props.t('editor.documentChangePendingChangesIgnoreChanges')}
				labelSaveAndConfirm={this.props.t('editor.documentChangePendingChangesSave')}
			/>

			<EditorUploadPdfDialog
				title={this.props.t('editor.changeDocumentTitle')}
				document={this.props.document}
				open={this.state.changePdfDialogOpen}
				onClose={this.onCloseChangePdfDialog}
			/>
		</PdfViewerComponent>;
	}

	onStartEditing = () => {
		this.setState({editing: true, name: this.props.document.name});
	}

	onChangeName = (e) => {
		this.setState({name: e.target.value});
	}

	onChangeNameKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.onConfirmEditing();
		}
	}

	onConfirmEditing = () => {
		this.setState({editing: false}, () => this.props.onChangeDocumentName(this.state.name));
	}

	onCancelEditing = () => {
		this.setState({editing: false});
	}

	onChangeDocument = () => {
		if (this.props.editorPendingChanges) {
			this.setState({changePdfPendingChangesDialogOpen: true});
		} else {
			this.setState({changePdfDialogOpen: true});
		}
	}

	onCloseChangePdfPendingChangesDialog = () => {
		this.setState({changePdfPendingChangesDialogOpen: false});
	}

	onChangePdfPendingChangesConfirm = () => {
		this.setState({changePdfDialogOpen: true});
	}

	onChangePdfPendingChangesSaveAndConfirm = () => {
		this.props.onSaveDocument();
	}

	onCloseChangePdfDialog = () => {
		this.setState({changePdfDialogOpen: false});
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			editorEditInfo: state.editor.editInfo,
			editorPendingChanges: state.editor.pendingChanges,
		}
	}
)(EditorPdfComponent)));
